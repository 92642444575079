import * as React from "react";
import { useNavigate } from "react-router-dom";
import { checkOnlineSession } from "@api/sessionApi";
import { NonExpressFormStep } from "@src/Sections";

const cookieName = "token";

let localCaseId: string | null = null;
export const setLocalCaseId = (caseId: string | null) => {
  localCaseId = caseId;
};

export const getLocalCaseId = () => {
  return localCaseId;
};

export const login = (token: string) => {
  const sameSite = 'Strict;';
  const secure = window.location.protocol === 'https:' ? 'Secure;' : '';

  document.cookie = `${cookieName}=${token}; path=/; max-age=${2 * 60 * 60}; SameSite=${sameSite} ${secure}`;
};

export const keepSessionAlive = (token: string) => {
  login(token);
};

export const logout = () => {
  const secure = window.location.protocol === 'https:' ? '; Secure' : '';

  document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; ${secure}`;
  clearSessionExpirationNotice();
  clearLocalSession();
  sessionStorage.removeItem("express-form-data");
};

export const loggedIn = () => !!token();

export const token = () => {
  const cookie = getCookie(cookieName);

  if (cookie) {
    ensureSessionExpirationNotice();
  }

  return cookie;
};

const getCookie = (string: string) => {
  const cookies = document.cookie.split(/;\s*/);
  const cookie = cookies.find((cookie) => cookie.startsWith(string + "="));
  return cookie && cookie.substring(string.length + 1);
};

let sessionExpirationInterval: NodeJS.Timeout | null = null;

const clearSessionExpirationNotice = () => {
  if (sessionExpirationInterval) {
    clearInterval(sessionExpirationInterval);
    sessionExpirationInterval = null;
  }
};

const ensureSessionExpirationNotice = () => {
  if (sessionExpirationInterval) {
    return;
  }

  sessionExpirationInterval = setInterval(() => {
    if (!getCookie(cookieName)) {
      alert("You have been logged.");
      window.location.reload();
    }
  }, 60000);
};

export type LocalSession = {
  caseId: string;
  signature: string;
};

export type RemoteSession = {
  url?: string,
  session: {
    caseId: string | null;
    signature: string | null;
    token: string | null;
  }
  formStatus?: {
    source: string | null,
    notifierFromState: NonExpressFormStep
  }
};

export const useLocalSession = (path: string) => {
  const navigate = useNavigate();
  const [{ caseId, signature }, setState] = React.useState({ caseId: null, signature: null } as {
    caseId: string | null;
    signature: string | null;
  });

  React.useEffect(() => {
    const session = localSession();
    if (session) {
      setState(session);
      return;
    }

    const caseId = getLocalCaseId();
    if (caseId) {
      setState({ caseId, signature: null });
      return;
    }

    checkOnlineSession().then((remoteSession: RemoteSession) => {
      if (remoteSession?.session.token) {
        setLocalCaseId(remoteSession.session.caseId);
        setState({ caseId: remoteSession?.session.caseId, signature: null });
        return;
      }

      navigate(path, { replace: true });
    });
  }, [caseId, signature]);

  return { caseId, signature };
};

export const localSession = () => {
  const caseId = window.sessionStorage.getItem("settldCaseId");
  const signature = window.sessionStorage.getItem("settldSignature");

  if (!caseId || !signature) {
    return null;
  }

  return { caseId, signature };
};

export const setLocalSession = ({ caseId, signature }: LocalSession) => {

  window.sessionStorage.setItem("settldCaseId", caseId);
  window.sessionStorage.setItem("settldSignature", signature);
};

export const clearLocalSession = () => {

  sessionStorage.clear();
  localStorage.clear();
};

export const isUserLoggedIn = () => {
  return !!(localSession() || token());
};
// export const validValues = (signature: string | null, caseId: string | null) => {
//   if (!signature) return false;
//   if (!caseId) return false;
//   return true;
// }

// export const put = (signature: string | null, caseId: string | null) => {
//   if (!signature) return false;
//   if (!caseId) return false;
//   setLocalSession({ caseId, signature });
// }