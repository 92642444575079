import { Stack, Typography } from "@mui/material";
import { PersonDetails, prettyTitle, readableRelationship, NotifierRolesToUI, NotifierRoles, NotifierRolesByServer } from "@src/types";
import { formatDate } from "@src/utils/Functions";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FieldsTableHeader, FieldsTable, FieldRow } from "../molecules";
import { colors } from "@styles/constants";


interface PersonSummaryI {
    person: PersonDetails
}
export const ExpressPersonSummary: React.FC<PersonSummaryI> = ({ person }) => {
    const match = useMatch("/express/:slug/*");
    const navigate = useNavigate();
    const onEditNotifier = React.useCallback(() => {
        if (!person.roles || person.roles.length === 0) {
            navigate(`${match?.pathnameBase}/notifier`);

        } else if (person.roles.includes(NotifierRolesByServer.Nok)) {
            navigate(`${match?.pathnameBase}/nok`);

        } else if (person.roles.includes(NotifierRolesByServer.Executor)) {
            navigate(`${match?.pathnameBase}/executor`);
        } else {
            navigate(`${match?.pathnameBase}/notifier`);
        }
    }, [navigate, match, person]);

    if (!person) {
        return (
            <Stack rowGap={2}>
                <FieldsTableHeader title="Your details" onEdit={onEditNotifier} />
                <Typography variant="body1" color={colors.fadedText}>
                    Not completed yet
                </Typography>
            </Stack>
        );
    }

    let role = "";
    if (!person.roles || person.roles.length === 0 || person.roles.includes(NotifierRolesByServer.Notifier)) {
        role = "Your ";
    } else if (person.roles.includes(NotifierRolesByServer.Nok)) {
        role = "Next Of Kin ";
    } else if (person.roles.includes(NotifierRolesByServer.Executor)) {
        role = "Executor ";
    } else {
        console.log("unknown type for person.roles");
    }
    const title = `${role} Details`;

    return (
        <FieldsTable header={<FieldsTableHeader title={title} onEdit={onEditNotifier} />}>
            <FieldRow
                label="Name"
                value={`${prettyTitle(person.title)} ${person.firstName} ${person.lastName} `}
            />
            <FieldRow
                label="Date of birth"
                value={person.dateOfBirth ? formatDate(person.dateOfBirth) : ""}
            />
            <FieldRow
                label="Address"
                value={
                    <>
                        {person.address}
                        <br />
                        {person.city}
                        <br />
                        {person.postcode}
                    </>
                }
            />
            <FieldRow label="Phone" value={person.contactNumber ?? ""} />
            <FieldRow label="Email" value={person.email ?? ""} />
            <FieldRow label="Vulnerable?" value={person.isVulnerable ? "Yes" : "No"} />
            <FieldRow
                label="The person who died was"
                value={readableRelationship(person.relationshipToDeceased) ?? ""}
            />
            {!person.roles && <FieldRow label="Role" value={NotifierRolesToUI[person.role as NotifierRoles] ?? ""} />}
        </FieldsTable>
    );
};