import { SPProvider } from "@store/SPProvider";
import { ExpressUiInfoProvider } from "./ExpressUiInfo";
import React from "react";

const ExpressProvider: React.FC = ({ children }) => {
  return (
    <ExpressUiInfoProvider> <SPProvider>{children}</SPProvider> </ExpressUiInfoProvider>
  );
};
export { ExpressProvider };
