import * as React from "react";

import { getExpressData, putExpressData } from "@pages/ExpressForm/express-data";
import { lookup } from "@api/formApi";
import { ServiceProvider } from "@src/types/ServiceProvider";

export const useServiceProvider = (slug: string) => {
  const [{ serviceProvider, loading, error }, setState] = React.useState({
    serviceProvider: null as ServiceProvider | null,
    loading: true,
    error: null as Error | null,
  });

  React.useEffect(() => {
    lookup({ slug }).then(
      (sp) => {
        const data = getExpressData();
        if (data?.slug !== slug) {
          putExpressData({ slug });
        }
        setState({
          serviceProvider: sp,
          loading: false,
          error: null,
        });
      },
      (_error) => {
        setState({ serviceProvider: null, loading: false, error: new Error("Not found") });
      }
    );
  }, [slug]);

  return { serviceProvider, loading, error };
};
