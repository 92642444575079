import * as React from "react";

import { makeStyles } from "@mui/styles";

import deleteIcon from "@assets/images/delete.svg";
import { Message } from "./message";
import { MessageAuthor } from "./MessageAuthor";
import { MessageDocuments } from "./MessageDocuments";
import { MessageSentAt } from "./MessageSentAt";

export const OutgoingMessageBlock: React.FC<{
  readonly messages: ReadonlyArray<Message>;
  readonly lastMessage: Message;
  readonly lastUndeletedMessage: Message | null;
  readonly recipient: "sp" | "settld";
  readonly sent: string;
  readonly deleteMessage: (id: string) => Promise<void>;
}> = ({ sent, messages, lastMessage, lastUndeletedMessage, recipient, deleteMessage }) => {
  const classes = useStyles();
  return (
    <OutgoingBlock>
      <MessageAuthor value={"You"} />
      {messages.map((message, index) => {
        if (message.deletedAt) {
          return (
            <div key={index} className={classes.deletedMessage}>
              This message has been deleted
            </div>
          );
        }

        return (
          <React.Fragment key={index}>
            <OutgoingMessage message={message} deleteMessage={deleteMessage} />
            {lastUndeletedMessage && lastUndeletedMessage.id === message.id && (
              <MessageSentAt
                value={sent}
                checked={
                  recipient === "sp"
                    ? lastMessage.isReadByServiceProvider
                    : lastMessage.isReadBySettld
                }
              />
            )}
          </React.Fragment>
        );
      })}
    </OutgoingBlock>
  );
};

const OutgoingBlock: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.outgoingBlock}>{children}</div>;
};

const OutgoingMessage: React.FC<{
  readonly message: Message;
  readonly deleteMessage: (id: string) => Promise<void>;
}> = ({ message, deleteMessage }) => {
  const { content, documents = [] } = message;

  const classes = useStyles();

  const deleteThisMessage = React.useCallback(() => {
    deleteMessage(message.id);
  }, [message, deleteMessage]);

  return (
    <div className={`${classes.outgoingMessage} hover-visibility-container`}>
      <div style={{ overflow: "auto", maxHeight: "200px" }}>{detectAndConvertURL(content)}</div>
      {documents.length > 0 && <MessageDocuments documents={documents} />}
      <div className="hover-visibility-content" onClick={deleteThisMessage}>
        <img className={`${classes.deleteImage}`} src={deleteIcon} width="20" height="20"></img>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  outgoingBlock: {
    marginLeft: "10%",
    marginRight: "10px",
    marginBottom: "15px",
  },

  deletedMessage: {
    backgroundColor: "#377A84",
    borderRadius: "8px",
    color: "#d1d1d1",
    fontSize: "16px",
    fontStyle: "italic",
    marginBottom: "5px",
    padding: "5px 15px",
    position: "relative",
  },

  outgoingMessage: {
    backgroundColor: "#377A84",
    borderRadius: "8px",
    color: "white",
    fontSize: "16px",
    marginBottom: "5px",
    padding: "5px 15px",
    position: "relative",
  },

  deleteImage: {
    cursor: "pointer",
    position: "absolute",
    right: "-10px",
    top: "-10px",
  },
});

function detectAndConvertURL(text: any) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex); // Split the text into parts
  return parts.map((part: any, index: any) => {
    if (part.match(urlRegex)) {
      // If the part is a URL, create a clickable link
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      // Otherwise, return the plain text
      return part;
    }
  });
}
