import * as React from "react";
import {  ACCEPTABLE_MIME_TYPES, FileTags, fileTagsToUiInfo, MAX_FILE_SIZE } from "@customTypes/Document";
import { Stack } from "@mui/material";
import { Link } from "../../components/atoms/Link";
import { DashboardInfo, DashboardInfoContext } from "@src/store/DashboardProvider";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { uploadDocuments } from "./FileUpload";

interface FileInfoI {
  filename: string;
  fileTag: FileTags;
}

export function getMissingDocuments(dashBoardState: DashboardInfo): Array<FileInfoI> {
  const missingDocuments = [];
  if (dashBoardState.intestacyFlow) {
    if (dashBoardState.collectExecutorDocuments) {
      dashBoardState.executorIdDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.ExecutorId],
          fileTag: FileTags.ExecutorId,
        });
      dashBoardState.executorGoaDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.ExecutorGoa],
          fileTag: FileTags.ExecutorGoa,
        });
    }
    if (dashBoardState.collectNokDocuments) {
      dashBoardState.nokIdDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.NokId],
          fileTag: FileTags.NokId,
        });
      dashBoardState.nokGoaDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.NokGoa],
          fileTag: FileTags.NokGoa,
        });
    }
  }
  return missingDocuments;
}

export const MissingDocument: React.FC<FileInfoI> = ({ filename, fileTag }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { state: dashBoardState, dispatch } = React.useContext(DashboardInfoContext);

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const invalidFiles: string[] = [];
    const validFiles: File[] = [];

    Array.from(files).forEach((file) => {
      if (!ACCEPTABLE_MIME_TYPES.includes(file.type)) {
        invalidFiles.push(`${file.name} (invalid type)`);
      } else if (file.size > MAX_FILE_SIZE) {
        invalidFiles.push(`${file.name} (exceeds 3MB)`);
      } else {
        validFiles.push(file);
      }
    });

    if (invalidFiles.length > 0) {
      window.alert(
        `Invalid file(s): ${invalidFiles.join(
          ", "
        )}.\nSupported types are: PDF, JPEG, PNG. Max size: 3MB.`
      );
      event.target.value = ""; // Reset the input
      return;
    }

    try {
      await uploadDocuments(
        validFiles,
        fileTag,
        dashBoardState.caseId,
        dashBoardState.signature
      );
      dispatch({ type: "REFRESH" });
      window.alert("File uploaded successfully!");
    } catch (uploadError) {
      window.alert("Upload failed. Please try again.");
      console.error(uploadError);
    }
  };

  return (
    <Stack direction="row" columnGap={2} alignItems="center" onClick={handleFileClick}>
      <input type="file" ref={fileInputRef} hidden multiple onChange={handleFileChange} />
      <PictureAsPdfOutlined color="error" />
      <Link to="">
        <span style={{ color: "red" }}>{filename}</span>
      </Link>
    </Stack>
  );
};
