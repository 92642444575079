import * as React from "react";
import { useNavigate } from "react-router-dom";

import {NonExpressFormData } from "@customTypes/index";
import urlPaths from "../../urlPaths";

import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { KYCDone } from "../NotifierForm/KYC/KYCDone";
import { KYCNotAvailableYet } from "../NotifierForm/KYC/KYCNotAvailable";
import { KYCView } from "../NotifierForm/KYC/KYCView";
import { getExpressSectionsData } from "./express-data";
import { useScrollToTop } from "@src/hooks/useScrollToTop";

const currentSection = "kyc";

export type KYCProps = {
  next: () => void;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
};

export const KYC: React.FunctionComponent<KYCProps> = ({
  next,
  onSectionClick,
  updateTemplate,
}) => {
  useScrollToTop();

  const state = React.useMemo(() => {
    const sections = getExpressSectionsData();
    if (sections[currentSection]?.target) {
      return "done";
    }
    if (sections["notifier"]?.target) {
      return "available";
    }

    return "unavailable";
  }, []);

  const navigate = useNavigate();

  const goToKYC = React.useCallback(() => {
    navigate(urlPaths.kycExpress(), { replace: true });
  }, [navigate]);

  if (state === "unavailable") {
    return (
      <KYCNotAvailableYet
        expressForm
        onContinue={() => onSectionClick("notifier")}
        onSectionClick={onSectionClick}
        updateTemplate={updateTemplate}
      />
    );
  }

  if (state === "available") {
    return (
      <KYCView
        expressForm
        onContinue={goToKYC}
        onSectionClick={onSectionClick}
        updateTemplate={updateTemplate}
      />
    );
  }

  return (
    <KYCDone
      expressForm
      onContinue={next}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
    />
  );
};

export const isComplete = (form:NonExpressFormData): boolean => {
  return !form.kycPending;
};
