import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import urlPaths from "../../urlPaths";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";

import { saveUserRating } from "@api/formApi";

export const FormRatingPage: React.FC<{ caseId: string; signature: string | null }> = ({
  caseId,
  signature
}) => {
  const navigate = useNavigate();

  const [busy, setBusy] = React.useState(false);
  const [state, setState] = React.useState({ rated: false, score: 0 });
  const [comment, setComment] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const sendScore = React.useCallback(
    (score?: number) => {
      if (score) {
        setState((s) => ({
          rated: true,
          score: score || s.score
        }));
      } else {
        setSubmitted(true);
      }

      saveUserRating({
        caseId,
        signature,
        subject: "form",
        score: (score || state.score) * 2,
        comment
      }).catch((error: any) => {
        console.warn(error);
      });
    },
    [state, comment, caseId, signature]
  );

  const done = React.useCallback(() => {
    sendScore();
    setBusy(true);
    setTimeout(() => {
      navigate(urlPaths.status(), { replace: true });
    }, 100);
  }, [sendScore, setBusy, navigate]);

  return (
    <>
      <FormRatingPageView
        busy={busy}
        rated={state.rated}
        score={state.score}
        comment={comment}
        submitted={submitted}
        sendScore={sendScore}
        setComment={setComment}
        done={done}
      />
    </>
  );
};

export type FormRatingPageViewProps = {
  busy?: boolean;
  score?: number;
  rated?: boolean;
  comment?: string;
  submitted?: boolean;
  sendScore: (score?: number) => void;
  setComment: (comment: string) => void;
  done: () => void;
};

export const FormRatingPageView: React.FC<FormRatingPageViewProps> = ({ busy, done }) => {
  return (
    <SideImageTemplate
      headerType="signout"
      busy={busy}
      image={<img src="/images/stock/signup.svg" width="100%" alt="Family" />}
    > <Stack rowGap={4}> <Header level={1}>Your case has been successfully submitted!</Header>

      <Stack rowGap={2}> <Typography variant="body1"> <b>What happens now?</b> </Typography>

        <Typography variant="body1"> Our team will verify the details in this form and then notify the listed
          organisations. </Typography> <Typography variant="body1"> You should also receive a confirmation email, to the
          email address you provided. </Typography> </Stack>

      <Stack rowGap={2}> <Typography variant="body1"> <b>What do I need to do next?</b> </Typography>

        <Typography variant="body1"> It is common for Settld and/or the organisations we notify to require further
          documentation or clarification from you. We will inform you of any such requests, plus all real-time account
          updates, via your personalised Account Dashboard. From here you can also add more accounts. </Typography>

        {/* <Typography variant="body1">
            In the meantime, we would be very grateful if you would give us your feedback on your
            experience of Settld so far. This will help us improve the service for others.
          </Typography>

          <Typography variant="body1">
            <b>How was the experience of filling in this form?</b>
          </Typography>

          <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block" }}>
              <Rating
                name="hover-feedback"
                value={score}
                size="large"
                precision={0.5}
                onChange={(event: any, score: number | null) => {
                  if (score) {
                    sendScore(score);
                  }
                }}
              />

              <div
                style={{
                  color: "gray",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                  width: "100%",
                }}
              >
                <div>Bad</div>
                <div>Good</div>
              </div>
            </div>
          </div>

          {rated && (
            <>
              <Typography variant="body1">
                <b>Why did you give this rating?</b>
              </Typography>

              <MultilineTextInput
                name="comment"
                value={comment || ""}
                onChange={(ev) => setComment(ev.target.value)}
                disabled={submitted}
              />
            </>
          )} */}
      </Stack>

      <FormStack> <FormField twoThirdsWidth> <Button
        variant="primary"
        size="large"
        disabled={busy}
        onClick={done}> Continue </Button> </FormField> </FormStack> </Stack> </SideImageTemplate>
  );
};
