import * as React from "react";
import { Alert, Snackbar, Stack, Typography } from "@mui/material";
import {
  NonExpressFormData,
  notifierRequiredFields,
  NotifierRoles,
  PersonErrors, Person,
  Relationship,
  roleOptions,
  ServiceProvider
} from "@customTypes/index";
import { filterProperties, isValidDateString, validEmailAddress } from "@utils/Functions";
import { ReactStateSetter } from "@utils/Types";
import { BooleanInput } from "@atoms/BooleanInput";
import { DateInput } from "@atoms/DateInput";
import { EmailAddressInput } from "@src/components/atoms";
import { Header } from "@atoms/Header";
import { PhoneNumberTextInput } from "@src/components/atoms";
import { SelectInput } from "@atoms/SelectInput";
import { TextInput } from "@atoms/TextInput";
import { NewTitleInput } from "@atoms/TitleInput";
import { NewAddressInput } from "@molecules/AddressInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@src/components/molecules";
import { RelationToDeceased } from "@src/components/molecules";
import { Section } from "@src/Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { updateNotifier } from "@api/caseApi";
import { RepresentativeInfoBox } from "@src/components/atoms/RepresentativeInfoBox";
import { IntestacyInfoBox, NokQuestion, VulnerableInfoBox, WillExistsQuestion } from "@src/components/molecules";
import { NEContext } from "@src/store/NonExpressState";
import { isAskWillAndNok, ui_state_machine } from "@src/utils/Intestacy";
import { ValidationError } from "@src/utils/Errors";

export type NotifierDetailsPersistedState = {
  record: Person;
  hasChanges: boolean;
  errors: Errors;
  remoteError?: string;
};
export type NotifierRecord = Person;

type Errors = PersonErrors;

export const notifierDetailsPersistedStateFromForm = (
  form: NonExpressFormData
): NotifierDetailsPersistedState => {
  const record = filterProperties(form.notifier || {}, [
    "title",
    "firstName",
    "lastName",
    "dateOfBirth",
    "address",
    "city",
    "postcode",
    "email",
    "contactNumber",
    "relationshipToDeceased",
    "relationshipToDeceasedOther",
    "role",
    "isVulnerable",
    "nok",
    "willAvailable"
  ]);

  return {
    record,
    hasChanges: false,
    errors: {}
  };
};

const updatedFormFromPersistedState = (
  form: NonExpressFormData,
  state: NotifierDetailsPersistedState
): NonExpressFormData => {
  const record = notifierRecordFromPersistedState(state);

  const notifier = !form.notifier
    ? record
    : {
      ...record,
      proofOfAddress: form?.notifier?.proofOfAddress
    };

  return { ...form, notifier };
};

const notifierRecordFromPersistedState = (state: NotifierDetailsPersistedState) => {
  const { relationshipToDeceasedOther, ...rest } = state.record;

  return rest.relationshipToDeceased === Relationship.Other && relationshipToDeceasedOther
    ? state.record
    : rest;
};

const serverRecordFromPersistedState = (state: NotifierDetailsPersistedState) => {
  const title = state.record.title?.toLowerCase();

  const relationshipToDeceased = state.record.relationshipToDeceased?.toLowerCase();

  return {
    id: state.record.id,
    title,
    firstName: state.record.firstName,
    lastName: state.record.lastName,
    dateOfBirth: state.record.dateOfBirth,
    address: state.record.address,
    city: state.record.city,
    postcode: state.record.postcode,
    email: state.record.email,
    contactNumber: state.record.contactNumber,
    relationshipToDeceased,
    relationshipToDeceasedOther: state.record.relationshipToDeceasedOther,
    role: state.record.role,
    isVulnerable: state.record.isVulnerable,
    nok: state.record.nok,
    willAvailable: state.record.willAvailable
  };
};

type NotifierDetailsProps = {
  caseId: string;
  signature: string | null;
  prepareUpdate: (section: Section, form: NonExpressFormData) => NonExpressFormData;
  updateSuccessful: (form: NonExpressFormData, section: Section, justSave?: boolean) => void;
  updateFailure: (error: Error) => void;
  form: NonExpressFormData;
  busy: boolean;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  persistedState: NotifierDetailsPersistedState;
  setPersistedState: ReactStateSetter<NotifierDetailsPersistedState>;
  updatePerson: (p: Person) => void;
  updatingNotifierEmailAddress: boolean;
  setUpdatingNotifierEmailAddress: (a: boolean) => void;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  persistedStateSetters: any
};
export const NotifierDetails: React.FC<NotifierDetailsProps> = ({
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedState,
  setPersistedState,
  updatePerson,
  updatingNotifierEmailAddress,
  onSectionClick,
  updateTemplate, persistedStateSetters
}) => {
  const { record, hasChanges, errors } = persistedState;
  const { state: uiInfo, dispatch } = React.useContext(NEContext);

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          [name]: value
        },
        errors: {
          ...errors,
          [name]: undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  const onRoleChange = React.useCallback(
    (value) => {
      const state: any = {
        hasChanges: true,
        record: {
          ...record,
          role: value,
          dateOfBirth: value === "solicitor" ? undefined : record.dateOfBirth,
          isVulnerable: value === "solicitor" ? false : record.isVulnerable,
          relationshipToDeceased:
            value === "solicitor" ? Relationship.Other : record.relationshipToDeceased,
          relationshipToDeceasedOther:
            value === "solicitor" ? "Solicitor" : record.relationshipToDeceased
        },
        errors: {
          ...errors,
          role: undefined,
          dateOfBirth: undefined,
          isVulnerable: undefined,
          relationshipToDeceased: undefined,
          relationshipToDeceasedOther: undefined
        }
      };

      const newNotifierFormState = clearUiOnRoleChange(state);
      dispatch({
        type: "SET_STATE",
        payload: ui_state_machine(newNotifierFormState.record, !!newNotifierFormState.record.willAvailable),
      });
      setPersistedState(newNotifierFormState);
    },
    [record, errors, setPersistedState]
  );

  const onIsVulnerableChange = React.useCallback(
    (value) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          isVulnerable: value
        },
        errors
      });
    },
    [record, errors, setPersistedState]
  );

  const onNokChange = React.useCallback(
    (value) => {
      dispatch({
        type: "SET_STATE",
        payload: ui_state_machine({ ...record, nok: value }, !!record.willAvailable),
      });
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          nok: value
        },
        errors
      });
    },
    [record, errors, setPersistedState, dispatch]
  );

  const onWillChange = React.useCallback(
    (value) => {
      dispatch({
        type: "SET_STATE",
        payload: ui_state_machine({ ...record, willAvailable: value }, !!value),
      });
      setPersistedState((s) => { return { ...s, hasChanges: true, record: { ...s.record, willAvailable: value } }; });
    },
    [dispatch, record]
  );

  const onDateOfBirthChange = React.useCallback(
    (value) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          dateOfBirth: value
        },
        errors: {
          ...errors,
          dateOfBirth: undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  // Submit callback.
  const onContinue = React.useCallback(
    (justSave?: boolean, nextSection?: Section) => {
      let futureForm = updatedFormFromPersistedState(form, persistedState);
      const errors = validate(futureForm.notifier as Person, uiInfo.intestacyFlow);

      if (errors) {
        setPersistedState({
          hasChanges,
          record,
          errors
        });
        if (!nextSection) {
          return;
        }
      }

      setPersistedState((s) => ({ ...s, remoteError: undefined }));

      if (!hasChanges) {
        continueWithoutChanges(Section.Notifier, justSave);
        return;
      }

      futureForm = prepareUpdate(Section.Notifier, futureForm);

      updateNotifier({
        intestacy: uiInfo.intestacyFlow,
        collectNokDetails: uiInfo.collectNokDetails,
        collectExecutorDetails: uiInfo.collectExecutorDetails,
        caseId,
        signature,
        record: serverRecordFromPersistedState(persistedState)
      }).then(
        (data) => {
          const person = data.notifier;
          setPersistedState((s) => ({ ...s, hasChanges: false }));
          updateSuccessful(futureForm, Section.Notifier, justSave);
          updatePerson(person);
          dispatch({ type: "REFRESH" });

          // if user go back and decided to do nok or executor we need to clear the other form id
          // so it does not do the update , instead create 
          // clear other forms
          if (!uiInfo.intestacyFlow) {
            persistedStateSetters.executor((s: any) => ({ ...s, record: {}, hasChanges: false }));
            persistedStateSetters.nok((s: any) => ({ ...s, record: {}, hasChanges: false }));
          }
        },
        (error) => {
          if (error instanceof ValidationError) {
            setPersistedState((s) => ({
              ...s,
              errors: errorsFromServer(error),
              remoteError: undefined
            }));
          } else {
            setPersistedState((s) => ({
              ...s,
              remoteError: "Operation failed. Please try again or contact customer support."
            }));
          }
          updateFailure(error);
        }
      );
    },
    [
      caseId,
      form,
      hasChanges,
      record,
      signature,
      setPersistedState,
      updateFailure,
      updatePerson,
      updateSuccessful,
      prepareUpdate,
      continueWithoutChanges,
      persistedState
    ]
  );

  const removeRemoteError = React.useCallback(() => {
    setPersistedState((s) => ({ ...s, remoteError: undefined }));
  }, [setPersistedState]);

  return (
    <NotifierDetailsView
      busy={busy}
      errors={errors}
      hasChanges={hasChanges}
      remoteError={persistedState.remoteError}
      removeRemoteError={removeRemoteError}
      record={record}
      updatingNotifierEmailAddress={updatingNotifierEmailAddress}
      onContinue={onContinue}
      onDateOfBirthChange={onDateOfBirthChange}
      onFieldChange={onFieldChange}
      onIsVulnerableChange={onIsVulnerableChange}
      onRoleChange={onRoleChange}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
      onNokChange={onNokChange}
      onWillChange={onWillChange}
    />
  );
};

export type NotifierDetailsViewProps = {
  busy: boolean;
  errors: Errors;
  hasChanges: boolean;
  record: Person;
  updatingNotifierEmailAddress: boolean;
  remoteError?: string;
  removeRemoteError: () => void;
  onRoleChange: (value: string) => void;
  onDateOfBirthChange: (value?: string) => void;
  onContinue: (exit?: boolean, other?: any) => void;
  onFieldChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onIsVulnerableChange: (value: boolean) => void;
  onNokChange: any;
  onWillChange: any;

};

export const NotifierDetailsView: React.FC<NotifierDetailsViewProps> = ({
  busy,
  errors,
  hasChanges,
  record,
  remoteError,
  removeRemoteError,
  onRoleChange,
  onDateOfBirthChange,
  onContinue,
  onFieldChange,
  onSectionClick,
  updateTemplate,
  onIsVulnerableChange,
  onNokChange, onWillChange
}) => {
  const { state: uiState } = React.useContext(NEContext);
  const [askForWill, isAskForNok]: [boolean, boolean] =
    isAskWillAndNok(uiState.intestacyFlow, record.role as NotifierRoles, record.willAvailable);

  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: Section.Notifier,
      onNext: () => onContinue(false),
      onSave: hasChanges ? () => onContinue(true) : undefined,
      onSectionClick
    });
  }, [busy, onContinue, onSectionClick, updateTemplate, hasChanges]);

  return (
    <Stack rowGap={4}> <Header level={1}>Now, let’s find out more about you.</Header>
      <FormStack>
        <FormField
          required={notifierRequiredFields.get("role")}
          halfWidthByItself
          label={
            <span>
              I am a personal representative and I am...{" "} <InfoBoxTrigger
                white
                width="448px"
                content={RepresentativeInfoBox()} />
            </span>
          }
        >
          <SelectInput
            name="role"
            onValueChange={onRoleChange}
            value={record.role || ""}
            options={roleOptions}
            error={errors?.role}
          />
        </FormField>

        <WillExistsQuestion
          caseData={{ record, errors }}
          setCaseData={onWillChange}
          serviceProvider={{ intestacyFlow: uiState.intestacyFlow } as ServiceProvider}
          askForWill={askForWill}
        />
        <NokQuestion
          errors={errors}
          record={record}
          onNokChange={onNokChange}
          askForNok={isAskForNok}
        />
        {(isAskForNok || askForWill) &&
          <FormField> <Typography>Please provide your details below</Typography> </FormField>}

        <FormField halfWidthByItself label="Title" required={notifierRequiredFields.get("title")}>
          <NewTitleInput
            name="title"
            onValueChange={(value) => onFieldChange({ target: { name: "title", value } })}
            value={record.title || ""}
            error={errors?.title}
          />
        </FormField>

        <FormField halfWidth label="First name" required={notifierRequiredFields.get("firstName")}>
          <TextInput
            name="firstName"
            value={record.firstName || ""}
            error={errors?.firstName}
            onChange={onFieldChange}
          />
        </FormField>

        <FormField halfWidth label="Last name" required={notifierRequiredFields.get("lastName")}>
          <TextInput
            name="lastName"
            value={record.lastName || ""}
            error={errors?.lastName}
            onChange={onFieldChange}
          />
        </FormField>

        {record.role !== "solicitor" && (
          <FormField halfWidthByItself label="Date of birth" required={notifierRequiredFields.get("dateOfBirth")}>
            <DateInput
              value={record.dateOfBirth || ""}
              onValueChange={onDateOfBirthChange}
              error={errors?.dateOfBirth}
              pastOnly
            />
          </FormField>
        )}

        <FormField label="Address" required={notifierRequiredFields.get("address")}>
          <NewAddressInput
            name="address"
            value={record.address || ""}
            onChange={onFieldChange}
            error={errors?.address}
          />
        </FormField>

        <FormField halfWidth label="City or Town" required={notifierRequiredFields.get("city")}>
          <TextInput
            name="city"
            value={record.city || ""}
            onChange={onFieldChange}
            error={errors?.city}
          />

        </FormField>

        <FormField halfWidth label="Postcode" required={notifierRequiredFields.get("postcode")}>
          <TextInput
            name="postcode"
            value={record.postcode || ""}
            onChange={onFieldChange}
            error={errors?.postcode}
          />
        </FormField>

        <FormField halfWidth label="Telephone number" required={notifierRequiredFields.get("contactNumber")}>
          <PhoneNumberTextInput
            name="contactNumber"
            value={record.contactNumber || ""}
            onChange={onFieldChange}
            error={errors?.contactNumber}
          />
        </FormField>

        <FormField halfWidth label="Email address" required={notifierRequiredFields.get("email")}>
          <EmailAddressInput
            name="email"
            value={record.email || ""}
            onChange={onFieldChange}
            error={errors?.email}// autoFocus={updatingNotifierEmailAddress}
          />
        </FormField>

        {record.role !== "solicitor" && (
          <FormField>
            <BooleanInput
              label={
                <span>
                  I feel vulnerable (temporarily or long-term) and would appreciate support{" "} <InfoBoxTrigger
                    white
                    width="448px"
                    content={VulnerableInfoBox()} />
                </span>
              }
              value={record.isVulnerable}
              onValueChange={onIsVulnerableChange}
            /> </FormField>
        )}

        {record.role !== "solicitor" && (
          <FormField
            required={notifierRequiredFields.get("relationshipToDeceased")}
            halfWidthByItself
            label={<span>The person who died was my/their... &nbsp; {IntestacyInfoBox({})}</span>}
          > <RelationToDeceased
              name="relationshipToDeceased"
              value={record.relationshipToDeceased || ""}
              otherValue={record.relationshipToDeceasedOther || ""}
              error={errors.relationshipToDeceased || errors.relationshipToDeceasedOther}
              onFieldChange={onFieldChange}
            /> </FormField>
        )}
      </FormStack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar> </Stack>
  );
};


export const validate = (record: Person, intestacyFlow: boolean): PersonErrors | undefined => {
  const errors: PersonErrors = {};

  notifierRequiredFields.forEach((required: boolean, key: keyof Person, _map: Map<keyof Person, boolean>) => {
    switch (key) {
      case "email":
        if (!record.email) {
          errors.email = "required";
        } else if (!validEmailAddress(record.email || "")) {
          errors.email = "Not a valid email address";
        }
        break;

      case "role":
        if (!record.role) {
          errors.role = "required";
        } else {
          if (intestacyFlow) {
            const needsNok =
              record.role === "administrator" ||
              (record.role === "delegated_notifier" && !record.willAvailable);

            if (needsNok && record["nok"] === undefined) {
              errors.nok= "required" ;
            }
          }
        }
        break;

      case "dateOfBirth":
        if (required && !record.dateOfBirth && record.role !== "solicitor") {
          errors.dateOfBirth = "Required"
        } else if (record.dateOfBirth) {
          if (!isValidDateString(record.dateOfBirth)) {
            errors.dateOfBirth = "Invalid Date"
          }
        }
        break;

      case "relationshipToDeceased":
        if (record.role !== "solicitor" && !record.relationshipToDeceased) {
          errors.relationshipToDeceased = "required";
        } else {
          const cond: boolean = record.role !== "solicitor" && record.relationshipToDeceased === Relationship.Other && !record.relationshipToDeceasedOther;
          if (cond) {
            errors.relationshipToDeceasedOther = "required";
          }
        }
        break;

      default:
        if (!record || !record[key]) {
          errors[key] = "required";
        }
        break;
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const isNotifierFormComplete = (form: NonExpressFormData, intestacyFlow: boolean): boolean => validate(form.notifier as Person, intestacyFlow) === undefined;

const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    firstName: ve.errors.firstName ? ve.errors.firstName[0] : undefined,
    lastName: ve.errors.lastName ? ve.errors.lastName[0] : undefined,
    email: ve.errors.email ? ve.errors.email[0] : undefined
  };

  return JSON.parse(JSON.stringify(errors));
};

function clearUiOnRoleChange(data: NotifierDetailsPersistedState): NotifierDetailsPersistedState {
  switch (data.record.role) {
    case NotifierRoles.SoleExecutor:
    case NotifierRoles.Executor:
    case NotifierRoles.Solicitor:
      return { ...data, record: { ...data.record, nok: false, willAvailable: true } };
    case NotifierRoles.Administrator:
      return { ...data, record: { ...data.record, nok: undefined, willAvailable: false } };
    case NotifierRoles.DelegatedNotifier:
      return { ...data, record: { ...data.record, nok: undefined, willAvailable: undefined } };
  }
  return data;
}
