import * as React from "react";

import { Button } from "@atoms/Button";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "@styles/constants";
import { Sponsors } from "@organisms/Sponsors";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { Header } from "@atoms/Header";

type Props = { caseId: string; signature: string | null };

export const OpenBankingLinkPage: React.FC<Props> = ({ caseId, signature }) => {
  const search = !signature ? "" : `?case_id=${caseId}&sig=${signature}`;
  const redirectUrl = `${process.env.REACT_APP_BACKEND_HOST}/experiments/openbanking/auth-redirect${search}`;

  return <OpenBankingLinkPageView redirectUrl={redirectUrl} />;
};

export type OpenBankingLinkPageViewProps = {
  redirectUrl: string;
};

export const OpenBankingLinkPageView: React.FC<OpenBankingLinkPageViewProps> = ({
  redirectUrl
}) => {
  const classes = useStyles();

  return (
    <SimpleTemplate headerType="logo">
      <div className={classes.textContainer}>
        <div>
          <Header level={1}>Settld Account Search</Header>

          <Grid container spacing={3}> <Grid item xs={12} md={6} className={classes.additional}>
            <p>
              When someone dies, they often leave behind a number of standing orders and direct debits. For example,
              subscriptions to magazines, gardening, healthcare, charities, food and wine boxes… the list goes on. These
              are often hard for families to find, and then take time to individually contact to notify of a death.
            </p>
            <p>
              If, for instance, the accounts being closed relate a small to medium-sized business owner, the problem is
              further amplified. Services linked to the deceased’s business bank account (IT, marketing, operational
              etc) can cost hundreds of pounds a month if not notified promptly.
            </p>

            <p>
              Using <b>Settld Account Search</b>, we can find and cancel these subscriptions, with the click of a
              button. Free of charge.
            </p>

            <div className={classes.formContainer}>
              <Button variant="primary" size="large" href={redirectUrl}> Get Started </Button>
            </div>

            <div style={{ paddingTop: "15px" }}></div>
          </Grid>

            <Grid item xs={12} md={6}> <Container className={classes.rightContainer}> <H3>The Settld promise</H3>

              <ul className={classes.ul}>
                <li className={classes.li}>The service is safe, secure, and free</li>
                <li className={classes.li}>We’ll keep you regularly updated</li>
                <li className={classes.li}>We’ll close your accounts as quickly as possible</li>
              </ul>
            </Container> </Grid> </Grid>
        </div>

        <Sponsors />
      </div>
    </SimpleTemplate>
  );
};

const H3: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return <h3 className={classes.h3}>{children}</h3>;
};

const useStyles = makeStyles({
  h1: {
    color: colors.black,
    fontSize: "2rem",
    marginBottom: "1rem",
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: 0
  },

  textContainer: {
    color: colors.gray,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1
  },

  formContainer: {
    maxWidth: "290px"
  },

  h3: {
    color: colors.veryDarkBlue,
    fontSize: "1.2rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
    marginTop: 0
  },

  rightContainer: {
    backgroundColor: colors.lightBlue,
    borderRadius: "5px",
    lineHeight: 2,
    padding: "20px"
  },

  additional: {
    marginBottom: "20px"
  },

  ul: {
    listStyle: "none",
    paddingLeft: 0,
    margin: 0
  },

  li: {
    "&::before": {
      backgroundColor: "#73a5c4",
      content: "\"\"",
      display: "inline-block",
      height: "10px",
      width: "10px",
      borderRadius: "10px",
      marginRight: "10px"
    }
  },

  sponsors: {
    paddingTop: "30px"
  },

  sponsorsLine: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },

  sponsor: {
    padding: "10px",
    textAlign: "center"
  },

  imgWidth: {
    width: "216px"
  },

  imgHeight: {
    height: "65px"
  }
});
