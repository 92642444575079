import { ServiceProvider } from "@customTypes/ServiceProvider";
import { getExpressSectionDataByKey, putExpressSectionDataByKey } from "@src/pages/ExpressForm/express-data";
import { useReducer, createContext, useEffect } from "react";

export type SPErrors = Partial<Record<keyof ServiceProvider, string>>;

export type SPInfo = ServiceProvider;

const initialState = {  } as SPInfo;

const SPContext = createContext<{ state: SPInfo; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => null,
});

const actionTypes = {
  SET_SP: "SET_SP",
};

function reducer(state: SPInfo, action: { type: string; payload: any }): SPInfo {
  switch (action.type) {
    case actionTypes.SET_SP:
      return action.payload ;
    default:
      return initialState;
  }
}
const SPProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const source = getExpressSectionDataByKey("express_sp");
    if (source) {
      dispatch({ type: actionTypes.SET_SP, payload: source });
    }
  }, []);

  useEffect(() => {
    putExpressSectionDataByKey("express_sp", state);
  }, [state]);

  return <SPContext.Provider value={{ state, dispatch }}>{children}</SPContext.Provider>;
};

export { SPContext, SPProvider };
