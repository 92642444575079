import { useReducer, createContext, useEffect } from "react";
import {
  putExpressSectionDataByKey,
  getExpressSectionDataByKey,
} from "@pages/ExpressForm/express-data";

import React from "react";

export type UiInfo = {
  willExists: boolean;
  collectNokDetails: boolean;
  collectExecutorDetails: boolean;
};

const initialState = {
  willExists: false,
  collectNokDetails: false,
  collectExecutorDetails: false,
};

const ExpressUiInfoContext = createContext<{
  state: UiInfo;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

const actionTypes = {
  SET_COLLECT_NOK_DETAILS: "SET_COLLECT_NOK_DETAILS",
  SET_COLLECT_EXECUTOR_DETAILS: "SET_COLLECT_EXECUTOR_DETAILS",
  SET: "SET",
  SET_STATE: "SET_STATE",
};

function reducer(state: UiInfo, action: { type: string; payload: any }): UiInfo {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.SET_COLLECT_NOK_DETAILS:
      return { ...state, collectNokDetails: payload };
    case actionTypes.SET_COLLECT_EXECUTOR_DETAILS:
      return { ...state, collectExecutorDetails: payload };
    case actionTypes.SET:
      return { ...state, ...action.payload };
    case actionTypes.SET_STATE:
      return {
        willExists: payload.willExists,
        collectExecutorDetails: payload.collectExecutorDetails,
        collectNokDetails: payload.collectNokDetails,
      };
    default:
      return initialState;
  }
}

const ExpressUiInfoProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const source = getExpressSectionDataByKey("ui_info");

    if (source) {
      dispatch({ type: actionTypes.SET, payload: source });
    }
  }, []);

  useEffect(() => {
    putExpressSectionDataByKey("ui_info", state);
  }, [state.willExists,state.collectNokDetails,state.collectExecutorDetails]);

  return (
    <ExpressUiInfoContext.Provider value={{ state, dispatch }}>
      {children}
    </ExpressUiInfoContext.Provider>
  );
};
export { ExpressUiInfoContext, ExpressUiInfoProvider }
