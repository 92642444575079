import { apiFetch } from "./restApi";
import { createDocumentAndUpload } from "./caseApi";
import monitor from "../../utils/monitoring";

export const getMessages = async (args: {
  caseId: string;
  signature: string | null;
  accountId: string | undefined;
}) => {
  const { caseId, signature, accountId } = args;

  if (!accountId) throw new Error("Account not found");

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${accountId}/messages`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "GET",
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}/accounts/{accountId}/messages",
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const refreshMessages = async (args: {
  caseId: string;
  signature: string | null;
  accountId: string | undefined;
}) => {
  const { caseId, signature, accountId } = args;
  if (!accountId) throw new Error("Account not found");

  return await apiFetch({
    path: `/notifier/cases/${caseId}/accounts/${accountId}/messages`,
    search: !signature ? undefined : { caseId, sig: signature },
    method: "GET",
  });
};

export const deleteMessage = async (args: {
  caseId: string;
  signature: string | null;
  accountId: string | undefined;
  messageId: string;
}) => {
  const { caseId, signature, accountId, messageId } = args;
  if (!accountId) throw new Error("Account not found");

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${accountId}/messages/${messageId}`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "DELETE",
    });
  } catch (error) {
    monitor.logError({
      event: `DELETE /notifier/cases/{caseId}/accounts/{accountId}/messages/{messageId}`,
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const markMessageAsRead = async (args: {
  caseId: string;
  signature: string | null;
  accountId: string | undefined;
  messageId: string;
}) => {
  const { caseId, signature, accountId, messageId } = args;
  if (!accountId) throw Error("account not found");

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${accountId}/messages/${messageId}/read`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/accounts/{accountId}/messages/{messageId}/read`,
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const submitMessage = async ({
  caseId,
  signature,
  accountId,
  content,
  attachment: file,
}: {
  caseId: string;
  signature: string | null;
  accountId: string | undefined;
  content: string;
  attachment: File | null;
}) => {
  if (!accountId) throw new Error("Account not found");

  let documentId;
  if (file) {
    const r = await createDocumentAndUpload(caseId, signature, file, undefined, []);
    documentId = r.id;
  }

  return createMessage({ caseId, signature, accountId, content, documentId });
};

export const createMessage = async (args: {
  caseId: string;
  signature: string | null;
  accountId: string;
  content: string;
  documentId?: string;
}) => {
  const { caseId, signature, accountId, content, documentId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${accountId}/messages`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { record: { content, documentId } },
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/accounts/{accountId}/messages`,
      args,
      error,
    });
    throw error;
  }

  return result;
};
