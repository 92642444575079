import * as React from "react";

import { FormControl, FormHelperText, TextField } from "@mui/material";
import { CalendarPickerView, DesktopDatePicker } from "@mui/x-date-pickers";

import { extractDateString } from "../../utils/Functions";

export type DateInputProps = {
  value?: string;
  onValueChange: (value?: string) => void;
  error?: string;
  pastOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

const views = ["year", "month", "day"] as readonly CalendarPickerView[];

export const DateInput: React.FC<DateInputProps> = ({
  value,
  error,
  pastOnly,
  placeholder = "dd/mm/yyyy",
  disabled,
  onValueChange,
}) => {
  // messed up component need to redone
  // TODO:
  // we are not setting the date for each keyboard input only in valid date formats
  const handleChange = React.useCallback(
    (value) => {
      if (!value || isNaN(value.getTime())) {
        onValueChange(undefined);
      } else {
        const year = value.getFullYear();
        // limits the date entered via keyboard , or copy paste
        if (year >= 1900 && year <= 2050) {
          onValueChange(extractDateString(value));
        }
      }
    },
    [onValueChange]
  );
  return (
    <FormControl error={!!error} sx={{ width: "100%" }}>
      <DesktopDatePicker
        // as={dob ? ExtendedDatePicker : DatePicker}
        // name={name}
        // className="form-control"
        // maxDate={pastOnly ? now : undefined}
        // selected={value ? new Date(value) : null}
        // placeholderText={placeholder}
        disableFuture={pastOnly}
        disabled={disabled}
        // limits the gui date picker
        minDate={new Date("1900-01-01")}
        //  The Date constructor expects the date string to be in a format that conforms to the ISO 8601 standard or RFC 2822
        // causes issue with mozilla fire fox
        maxDate={new Date("2050-01-01")}
        inputFormat="dd/MM/yyyy"
        value={value || null}
        views={views}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} error={!!error} size="small" />
        )}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
