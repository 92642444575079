import * as React from "react";

import { Alert, Snackbar } from "@mui/material";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, AccountNextStep, ServiceProvider, ServiceProviderType } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { useOnChange, useUpdater } from "@utils/callbacks";
import { Person } from "./ResponsibleFields";
import {
  DeceasedDetailsFields,
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord
} from "./DeceasedDetailsFields";
import { AccountFieldProps } from "./AccountForm";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly profileUrl?: string;
};

export type Errors =
  | undefined
  | {
  readonly deceased?: DeceasedErrors;
  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly profileUrl?: string;
};

const accountRequest = AccountNextStep.Cancel;

export const errorsOf: (r: Record, askForDeceasedDetails: boolean) => Errors = (
  record,
  askForDeceasedDetails
) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    emailAddress: record?.emailAddress ? undefined : "required"
    // phoneNumber: record?.phoneNumber ? undefined : "required",
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {}
    };
  }

  return {
    deceased: {},
    emailAddress: account.emailAddress,
    phoneNumber: account.phoneNumber,
    profileUrl: account.profileUrl
  };
};

export type SocialMediaOrEmailAccountFieldsProps = AccountFieldProps;

export const SocialMediaOrEmailAccountFields: React.FC<SocialMediaOrEmailAccountFieldsProps> = ({
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  initialRecord,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false
}) => {
  const [record, update] = React.useState(
    savedRecord || (initialRecord && !account ? initialRecord : recordFromAccount(account))
  );
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");
  const onChange = useOnChange(update);

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      onNext: !serviceProvider?.serviceProviderType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              serviceProviderType: serviceProvider.serviceProviderType
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            emailAddress: record.emailAddress,
            phoneNumber: record.phoneNumber,
            profileUrl: record.profileUrl,
            nextStep: accountRequest
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);

          return saveAccount({
            sector: ServiceProviderType.SocialMedia,
            record: data
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        }
    });
  }, [updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy, saveAccount]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField halfWidth label="Associated email address"> <TextInput
        name={"emailAddress"}
        value={record.emailAddress || ""}
        error={errors?.emailAddress}
        onChange={onChange}
      /> </FormField>

      {serviceProvider.serviceProviderType !== ServiceProviderType.Email && (
        <>
          <FormField halfWidth label="Associated phone number"> <TextInput
            name={"phoneNumber"}
            value={record.phoneNumber || ""}
            error={errors?.phoneNumber}
            onChange={onChange}
          /> </FormField>

          <FormField twoThirdsWidthByItself label="Associated profile link (if available)"> <TextInput
            name={"profileUrl"}
            value={record.profileUrl || ""}
            error={errors?.profileUrl}
            onChange={onChange}
          /> </FormField>
        </>
      )}

      <FormField>We will assist you in closing this account.</FormField>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};
