import * as React from "react";

import { EditOutlined } from "@mui/icons-material";
import { Stack, styled, Table, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";

import { colors } from "@styles/constants";
import { Header } from "@atoms/Header";
import { Link } from "@atoms/Link";

export type FieldsTableProps = {
  readonly header?: React.ReactNode;
  readonly children: React.ReactNode;
};

export const FieldsTable: React.FC<FieldsTableProps> = ({ header, children }) => {
  return (
    <Stack rowGap={2}>
      {header} <Table size="small">
      <colgroup>
        <col style={{ width: "30%" }} />
        <col style={{ width: "70%" }} />
      </colgroup>
      <TableBody>{children}</TableBody> </Table> </Stack>
  );
};

export type FieldRowProps = {
  readonly label: string;
  readonly value?: React.ReactNode;
};

export const FieldRow: React.FC<FieldRowProps> = ({ label, value }) => {
  return (
    <StyledTableRow> <TableCell> <Typography color={colors.secondaryText}>{label}</Typography> </TableCell> <TableCell>
      <Typography color={colors.primaryText}>{value}</Typography> </TableCell> </StyledTableRow>
  );
};

const StyledTableRow = styled(TableRow)(_ => ({
  "& td:first-of-type": {
    paddingLeft: 0
  },
  "& td": {
    verticalAlign: "top",
    border: 0
  }
}));

export type FieldsTableHeaderProps = {
  readonly title: string;
  readonly onEdit: () => void;
};

export const FieldsTableHeader: React.FC<FieldsTableHeaderProps> = ({ title, onEdit }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline"> <Header
      level={2}
      color={theme.palette.primary.light}>
      {title}
    </Header> <Link inline variant="action" onClick={onEdit} icon={<EditOutlined fontSize="small" />}> Edit </Link>
    </Stack>
  );
};
