import * as React from "react";

import { AccountDetails, Document, MeterType, UploadRecord } from "@src/types";

import { cleanedErrors } from "@utils/Functions";
import { DateInput } from "@atoms/DateInput";
import { RadioGroupInput } from "@atoms/RadioGroupInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@molecules/InfoBoxTrigger";
import { useOnValueChange } from "@utils/callbacks";
import { NewAccountRecord } from "@api/online-api";
import { SimpleUploadField } from "@organisms/SimpleUploadField";

export type Record = {
  readonly meterType?: MeterType;
  readonly lastMeterReadingDate?: string;
  readonly gasDocuments: UploadRecord[];
  readonly electricityDocuments: UploadRecord[];
};

export type Errors =
  | undefined
  | {
    readonly meterType?: string;
    readonly lastMeterReadingDate?: string;
    readonly gasDocuments?: string;
    readonly electricityDocuments?: string;
  };

export const errorsOf: (r?: Record) => Errors = (record) => {
  // const requiresReadings = record?.meterType === MeterType.Credit || record?.meterType === MeterType.PayAsYouGo;

  const requiresReadings = false;

  return cleanedErrors({
    meterType: record?.meterType ? undefined : "required",
    lastMeterReadingDate:
      !requiresReadings || record?.lastMeterReadingDate ? undefined : "required",
    gasFiles: !requiresReadings || (record?.gasDocuments || []).length > 0 ? undefined : "required",
    electricityFiles:
      !requiresReadings || (record?.electricityDocuments || []).length > 0 ? undefined : "required"
  });
};

export const recordFromAccount = (account: AccountDetails | undefined): Record => {
  if (!account) {
    return {
      gasDocuments: [],
      electricityDocuments: []
    };
  }

  return {
    meterType: account.meterType,
    lastMeterReadingDate: account.lastMeterReadingDate,
    gasDocuments:
      account.documents
        ?.filter(({ tags }) => tags?.includes("gas_meter_reading"))
        .map(({ id, name }) => ({ id, filename: name })) || [],
    electricityDocuments:
      account.documents
        ?.filter(({ tags }) => tags?.includes("electricity_meter_reading"))
        .map(({ id, name }) => ({ id, filename: name })) || []
  };
};

export const accountFieldsFromRecord = (record?: Record): Partial<NewAccountRecord> => {
  if (!record) {
    return {};
  }

  return {
    meterType: record.meterType,
    lastMeterReadingDate: record.lastMeterReadingDate,
    documentIds: [...record.gasDocuments, ...record.electricityDocuments].map((d) => d.id)
  };
};

export type EnergyMeterInfoFieldsProps = {
  record: Record;
  errors?: any;
  update: any;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  // updateError: any;
};

// function makeSetError(
//   field: "gasDocuments" | "electricityDocuments",
//   updateError: any
// ) {
//   return (errorMsg: string) => {
//     // We assume `update` merges partial state
//     updateError((prevState: { record: Record; errors?: Errors }) => ({
//       ...prevState,
//       errors: {
//         ...(prevState.errors || {}),
//         [field]: errorMsg,
//       },
//     }));
//   };
// }

export const EnergyMeterInfoFields: React.FC<EnergyMeterInfoFieldsProps> = ({
  record,
  errors,
  update,
  createDocumentAndUpload
}) => {
  const onMeterTypeChange = useOnValueChange(update, "meterType");
  const onReadingDateChange = useOnValueChange(update, "lastMeterReadingDate");

  const documentsUploader =
    (name: "gasDocuments" | "electricityDocuments") => (files: ReadonlyArray<File>) => {
      const prefix = {
        gasDocuments: "gas_meter_reading",
        electricityDocuments: "electricity_meter_reading"
      }[name];

      const tags = {
        gasDocuments: ["gas_meter_reading"],
        electricityDocuments: ["electricity_meter_reading"]
      }[name];

      const uploads = files.map((file, i) => {
        const index = record[name].length + i;
        const suffix = index === 0 ? "" : `_${index}`;
        const filename = `${prefix}${suffix}`;

        return createDocumentAndUpload(file, filename, tags).then(
          ({ id, filename }) => {
            return { id, filename };
          },
          (_error) => null
        );
      });

      Promise.all(uploads).then((uploads) => {
        uploads = uploads.filter((u) => u !== null);
        update((record: Record) => {
          return {
            ...record,
            [name]: [...(record[name] || []), ...uploads]
          };
        });
      });
    };

  const documentRemover = (name: "gasDocuments" | "electricityDocuments") => (id: string) => {
    update((record: Record) => {
      return {
        ...record,
        [name]: record[name].filter((d) => d.id !== id)
      };
    });
  };

  return (
    <>
      <FormField label="Meter information">
        <RadioGroupInput
          onValueChange={onMeterTypeChange}
          value={record.meterType}
          fields={meterTypeFields}
          error={errors?.meterType}
        />
      </FormField>

      {(record.meterType === MeterType.Credit || record.meterType === MeterType.PayAsYouGo) && (
        <FormStack substack> <FormField label="Date of the readings"> <DateInput
          value={record.lastMeterReadingDate || ""}
          onValueChange={onReadingDateChange}
          error={errors?.lastMeterReadingDate}
          pastOnly
        /> </FormField>

          <FormField
            label={
              <span>
                If you have it, please upload a picture of the <b>gas</b> meter reading
              </span>
            }
          > <SimpleUploadField
              busy={false}
              entries={record.gasDocuments}
              upload={documentsUploader("gasDocuments")}
              remove={documentRemover("gasDocuments")}
              error={errors?.gasDocuments}
              // setError={(_error: any) => { }}
              setError={(_error: any) => { 
                window.alert(
                  `Supported types are: PDF, JPEG, PNG. Max size: 3MB.`
                );
              }}
              // setError={makeSetError("gasDocuments", update)}
            />

            {/* <UploadField
              uploadedFileInfo={uploadedFileInfo}
              busy={false}
              record={record.gasDocuments}
              update={onGasDocumentsChange}
              error={errors?.gasDocuments ? errors.gasDocuments : undefined}
              setError={() => {}}
              tag="gas_meter_reading"
            /> */}
          </FormField>

          <FormField
            label={
              <span>
                If you have it, please upload a picture of the <b>electricity</b> meter reading
              </span>
            }
          >
            <SimpleUploadField
              busy={false}
              entries={record.electricityDocuments}
              upload={documentsUploader("electricityDocuments")}
              remove={documentRemover("electricityDocuments")}
              error={errors?.electricityDocuments}
              setError={(_error: any) => { 
                window.alert(
                  `Supported types are: PDF, JPEG, PNG. Max size: 3MB.`
                );
              }}
              // setError={makeSetError("electricityDocuments", update)}
            />

            {/* <UploadField
              uploadedFileInfo={uploadedFileInfo}
              busy={false}
              record={record.electricityDocuments}
              update={onElectricityDocumentsChange}
              error={errors?.electricityDocuments ? errors.electricityDocuments : undefined}
              setError={() => {}}
              tag="electricity_meter_reading"
            /> */}
          </FormField> </FormStack>
      )}
    </>
  );
};

const meterTypeFields = [
  { value: MeterType.SmartMeter, label: "Smart meter" },
  { value: MeterType.Credit, label: "Credit" },
  { value: MeterType.PayAsYouGo, label: "Pay as you go" },
  {
    value: MeterType.Unknown,
    label: (
      <>
        I don't know or I cannot provide a meter reading{" "} <InfoBoxTrigger
          content={
            <div>
              <p>
                <b>Don't have meter readings?</b> If you can't give us one today, the provider will use the previous meter
                reading as an estimate.
              </p>
            </div>
          }
        />
      </>
    )
  }
];
