

import * as React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { setLocalSession, useLocalSession } from "@utils/session";
import urlPaths from "./urlPaths";
import { AdminEntrypoint } from "@pages/AdminEntrypoint";
import { ForgotPasswordPage } from "@pages/Authentication/ForgotPasswordPage";
import { ResetPasswordPage } from "@pages/Authentication/ResetPasswordPage";
import { SigninPage } from "@pages/Authentication/SigninPage";
import { SignupPage } from "@pages/Authentication/SignupPage";
import { StatusPage } from "./pages/Dashboard";
import { BrokenLinkPage } from "@pages/Error/BrokenLinkPage";
import { ExpressFormPage } from "@pages/ExpressForm";
import { FormRatingPage } from "@pages/FormEpilogue/FormRatingPage";
import { KYCPage } from "@pages/KYCPage";
import { NonExpressFormPage } from "@pages/NotifierForm";
import { PermissionPage } from "@pages/Onboarding/PermissionPage";
import { OpenBankingCallbackPage } from "@pages/OpenBanking/CallbackPage";
import { OpenBankingDonePage } from "@pages/OpenBanking/DonePage";
import { OpenBankingErrorPage } from "@pages/OpenBanking/ErrorPage";
import { OpenBankingLinkPage } from "@pages/OpenBanking/LinkPage";
import { RootPage } from "@pages/RootPage";
import { ExpressKYCPage } from "@pages/ExpressForm/ExpressKYCPage";
import { RegistrationPage } from "@pages/registration/RegistrationPage";
import { OnBoardingAdditionalServicesPage } from "@pages/Onboarding/OnboardingAdditionalServicesPage";
import { OnBoardingInfoPage } from "@pages/Onboarding/OnboardingInfoPage";
import { AdditionalServicesPage } from "@pages/FormEpilogue/AdditionalServicesSimplePage";
import { ExpressProvider } from "@store/ExpressProvider";
import { DashboardInfoProvider } from "@store/DashboardProvider";
import { NEUiInfoProvider } from "@store/NonExpressState";
// import IframeTest from "./pages/IframeTest";

// flow
// submit -> 8
// kyc -> 7
// documents -> 6
// accounts -> 5
// executors -> 4
// deceased -> 3
// notifier -> 2
// permission -> 1
// requirements -> 0 -> 0.1 onboarding additional services -> 0.2 onboarding info page
function logIsolation(): void{
  if (self.crossOriginIsolated) console.log("isolation")
    else console.log("no isolation")
}

export const AppRoutes = (): React.ReactElement => {
  logIsolation()
  return (
    <Routes>
      <Route path="regform-registrar" element={<RegistrationPage />} />

      <Route path="/notifier-form/index.html" element={<LegacyURL />} />
      
      <Route path="index.html" element={<RootPage />} />

      <Route path="/" element={<RootPage />}></Route>

      <Route
        path="sign-up"
        element={<SignupPage nextPageURL={urlPaths.onBoardingAdditionalServices()} />}
      />

      <Route
        path="sign-up/:website"
        element={<SignupPage nextPageURL={urlPaths.onBoardingAdditionalServices()} />}
      />

      <Route
        path="onboarding/additional-services"
        element={<WrappedOnboardingAdditionalServicesPage />}
      />

      <Route path="onboarding/info-page" element={<WrappedOnBoardingInfoPage />} />

      <Route path="onboarding/permission" element={<WrappedPermissionPage />} />

      <Route
        path="express/:slug/*"
        element={
          <ExpressProvider> <ExpressFormPage /> </ExpressProvider>
        }
      />

      <Route path="express-kyc" element={<ExpressKYCPage />} />

      <Route path="sign-in" element={<SigninPage nextPageURL={urlPaths.root()} />} />

      <Route path="forgot-password" element={<ForgotPasswordPage />} />

      <Route path="reset-password/:token" element={<WrappedResetPasswordPage />} />

      <Route path="form/*" element={<WrappedNonExpressFormPage />} />

      <Route path="kyc" element={<WrappedKYCPage />} />

      <Route path="form-rating" element={<WrappedFormRatingPage />} />

      <Route path="additional-services" element={<WrappedAdditionalServicesPage />} />

      <Route path="status/*" element={<WrappedStatusPage />} />

      <Route path="account-search" element={<WrappedOpenBankingLinkPage />} />

      <Route path="account-search/callback" element={<OpenBankingCallbackPage />} />

      <Route path="account-search/done" element={<OpenBankingDonePage />} />

      <Route path="account-search/error" element={<WrappedOpenBankingErrorPage />} />
      {/* <Route path="iframetest" element={<IframeTest />} /> */}

      <Route path="admin/*" element={<AdminEntrypoint />} />

      <Route path="*" element={<BrokenLinkPage />} />
    </Routes>
  );
};

const WrappedResetPasswordPage = () => {
  const { token } = useParams() as { token: string };
  
  if (!token) return null;
  
  return <ResetPasswordPage token={token} />;
};

const WrappedOnBoardingInfoPage = () => {
  const { caseId } = useLocalSession(urlPaths.signup());

  if (!caseId) return null;

  return <OnBoardingInfoPage navigateNext={urlPaths.permission()} />;
};

const WrappedOnboardingAdditionalServicesPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) return null;

  return (
    <OnBoardingAdditionalServicesPage
      caseId={caseId}
      signature={signature}
      navigateNext={urlPaths.onBoardingInfo()}
    />
  );
};

const WrappedPermissionPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

if (!caseId) return null;

  return <PermissionPage caseId={caseId} signature={signature} nextPageURL={urlPaths.form()} />;
};

const WrappedNonExpressFormPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) return null;

  return (
    <NEUiInfoProvider>
      <NonExpressFormPage caseId={caseId} signature={signature} /> 
    </NEUiInfoProvider>
  );
};

const WrappedKYCPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

if (!caseId) return null;

  return <KYCPage caseId={caseId} signature={signature} />;
};

const WrappedAdditionalServicesPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

if (!caseId) return null;

  return <AdditionalServicesPage caseId={caseId} signature={signature} />;
};

const WrappedFormRatingPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

if (!caseId) return null;

  return <FormRatingPage caseId={caseId} signature={signature} />;
};

const WrappedStatusPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());
  const { accountId } = useParams() as {
    accountId: string | undefined;
    admin: string | undefined;
  };

  if (!caseId) return null;

  return (
  <DashboardInfoProvider> 
    <StatusPage caseId={caseId} signature={signature} accountId={accountId} />
  </DashboardInfoProvider>
  );
};

const legacyWrapper = (component: React.ComponentType<{ caseId: string; signature: string | null }>) => {
  const WrappedComponent = () => {
    const navigate = useNavigate();
    const [{ caseId, signature }, setState] = React.useState({ caseId: null, signature: null } as {
      caseId: string | null;
      signature: string | null;
    });

    React.useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      const caseId = query.get("case_id") || null;
      const signature = query.get("sig") || null;
      if (!caseId) {
        navigate(`/not-found`, { replace: true });
        return;
      }
      setState({ caseId, signature });
    }, [navigate]);

    if (!caseId) return null;

    return React.createElement(component, { caseId, signature });
  };
  WrappedComponent.displayName = `LegacyWrapper(${component.displayName || component.name || "Component"})`;
  return WrappedComponent;
};

const WrappedOpenBankingLinkPage = legacyWrapper(OpenBankingLinkPage);
const WrappedOpenBankingErrorPage = legacyWrapper(OpenBankingErrorPage);

const LegacyURL = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const caseId = query.get("case_id");
    const signature = query.get("sig");
    query.delete("case_id");
    query.delete("sig");
    if (caseId && signature) setLocalSession({ caseId, signature });
    navigate(`${urlPaths.root()}?${query.toString()}`);
  }, [navigate]);

  return null;
};


// const LegacyURL = () => {
//   // generated by backend so user cant do so and its for cases which are in progress
//   const navigate = useNavigate();

//   React.useEffect(() => {
//       // /notifier-form/index.html?sig=the_sig&case_id=case_gid&express_form=true&nfs_sumbit=false&add_accounts=true&account_id=accountid
//     const query = new URLSearchParams(window.location.search);
//     const caseId = query.get("case_id") || null;
//     const signature = query.get("sig") || null;
//     const add_accounts = query.get("add_accounts") || null;
//     const account_id = query.get("account_id") || null;
//     const express_form = query.get("express_form") || null;
//     const nfs_submit = query.get("nfs_submit") || null;
    
//     if (!Session.validValues(signature,caseId)) {
//       // no signature
//       navigate(urlPaths.signin());
//       return;
//     }

//     // valid token
//     Session.put(signature,caseId);

   
//     if(account_id !== null){
//       // navigate to account in tthe dashboard
//       // &express_form=true&nfs_sumbit=false&account_id=accountid
//       navigate(urlPaths.status_query(account_id))
//       return;
//     }

//     if (express_form === "true" && nfs_submit === "false" && add_accounts==="true" && account_id === null) {
//       // user submitted a express cases and now wants to add more accoutns
//       // &express_form=true&nfs_sumbit=false&add_accounts=true
//       // navigate to complete the notifier form
//       navigate(urlPaths.etne_account());
//       return;
//     }

//     if (nfs_submit === "true" && account_id === null && add_accounts ==="true") {
//       // &express_form=false|true&nfs_sumbit=true&add_accounts=true
//       // express cases converteed to non express cases and non express cases
//       // navigate to new accounts url 
//       navigate(urlPaths.new_account());
//       return;
//     }

//     if (regularLink(add_accounts, account_id, express_form, nfs_submit)) {
//       // /notifier-form/index.html?sig=the_sig&case_id=case_gid
//       // navigate to dashboard
//       navigate(urlPaths.root())
//       return;
//     }
//     navigate(urlPaths.root())
//   }, [navigate]);

//   return null;
// };

// function regularLink(add_accounts: String | null, account_id: String | null, express_form: String | null, nfs_submit: String | null): boolean {
//   return add_accounts === null && account_id === null && express_form === null && nfs_submit === null;
// }