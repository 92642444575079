import * as React from "react";

import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { createDocumentAndUpload } from "./createDocumentAndUpload";
import { DocumentsRecord, DocumentsView } from "./DocumentsView";
import { getExpressSectionSourceDataByKey, putExpressSectionDataByKey } from "./express-data";
import { isWillRequiredExpressForm } from "@src/types/Document";
import { ExpressUiInfoContext } from "@src/store/ExpressUiInfo";
import { ServiceProvider } from "@src/types";
import { useScrollToTop } from "@src/hooks/useScrollToTop";

const currentSection = "documents";

export type DocumentsState = {
  readonly record: DocumentsRecord;
  readonly errors: Errors;
  readonly hasChanges: boolean;
};

type Errors = {
  proofOfAddress?: string;
  proofOfDeath?: string;
  willAndProbate?: string;
  grantOfRepresentation?: string;
};

const serverRecordFromRecord = (newIds: DocumentsRecord) => {
  return {
    proofOfAddressIds: (newIds.proofOfAddress || []).map((d) => d.id),
    deathCertificateIds: (newIds.proofOfDeath || []).map((d) => d.id),
    willAndProbateIds: (newIds.willAndProbate || []).map((d) => d.id),
    grantOfRepresentationIds: (newIds.grantOfRepresentation || []).map((d) => d.id),
  };
};

export type DocumentsProps = {
  serviceProvider: {
    customForm?: string;
    deathCertificateNeeded?: boolean;
    grantOfRepresentationNeeded?: boolean;
    proofOfAddressNeeded?: boolean;
    willNeeded?: boolean;
  };
  busy: boolean;
  remoteError: string | undefined;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
  readonly next: () => void;
};

export const Documents: React.FC<DocumentsProps> = ({
  serviceProvider,
  busy,
  remoteError,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
  next,
}) => {
  useScrollToTop();

  const {
    state: { willExists },
  } = React.useContext(ExpressUiInfoContext);
  const [{ record, errors, hasChanges }, setState] = React.useState<DocumentsState>({
    record: {
      proofOfAddress: [],
      proofOfDeath: [],
      willAndProbate: [],
      grantOfRepresentation: [],
    },
    hasChanges: false,
    errors: {},
  });

  const uploaderFor =
    (name: "proofOfAddress" | "proofOfDeath" | "willAndProbate" | "grantOfRepresentation") =>
      (files: ReadonlyArray<File>) => {
        // TODO set busy

        const prefix = {
          proofOfAddress: "proof_of_address",
          proofOfDeath: "proof_of_death",
          willAndProbate: "will_and_probate",
          grantOfRepresentation: "grant_of_representation",
        }[name];

        const tags = {
          proofOfAddress: ["proof_of_address"],
          proofOfDeath: ["proof_of_death"],
          willAndProbate: ["will"],
          grantOfRepresentation: ["grant_of_representation"],
        }[name];

        const uploads = files.map((file, i) => {
          const index = record[name].length + i;
          const suffix = index === 0 ? "" : `_${index}`;
          const filename = `${prefix}${suffix}`;

          return createDocumentAndUpload(file, filename, tags).then(
            ({ id, filename }) => {
              return { id, filename: filename };
            },
            (_error) => null
          );
        });

        Promise.all(uploads).then((uploads) => {
          uploads = uploads.filter((u) => u !== null);
          // TODO: unset busy
          setState((s) => ({
            ...s,
            hasChanges: true,
            record: {
              ...s.record,
              [name]: [...(s.record[name] || []), ...uploads],
            },
          }));
        });
      };

  const removerFor = (
    name: "proofOfAddress" | "proofOfDeath" | "willAndProbate" | "grantOfRepresentation"
  ) => {
    return (id: string) => {
      setState((s) => ({
        ...s,
        hasChanges: true,
        record: {
          ...s.record,
          [name]: s.record[name].filter((d) => d.id !== id),
        },
      }));
    };
  };

  const updateProofOfAddress = React.useCallback(uploaderFor("proofOfAddress"), [
    record.proofOfAddress,
    setState,
  ]);

  const updateProofOfDeath = React.useCallback(uploaderFor("proofOfDeath"), [
    record.proofOfDeath,
    setState,
  ]);

  const updateWillAndProbate = React.useCallback(uploaderFor("willAndProbate"), [
    record.willAndProbate,
    setState,
  ]);

  const updateGrantOfRepresentation = React.useCallback(uploaderFor("grantOfRepresentation"), [
    record.grantOfRepresentation,
    setState,
  ]);

  const removeProofOfAddress = React.useCallback(removerFor("proofOfAddress"), [setState]);
  const removeProofOfDeath = React.useCallback(removerFor("proofOfDeath"), [setState]);
  const removeWillAndProbate = React.useCallback(removerFor("willAndProbate"), [setState]);
  const removeGrantOfRepresentation = React.useCallback(removerFor("grantOfRepresentation"), [
    setState,
  ]);

  const setError = React.useCallback(
    (name: string, message: string) => {
      setState((s) => ({
        ...s,
        hasChanges: true,
        record: { ...s.record, [name]: [] },
        errors: { ...s.errors, [name]: message },
      }));
    },
    [setState]
  );

  React.useEffect(() => {
    const data = getExpressSectionSourceDataByKey(currentSection);

    if (!data) {
      setState(({ record }) => ({ record, hasChanges: false, errors: {} }));
      return;
    }

    setState({ record: data, hasChanges: false, errors: {} });
  }, [setState]);

  const onNext = React.useCallback(() => {
    const errors = errorsOf(record, serviceProvider, willExists);
    if (errors) {
      setState({
        hasChanges,
        record,
        errors,
      });
      putExpressSectionDataByKey(currentSection, { source: record });
      return;
    }

    if (!hasChanges) {
      next();
      return;
    }

    putExpressSectionDataByKey(currentSection, {
      source: record,
      target: serverRecordFromRecord(record),
    });
    next();
  }, [record, serviceProvider, hasChanges, next, willExists]);

  const onSectionClickExtended = React.useCallback(
    (section) => {
      if (hasChanges) {
        const errors = errorsOf(record, serviceProvider, willExists);
        putExpressSectionDataByKey(currentSection, {
          source: record,
          target: errors ? undefined : serverRecordFromRecord(record),
        });
      }

      onSectionClick(section);
    },
    [record, serviceProvider, hasChanges, onSectionClick, willExists]
  );

  return (
    <DocumentsView
      serviceProvider={serviceProvider}
      askForProofOfAddress={serviceProvider.proofOfAddressNeeded}
      askForWillAndProbate={isWillRequiredExpressForm(serviceProvider as ServiceProvider, willExists)}
      askForGrantOfRepresentation={serviceProvider.grantOfRepresentationNeeded}
      busy={busy}
      record={record}
      errors={errors}
      hasChanges={hasChanges}
      remoteError={remoteError}
      setError={setError}
      updateProofOfAddress={updateProofOfAddress}
      removeProofOfAddress={removeProofOfAddress}
      updateWillAndProbate={updateWillAndProbate}
      removeWillAndProbate={removeWillAndProbate}
      updateGrantOfRepresentation={updateGrantOfRepresentation}
      removeGrantOfRepresentation={removeGrantOfRepresentation}
      updateProofOfDeath={updateProofOfDeath}
      removeProofOfDeath={removeProofOfDeath}
      onNext={onNext}
      onSectionClick={onSectionClickExtended}
      updateTemplate={updateTemplate}
      removeRemoteError={removeRemoteError}
    />
  );
};

const errorsOf = (record: DocumentsRecord, serviceProvider: any, collectWill: boolean): Errors | undefined => {
  const errors = {} as Errors;

  if (serviceProvider.deathCertificateNeeded && record?.proofOfDeath.length === 0) {
    errors.proofOfDeath = "required";
  }
  const askForWillAndProbate = isWillRequiredExpressForm(serviceProvider, collectWill);
  // if (
  //   isProofOfAddressNeeded &&
  //   documents?.proofOfAddressIds &&
  //   documents.proofOfAddressIds.length === 0
  // ) {
  //   errors.proofOfAddressIds = "Required field";
  // }

  if (askForWillAndProbate && record?.willAndProbate && record?.willAndProbate.length === 0) {
    errors.willAndProbate = "Required field";
  }

  return Object.keys(errors).length === 0 ? undefined : errors;
};
