import * as React from "react";

import { colors } from "@styles/constants";

export type HRProps = object;

export const HR: React.FC<HRProps> = () => {
  return (
    <hr
      style={{
        borderColor: colors.lighterGray,
        borderStyle: "solid",
        borderWidth: 0,
        borderTopWidth: "1px",
        margin: "0 -15px",
        width: "auto",
      }}
    />
  );
};
