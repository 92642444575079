import * as React from "react";
import { Box, Stack } from "@mui/material";
import { validEmailAddress } from "@utils/Functions";
import { EmailAddressInput } from "@src/components/atoms";
import { Header, Subheader } from "@atoms/Header";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { registerUser } from "@api/signupApi";
import { Button } from "@atoms/Button";
import { RegistrationInfo } from "@utils/Types";
import { ValidationError } from "@utils/Errors";

const recordErrors = (record: RegistrationInfo) => {
  const errors = {
    firstName: !record.firstName ? "required" : undefined,
    lastName: !record.lastName ? "required" : undefined,
    emailAddress: !record.emailAddress
      ? "required"
      : !validEmailAddress(record.emailAddress)
        ? "invalid"
        : undefined,
    registrarLocation: !record.registrarLocation ? "required" : undefined
  };
  return JSON.parse(JSON.stringify(errors));
};

export const RegistrationPage = () => {
  const newRegInfo: RegistrationInfo = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    registrarLocation: ""
  };

  const [state, setState] = React.useState({
    success: false,
    errorMessage: "",
    errors: {},
    record: newRegInfo,
    validated: false,
    busy: false
  });

  const { busy, success, errorMessage, errors, record, validated } = state;

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [name]: undefined
        },
        record: { ...s.record, [name]: value },
        validated: false
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, validated: true, errors, errorMessage: "" }));
        return;
      }
      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));
      // console.log("record", record);
      registerUser(record).then(
        () => {
          // console.log("success");
          setState((s) => ({ ...s, success: true, errors, errorMessage: "" }));
        },
        (error: any) => {
          if (error instanceof ValidationError) {
            setState((s) => ({
              ...s,
              busy: false,
              errors: "server error",
              validated: true
            }));
          } else {
            setState((s) => ({ ...s, busy: false, errorMessage: "server error" }));
          }
        }
      );
    },
    [record, setState]
  );

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  return (
    <>
      <RegistrationPageView
        success={success}
        busy={busy}
        validated={validated}
        record={record}
        errors={errors}
        errorMessage={errorMessage}
        onSubmit={onSubmit}
        onChange={onChange}
        removeErrorMessage={removeErrorMessage}
      />
    </>
  );
};

export const RegistrationPageView = (data: any) => {
  const { success, errorMessage, removeErrorMessage } = data;
  return (
    <SideImageTemplate
      headerType="help"
      errorMessage={errorMessage}
      removeErrorMessage={removeErrorMessage}
      image={
        <img
          src="/images/stock/signup.png"
          width="543"
          alt="Family"
          style={{ paddingTop: "100px", width: "100%" }}
        />
      }
      sx={{ background: `url(/images/background/register.svg) no-repeat center #d1dee0` }}
    >
      <Stack rowGap={2}>
        <Box>
          <Header> Settld Registrar Referral - for use in appointments</Header>
          <Subheader>
            <ul>
              <li>
                <span>
                  <strong>
                    <a href="https://www.settld.care/" rel="noopener noreferrer" target="_blank">
                      Settld
                    </a>{" "}
                    is a free service
                  </strong>{" "}
                  which requests the cancellation or transfer of all non-government accounts when
                  someone dies (from banks to social media providers).{" "}
                </span>
              </li>
              <li>
                <span>
                  Rather than having to contact each company separately, people can use Settld's{" "}
                  <b>secure online form, to do it in one go. </b>
                </span>
              </li>
              <li>
                <span>
                  This <strong>saves most people around </strong>
                  <b>20 hours</b> of&nbsp;admin effort.
                </span>
                <br />
                &nbsp;
              </li>
            </ul>
          </Subheader>
        </Box>
        {!success && <RegistrarForm data={data} />}
        {success && <SuccessPage />}
      </Stack>
    </SideImageTemplate>
  );
};

const SuccessPage = () => {
  return (
    <>
      <h3>Confirmed: an email will now be sent to the notifier with further details.</h3>
    </>
  );
};

const RegistrarForm = ({ data }: any) => {
  const { onSubmit, record, validated, onChange, errors, busy } = data;
  return (
    <form noValidate onSubmit={onSubmit}>
      <FormStack> <FormField halfWidth label="First Name"> <TextInput
        name="firstName"
        value={record.firstName || ""}
        error={!validated ? null : errors.firstName}
        onChange={onChange}
      /> </FormField>

        <FormField halfWidth label="Last Name"> <TextInput
          name="lastName"
          value={record.lastName || ""}
          error={!validated ? null : errors.lastName}
          onChange={onChange}
        /> </FormField>

        <FormField halfWidth label="Email Address"> <EmailAddressInput
          name="emailAddress"
          value={record.emailAddress || ""}
          error={!validated ? null : errors.emailAddress}
          onChange={onChange}
        /> </FormField>

        <FormField halfWidth label="Registrar office location"> <TextInput
          name="registrarLocation"
          value={record.registrarLocation || ""}
          error={!validated ? null : errors.registrarLocation}
          onChange={onChange}
        /> </FormField> </FormStack>

      <br></br> <FormStack> <FormField slightlyMoreThanHalfWidth> <Button
      submit
      variant="primary"
      size="large"
      disabled={busy}
      fullWidth={true}> Register </Button> </FormField> </FormStack>
    </form>
  );
};
