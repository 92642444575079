import * as React from "react";
import { useNavigate } from "react-router-dom";
import { NonExpressFormData } from "@src/types";
import urlPaths from "../../../urlPaths";
import { Section } from "@src/Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { KYCDone } from "./KYCDone";
import { KYCView } from "./KYCView";

export type KYCProps = {
  form: NonExpressFormData;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
};

export const KYC: React.FunctionComponent<KYCProps> = ({
  continueWithoutChanges,
  form,
  onSectionClick,
  updateTemplate,
}) => {
  const navigate = useNavigate();
  const onContinue = React.useCallback(() => {
    continueWithoutChanges(Section.KYC);
  }, [continueWithoutChanges]);

  const goToKYC = React.useCallback(() => {
    navigate(urlPaths.kyc(), { replace: true });
  }, [navigate]);

  if (form.kycPending) {
    return (
      <KYCView
        onContinue={goToKYC}
        onSectionClick={onSectionClick}
        updateTemplate={updateTemplate}
      />
    );
  }

  return (
    <KYCDone
      onContinue={onContinue}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
    />
  );
};

export const isComplete = (form: NonExpressFormData): boolean => {
  return !form.kycPending;
};
