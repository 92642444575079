import * as React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { setLocalSession } from "@utils/session";
import urlPaths from "../urlPaths";
import { EditAccountPage } from "./Dashboard/EditAccountPage";
import { NewAccountPage } from "./Dashboard/NewAccountPage";

export const AdminEntrypoint = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [{ caseId, signature }, setState] = React.useState({ caseId: null, signature: null } as {
    caseId: string | null;
    signature: string | null;
  });

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const caseId = query.get("case_id") || null;
    const signature = query.get("sig") || null;

    if (!caseId || !signature) {
      navigate(`/`, { replace: true });
      return;
    }

    setLocalSession({ caseId, signature });

    setState({ caseId, signature });
  }, []);

  if (!caseId || !signature) {
    return null;
  }

  return (
    <Routes>
      <Route path="accounts">
        <Route
          path="new"
          element={<NewAccountPage caseId={caseId} signature={signature} userIsAdmin={true} />}
        />
        <Route
          path=":accountId"
          element={<EditAccountPage caseId={caseId} signature={signature} userIsAdmin={true} />}
        />
      </Route>

      <Route path="dashboard" element={<Navigate to={urlPaths.status()} replace />} />
      <Route path="form" element={<Navigate to={urlPaths.form()} replace />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};
