import * as React from "react";

import { cleanedErrors, isNWG } from "@utils/Functions";
import { RadioGroupInput } from "@atoms/RadioGroupInput";
import { DateInput } from "@atoms/DateInput";
import { AccountRecordPropertySpec } from "@api/online-api";
import { InfoBoxTrigger } from "../../../../components/molecules/InfoBoxTrigger";
import { OwnershipStatus, Property } from "@customTypes/property";
import { useOnChange, useOnValueChange } from "@utils/callbacks";
import { FormField } from "../../../../components/molecules/FormField";
import { TextInput } from "@atoms/TextInput";
import { FormStack } from "../../../../components/molecules/FormStack";
import { Typography } from "@mui/material";

export type Record = {
  readonly ownershipStatus?: OwnershipStatus;
  readonly landlordType?: string;
  readonly landlordName?: string;
  readonly landlordAddress?: string;
  readonly landlordPhoneNumber?: string;
  readonly landlordEmailAddress?: string;
  readonly tenancyEndDate?: string;
};

export type Errors =
  | undefined
  | {
    ownershipStatus?: string;
    tenancyEndDate?: string;
  };

export const errorsOf: (r?: Record) => Errors = (r) => {
  let tenancyEndDateErrors: string | undefined = undefined;
  if (r?.ownershipStatus == OwnershipStatus.Rented) {
    tenancyEndDateErrors = r?.tenancyEndDate ? undefined : "required";
  }

  return cleanedErrors({
    ownershipStatus: r?.ownershipStatus ? undefined : "required",
    tenancyEndDate: tenancyEndDateErrors,
  });
};

export const propertyFieldsFromRecord = (record?: Record): Partial<AccountRecordPropertySpec> => {
  if (!record) {
    return {};
  }

  return {
    ownershipStatus: record.ownershipStatus,
    landlordType: record.landlordType,
    landlordName: record.landlordName,
    landlordAddress: record.landlordAddress,
    landlordPhoneNumber: record.landlordPhoneNumber,
    landlordEmailAddress: record.landlordEmailAddress,
    tenancyEndDate: record.tenancyEndDate,
  };
};

export const infoAvailable = (property: Property) => {
  return property.ownershipStatus && true;
};

export const OwnershipStatusField: React.FunctionComponent<{
  readonly record: Record;
  readonly update: (f: (record: Record) => Record) => void;
  readonly errors: Errors;
  readonly serviceProviderId?: string;
}> = ({ record, update, errors, serviceProviderId }) => {
  const onOwnershipStatusValueChange = useOnValueChange(update, "ownershipStatus");

  const onLandlordTypeValueChange = useOnValueChange(update, "landlordType");
  const onChange = useOnChange(update);
  const onTenancyEndDateChange = useOnValueChange(update, "tenancyEndDate");

  return (
    <FormStack substack spacing={0}>
      <FormField
        label={
          <span>
            What is the ownership status of the property?{" "}
            {isNWG(serviceProviderId) && <InfoBoxTrigger content={renderInfoBox()} />}
          </span>
        }
      >
        <RadioGroupInput
          value={record.ownershipStatus}
          onValueChange={onOwnershipStatusValueChange}
          error={errors?.ownershipStatus}
          fields={ownershipStatusOptions}
        />
      </FormField>

      {record.ownershipStatus === OwnershipStatus.Rented && (
        <FormStack substack indented>
          <FormField
            twoThirdsWidthByItself
            label="If you are unsure use the date of the end of the current tenancy agreement"
          >
            <Typography variant="body1"> When is the tenancy due to end?</Typography>
            <DateInput
              value={record.tenancyEndDate}
              onValueChange={onTenancyEndDateChange}
              error={errors?.tenancyEndDate}
              pastOnly={false}
            />
          </FormField>
          <FormField
            twoThirdsWidthByItself
            label="Please provide further details of the rental arrangement"
          >
            <RadioGroupInput
              value={record.landlordType}
              onValueChange={onLandlordTypeValueChange}
              fields={landlordTypeOptions}
            />
          </FormField>

          <FormField>
            <Typography variant="body1">
              Please provide their contact information, if available.
            </Typography>
          </FormField>

          <FormField twoThirdsWidthByItself label="Name">
            <TextInput name="landlordName" value={record.landlordName || ""} onChange={onChange} />
          </FormField>

          <FormField twoThirdsWidthByItself label="Address">
            <TextInput
              name="landlordAddress"
              value={record.landlordAddress || ""}
              onChange={onChange}
            />
          </FormField>

          <FormField twoThirdsWidthByItself label="Phone number">
            <TextInput
              name="landlordPhoneNumber"
              value={record.landlordPhoneNumber || ""}
              onChange={onChange}
            />
          </FormField>
        </FormStack>
      )}
    </FormStack>
  );
};

const renderInfoBox = () => {
  return (
    <div>
      <p>
        <b>Why do I need to provide this information?</b>
      </p>

      <p>
        Please note we need to understand the ownership status to help the supplier determine the
        length of the grace period, during which the supply to the property is left on and available
        for use whilst clearing, cleaning and sorting the premises.
      </p>

      <p style={{ marginBottom: 0 }}>
        A 6-month grace period is provided if the property was owned by the person who died. A
        30-day grace period is provided if the property was rented.
      </p>
    </div>
  );
};

const ownershipStatusOptions = [
  { value: "owned", label: "Owned" },
  { value: "rented", label: "Rented" },
  {
    value: "unknown",
    label:
      "Don't know (Please note that this information will still be required further down the line)",
  },
];

const landlordTypeOptions = [
  { value: "private", label: "Private landlord" },
  { value: "letting_agent", label: "Letting agent" },
  {
    value: "local_authority",
    label: "Local Authority/Housing Association",
  },
];

export const readableLandlordType = (landlordType?: string) => {
  if (!landlordType) {
    return "";
  }

  switch (landlordType) {
    case "private":
      return "Private landlord";
    case "letting_agent":
      return "Letting agent";
    case "local_authority":
      return "Local Authority/Housing Association";
    default:
      return "";
  }
};
