import * as React from "react";
import { BooleanInput } from "../atoms/BooleanInput";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "../atoms/Button";

export interface ProbeUnknownProps {
  readonly checked: boolean;
  readonly label: any;
  readonly update: any;
  defaultValue: any;
  emptyValue?: any;
  title?: string;
  content?: string;
}

export function ProbeUnknown({
  checked,
  label,
  update,
  defaultValue,
  emptyValue,
  title,
  content,
}: ProbeUnknownProps) {
  const [showDialog, setShowDialog] = React.useState(false);
  const closeDialog = () => setShowDialog(false);

  const updateUnknown = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        update((s: any) => ({ ...s, ...defaultValue }));
        setShowDialog(true);
      } else {
        update((s: any) => ({ ...s, ...emptyValue }));
      }
    },
    [update, defaultValue, emptyValue]
  );

  // " Please note that if you select unknown there may be a delay in notification for this account. You may still be asked to provide this information at a later date if you do not provide it now.";
  title = title || "Information";
  content =
    content ||
    "Please note that you will need this information for the company to act on your request. If you do not have the information to hand now, please submit the form without this account, and add it via your dashboard at a later date.";

  return (
    <>
      <BooleanInput value={checked} onValueChange={updateUnknown} label={label} />

      <Dialog open={showDialog} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ textAlign: "justify" }}>
          <p>{content}</p>
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "40px",
              alignItems: "baseline",
            }}
          >
            <div>
              <Button variant="primary" size="large" onClick={closeDialog}>
                <span style={{ textTransform: "uppercase" }}>Close</span>
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
