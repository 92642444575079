import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { localSession, setLocalCaseId } from "@utils/session";
import urlPaths from "../urlPaths";
import { LoadingPage } from "./LoadingPage";
import { checkFormStatus, checkOnlineSession } from "@api/sessionApi";

export const RootPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const account_id = searchParams.get("account_id");
  const add_accounts_focus = searchParams.get("add_accounts") === "true";
  let url: string = urlPaths.status()

  if (account_id) {
    url = url + "?account_id=" + account_id;
  } else if (add_accounts_focus) {
    url = url + "?add_accounts=" + "true";
  }

  const moveAuthenticatedUserToNextPage = React.useCallback(
    (formStatus) => {
      let nextPage;

      if (formStatus?.source === "express_form" || formStatus?.formSubmittedAt) {
        nextPage = url;
      } else if (formStatus?.notifierFormState.permission) {
        nextPage = urlPaths.form();
      } else if (formStatus?.notifierFormState.requirements) {
        nextPage = urlPaths.permission();
      } else {
        nextPage = urlPaths.onBoardingAdditionalServices();
      }

      navigate(nextPage, { replace: true });
    },
    [navigate, url,account_id,add_accounts_focus]
  );

  React.useEffect(() => {
    const session = localSession();
    if (session) {
      checkFormStatus(session).then(
        (formStatus) => {
          moveAuthenticatedUserToNextPage(formStatus);
        },
        () => {
          // TODO: do not ignore error
          navigate(urlPaths.signup(), { replace: true });
        }
      );

      return;
    }

    checkOnlineSession().then(({ session, formStatus }: any) => {
      if (!session) {
        navigate(urlPaths.signup(), { replace: true });
        return;
      }

      setLocalCaseId(session.caseId);

      moveAuthenticatedUserToNextPage(formStatus);
    });
  }, [moveAuthenticatedUserToNextPage, navigate]);

  return <LoadingPage />;
};
