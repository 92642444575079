// Need to create a new modal since design is new
// This modal is used to show information based on account management status

import { AccountManagement } from "@src/types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Link, Typography } from "@mui/material";
import React, { ReactElement } from "react";


interface IAccountManagementInfoModal {
  // Indicates whether the dialog is visible
  isDialogVisible: boolean;
  // Function to toggle the visibility of the dialog
  setDialogVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  // The account management status, can be NotifyOnly, NotManaged, or undefined
  accountManagement: AccountManagement | null | undefined;
}


const AccountManagementInfoModal: React.FC<IAccountManagementInfoModal> = ({
  isDialogVisible,
  setDialogVisibility,
  accountManagement
}) => {
  let heading: string;
  let body: ReactElement;

  // Determine the content based on the account management status
  if (accountManagement === AccountManagement.NotifyOnly) {
    // If the account is set to NotifyOnly
    heading = "Account Notification Only";
    body = <>
      The service provider selected does not support digital notification through Settld.<br /><br />

      As a result we will only instruct the provider to close or transfer the account on your behalf. They will be in
      touch with you with next steps by email, call, or post.<br />
    </>;

  } else {
    // If the account is not managed and rest except notify only
    heading = "Account Not Managed";
    body =
      <>
        The service provider currently does not accept notification from Settld.<br /><br />

        Contact details for their bereavement team will be provided in the dashboard, so you don’t have to search
        yourself.<br /><br />

        If you’d like to add details for the account, they’ll be available to you in the dashboard. However, this
        information will not be transmitted or shared.
      </>;
  }

  return (
    <Dialog
      open={isDialogVisible}
      onClose={() => setDialogVisibility(false)} // Close the dialog when the user clicks outside or presses Escape
    >
      <DialogTitle>
        <u>
          {heading}
        </u>
      </DialogTitle>

      <DialogContent>
        <Typography className="mb-4">
          {body}
        </Typography>
        <br></br>
        <Typography>
          Please support our request that this provider works with Settld{"   "}
          <Link
            href="https://acsurvey.qualtrics.com/jfe/form/SV_2tzCz04LHr8l3Ho" // Link to the survey
            target="_blank" // Open the link in a new tab
            rel="noreferrer" // Prevents the browser from sending the current page's address
          >
            here
          </Link>
          .
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => setDialogVisibility(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountManagementInfoModal;
