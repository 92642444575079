import * as React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";
import { AcceptedFileTypes, MAX_FILE_SIZE } from "@src/types/Document";
interface BasicDropzoneProps {
  readonly files?: ReadonlyArray<File> | File;
  readonly multiple?: boolean;
  readonly isUploading?: boolean;
  readonly errors?: ReadonlyArray<string>;
  readonly onDrop: (f: File[]) => void;
  readonly onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
}

export const BasicDropzone: React.FC<BasicDropzoneProps> = ({
  files,
  multiple,
  isUploading,
  errors,
  onDrop,
  onDropRejected,
}) => {
  // expression resolves undefined/not array to array or []
  const acceptedFiles = React.useMemo(
    () => (!files ? [] : !Array.isArray(files) ? [files] : files),
    [files]
  );

  const onFileDrop = React.useCallback(
    (newFiles) => {
      if (!multiple) {
        onDrop(newFiles);
        return;
      }
      const existingFileNames = acceptedFiles.map((f) => f.name);
      newFiles = Array.isArray(newFiles) ? newFiles : [newFiles];
      newFiles = newFiles.filter((file: any) => file && !existingFileNames.includes(file.name));
      onDrop(acceptedFiles.concat(newFiles));
    },
    [acceptedFiles, multiple, onDrop]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: AcceptedFileTypes,
    maxSize: MAX_FILE_SIZE,
    onDrop: onFileDrop,
    onDropRejected,
    multiple,
  });

  const zoneClassName = [
    "BasicDropzone-zone",
    // HACK to always show darker chars
    isDragAccept || acceptedFiles.length > 0 ? "accept" : "accept",
    isDragReject || (errors && errors.length > 0) ? "reject" : "",
    isUploading ? "isUploading" : "",
  ].join(" ");

  return (
    <div className="BasicDropzone">
      <div className={zoneClassName} {...getRootProps()}>
        <input {...getInputProps()} disabled={isUploading} />
        <Message
          acceptedFiles={acceptedFiles}
          errors={errors}
          isUploading={!!isUploading}
          multiple={!!multiple}
        />
      </div>
    </div>
  );
};

const Message = ({
  acceptedFiles,
  errors,
  isUploading,
  multiple,
}: {
  acceptedFiles: unknown[];
  isUploading: boolean;
  errors?: ReadonlyArray<string>;
  multiple: boolean;
}) => {
  if (isUploading) {
    return (
      <>
        <CircularProgress color="primary" />
        <p>Uploading...</p>
      </>
    );
  }

  if (multiple || acceptedFiles.length === 0 || errors) {
    return (
      <>
        <p>Drag and drop a document, or click to upload.</p>
        <em>(*.pdf, *.jpeg, *.png)</em>
        {errors && (
          <>
            {errors.map((e, idx) => (
              <p key={idx}>{e.split("\n")}</p>
            ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <p>Drop another document to replace or click here.</p>
      <em>(*.pdf, *.jpeg, *.png)</em>
      {errors && (
        <>
          {/* @ts-ignore */}
          {errors.map((e, idx) => (
            <p key={idx}>{e.split("\n")}</p>
          ))}
        </>
      )}
    </>
  );
};
