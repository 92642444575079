import * as React from "react";
import { useNavigate } from "react-router-dom";
import urlPaths from "../../urlPaths";

import { KYCTemplate } from "@templates/KYCTemplate";
import { createChecks, getApplicantAndToken, getChecks, getReports } from "./kyc-api";
import { KYC, KYCApi } from "../KYCPage/KYC";
import {
  getExpressData,
  getExpressSectionDataByKey,
  getExpressSectionTargetDataByKey,
  putExpressSectionDataByKey,
} from "./express-data";
import { useScrollToTop } from "@src/hooks/useScrollToTop";

export const ExpressKYCPage: React.FC = () => {
  const navigate = useNavigate();
  useScrollToTop();

  const [busy, setBusy] = React.useState(false);

  const onDone = React.useCallback(() => {
    const data = getExpressData();
    if (!data?.slug) {
      navigate("/", { replace: true });
      return;
    }

    if (data.sections?.kyc?.target) {
      navigate(urlPaths.expressForm(data.slug, "submit"), { replace: true });
      return;
    }

    navigate(urlPaths.expressForm(data.slug, "kyc"), { replace: true });
  }, [navigate]);

  const api: KYCApi = React.useMemo(() => {
    return {
      getApplicantAndToken: async () => {
        const notifier = getExpressSectionTargetDataByKey("notifier");
        const { firstName, lastName } = notifier;

        const data = await getApplicantAndToken({ firstName, lastName });
        if (data.data) {
          const previous = getExpressSectionDataByKey("kyc");
          putExpressSectionDataByKey("kyc", {
            ...(previous || {}),
            source: {
              ...(previous?.source || {}),
              applicantId: data.data.applicantId,
            },
          });
        }
        return data;
      },
      createChecks: ({ applicantId, variant }) =>
        createChecks({ applicantId, variant }).then((data) => {
          if (data.data) {
            const previous = getExpressSectionDataByKey("kyc");
            putExpressSectionDataByKey("kyc", {
              ...(previous || {}),
              source: {
                ...(previous?.source || {}),
                checkId: data.data.checkId,
              },
            });
          }
          return data;
        }),
      getChecks: ({ checkId }) =>
        getChecks({ checkId }).then((data) => {
          if (data.data) {
            const previous = getExpressSectionDataByKey("kyc");
            putExpressSectionDataByKey("kyc", {
              ...(previous || {}),
              source: {
                ...(previous?.source || {}),
                reportIds: data.data.reportIds,
              },
            });
          }
          return data;
        }),
      getReports: ({ reportIds }) => getReports({ reportIds }),
      downloadCheck: () => {
        const previous = getExpressSectionDataByKey("kyc");
        putExpressSectionDataByKey("kyc", {
          ...(previous || {}),
          target: previous?.source,
        });
        return Promise.resolve({ error: undefined, data: {} });
      },
    };
  }, []);

  return (
    <KYCTemplate busy={busy}>
      <KYC api={api} setBusy={setBusy} onDone={onDone} />
    </KYCTemplate>
  );
};
