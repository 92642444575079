import * as React from "react";
import { Route, Routes, useMatch, useNavigate, useParams } from "react-router-dom";

import { ServiceProviderType } from "@customTypes/index";
import { NotFoundPage } from "../Error/NotFoundPage";
import { LoadingPage } from "../LoadingPage";

import {
  getExpressData,
  getExpressSectionsData,
  getExpressSectionTargetDataByKey,
} from "./express-data";

import { PasswordSetupPage } from "./PasswordSetupPage";
import { ExpressFormSubmittedPage } from "./ExpressFormSubmittedPage";

import { SPContext } from "@src/store/SPProvider";
import { ServiceProvider } from "@src/types/ServiceProvider";
import { useServiceProvider } from "@src/hooks/useServiceProvider";
import {
  GetAccountsComponent,
  GetNotifierComponent,
  GetExecutorComponent,
  GetNokComponent,
  GetDocumentsComponent,
  GetKYCComponent,
  GetSubmitComponent,
  GetAdditionalServicesComponent,
  NotSubmittedPage,
  ExpressComponentProps,
} from "./ExpressFormComponents";
import { ExpressUiInfoContext } from "@src/store/ExpressUiInfo";
export const ExpressFormPage: React.FC = () => {
  const { slug } = useParams() as { slug: string };

  const { loading, error, serviceProvider } = useServiceProvider(slug);

  if (loading) return <LoadingPage />;
  if (error) return <NotFoundPage />;
  if (!serviceProvider) return <NotFoundPage />;
  return <Router serviceProvider={serviceProvider} />;
};

type RouterProps = {
  serviceProvider: ServiceProvider;
};

const Router: React.FC<RouterProps> = ({ serviceProvider }) => {
  const match = useMatch("/express/:slug/*");
  if (!match) throw new Error("Unexpected");

  const navigate = useNavigate();
  const {
    state: { collectNokDetails, collectExecutorDetails, willExists },
  } = React.useContext(ExpressUiInfoContext);
  const [count, setCount] = React.useState(0);

  const refresh = React.useCallback(() => {
    setCount((c) => c + 1);
  }, [setCount]);

  const submitted = React.useMemo(() => {
    const data = getExpressData();
    return data && data.submitted;
  }, []);
  const isCollectDocuments: boolean = collectDocuments(serviceProvider, willExists);
  const isKyc: boolean = !!serviceProvider.idDocumentNeeded && !!getExpressSectionTargetDataByKey("notifier");

  const formMenuEntries = React.useMemo(() => {
    const data = getExpressSectionsData();
    const entries = expressFormMenuEntries(
      serviceProvider.serviceProviderType,
      collectNokDetails,
      collectExecutorDetails,
      isCollectDocuments,
      isKyc
    );

    if (!data) return entries;

    return entries.map(({ key, label }) => {
      if (!data[key]) {
        return { key, label };
      }
      const done = !!data[key].target;
      const error = data[key].source && !done;

      return { key, label, done, error };
    });
  }, [serviceProvider, collectNokDetails, collectExecutorDetails, count, isCollectDocuments, isKyc,willExists]);

  const onSectionClick = React.useCallback(
    (section) => {
      refresh();
      navigate(`${match.pathnameBase}/${section}`);
    },
    [match.pathnameBase, navigate, refresh]
  );

  const expressComponentProps: ExpressComponentProps = {
    serviceProvider, formMenuEntries,
    match, refresh, submitted, navigate, onSectionClick,
    collectExecutorDetails, collectNokDetails,
    willExists, isCollectDocuments, isKyc
  };

  const { dispatch } = React.useContext(SPContext);
  React.useEffect(() => {
    dispatch({ type: "SET_SP", payload: serviceProvider });
  }, [dispatch, serviceProvider]);

  return (
    <Routes>
      <Route path="account" element={GetAccountsComponent(expressComponentProps)} />
      <Route path="notifier" element={GetNotifierComponent(expressComponentProps)} />

      <Route path="executor" element={GetExecutorComponent(expressComponentProps)} />
      <Route path="nok" element={GetNokComponent(expressComponentProps)} />

      <Route path="documents" element={GetDocumentsComponent(expressComponentProps)} />
      <Route path="kyc" element={GetKYCComponent(expressComponentProps)} />
      <Route path="submit" element={GetSubmitComponent(expressComponentProps)} />

      <Route
        path="additional-services"
        element={GetAdditionalServicesComponent(expressComponentProps)}
      />
      <Route
        path="submitted"
        element={
          submitted ? (
            <ExpressFormSubmittedPage serviceProvider={serviceProvider} />
          ) : (
            NotSubmittedPage({ match })
          )
        }
      />

      <Route path="password" element={<PasswordSetupPage nextPageURL="/form" />} />

      <Route path="/" element={<Root />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const Root = () => {
  const match = useMatch("/express/:slug/*");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (match) {
      navigate(`${match.pathnameBase}/account`);
    }
  }, [match, navigate]);

  return null;
};

export const expressFormMenuEntries = (
  serviceProviderType: ServiceProviderType,
  collectNokInfo: boolean,
  collectExecutorDetails: boolean,
  isCollectDocuments: boolean,
  isKyc: boolean
) => {
  return [
    serviceProviderType === ServiceProviderType.Insurance
      ? { key: "account", label: "Policy details" }
      : { key: "account", label: "Account details" },
    { key: "notifier", label: "Your details" },
    ...(collectNokInfo ? [{ key: "nok", label: "Next of kin Details" }] : []),
    ...(collectExecutorDetails ? [{ key: "executor", label: "Executor Details" }] : []),
    ...(isCollectDocuments ? [{ key: "documents", label: "Documents" }] : []),
    ...(isKyc ? [{ key: "kyc", label: "Id verification" }] : []),
    { key: "submit", label: "Submit" },
  ];
}

// ### Truth Table

// | collectWill | sp.willNeeded | Result (res) |
// |-------------|----------------|--------------|
// | true        | true           | true         |
// | true        | false          | true         |
// | false       | true           | false        |
// | false       | false          | false        |
function collectDocuments(sp: ServiceProvider, collectWill: boolean) {
  const doWeNeedWill = (collectWill && sp.willNeeded) || (collectWill && !sp.willNeeded);

  return sp.deathCertificateNeeded || sp.proofOfAddressNeeded || sp.grantOfRepresentationNeeded || doWeNeedWill;

}