import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { requestSignIn } from "@api/sessionApi";
import { login } from "@utils/session";
import urlPaths from "../../urlPaths";
import { BusyOverlay } from "../../components/atoms/BusyOverlay";
import { Button } from "../../components/atoms/Button";
import { Header, Subheader } from "../../components/atoms/Header";
import { EmailAddressInput } from "../../components/atoms/EmailAddressInput";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { CentredTemplate } from "@templates/CentredTemplate";
import { Link } from "../../components/atoms/Link";

type State = {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly record: Record;
  readonly validated: boolean;
  readonly errors: FieldsErrors;
};

type Record = {
  readonly emailAddress: string;
  readonly password: string;
};

interface FieldsErrors {
  readonly emailAddress?: string;
  readonly password?: string;
}

export type SigninPageProps = {
  nextPageURL: string;
};

export const SigninPage: React.FC<SigninPageProps> = ({ nextPageURL }) => {
  

  const [state, setState] = React.useState({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      emailAddress: "",
      password: "",
    },
  } as State);

  const { busy, errorMessage, errors, record } = state;

  const navigate = useNavigate();

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        record: { ...s.record, [name]: value },
        errors: {
          ...s.errors,
          [name]: "",
        },
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none" };
      // // @ts-ignore
      // window.googleReCAPTCHA.ready(() => {
      //   // @ts-ignore
      //   window.googleReCAPTCHA.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
      //     submitData({ ...record, captcha: token });
      //   });
      // });

      requestSignIn(data).then(
        (data) => {
          setState((s) => ({ ...s, busy: false }));

          const { session } = data;
          login(session.token);
          navigate(nextPageURL, { replace: true });
        },
        (error) => {
          setState((s) => ({ ...s, busy: false, errorMessage: error.message }));
        }
      );
    },
    [record, navigate, nextPageURL, setState]
  );

  const onForgotPasswordClick = React.useCallback(() => {
    navigate(urlPaths.forgotPassword());
  }, [navigate]);

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  return (
    <SigninPageView
      record={record}
      errors={errors}
      errorMessage={errorMessage}
      removeErrorMessage={removeErrorMessage}
      busy={busy}
      onSubmit={onSubmit}
      onChange={onChange}
      onForgotPasswordClick={onForgotPasswordClick}
    />
  );
};

export type SigninPageViewProps = {
  record: any;
  errors: any;
  errorMessage: any;
  removeErrorMessage: () => void;
  busy: any;
  onSubmit: (ev: any) => void;
  onChange: any;
  onForgotPasswordClick: () => void;
};

export const SigninPageView: React.FC<SigninPageViewProps> = ({
  record,
  errors,
  errorMessage,
  removeErrorMessage,
  busy,
  onSubmit,
  onChange,
  onForgotPasswordClick,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <CentredTemplate
        sx={{ background: `url(/images/background/signin.svg) no-repeat center #fcfcfc` }}
        headerType="signup"
        errorMessage={errorMessage}
        removeErrorMessage={removeErrorMessage}
      >
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <Stack alignItems="center">
            <Stack rowGap={4} alignItems="center" maxWidth="400px">
              <Box sx={{ textAlign: "center" }}>
                <Header>Welcome back</Header>
                <Subheader>Simplifying end-of-life admin for all.</Subheader>
              </Box>

              <FormStack>
                <FormField label="Email">
                  <EmailAddressInput
                    name="emailAddress"
                    value={record.emailAddress || ""}
                    error={errors.emailAddress}
                    onChange={onChange}
                  />
                </FormField>

                <FormField label="Password">
                  <PasswordInput
                    name="password"
                    value={record.password || ""}
                    error={errors.password}
                    onChange={onChange}
                  />

                  <Box>
                    <Link inline onClick={onForgotPasswordClick}>
                      Forgot password?
                    </Link>
                  </Box>
                </FormField>
              </FormStack>

              <FormStack>
                <FormField>
                  <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                    Log in to Settld
                  </Button>
                </FormField>
              </FormStack>
            </Stack>
          </Stack>
        </form>
      </CentredTemplate>
    </BusyOverlay>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    emailAddress: !record.emailAddress ? "required" : undefined,
    password: !record.password ? "required" : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};
