import { assertUnreachable } from "@utils/Functions";


export enum Relationship {
  Spouse = "spouse",
  CivilPartner = "civilpartner",
  Partner = "partner",
  Child = "child",
  Grandchild = "grand_child",
  Parent = "parent",
  Grandparent = "grandparent",
  GreatGrandparent = "great_grandparent",
  Sibling = "sibling",
  NephewOrNiece = "nephew_or_niece",
  GreatNephewOrGreatNiece = "great_nephew_or_great_niece",
  UncleOrAunt = "uncle_or_aunt",
  Stepchild = "stepchild",
  Stepmother = "stepmother",
  Stepfather = "stepfather",
  BrotherInLaw = "brother_in_law",
  SisterInLaw = "sister_in_law",
  FatherInLaw = "father_in_law",
  MotherInLaw = "mother_in_law",
  SonInLaw = "son_in_law",
  DaughterInLaw = "daughter_in_law",
  Cousin = "cousin",
  // LegalRepresentative = "legal_representative",
  Other = "other",

  // removed
  RelatedByMarriage = "related_by_marriage",
}


export enum Titles {
  Mr = "mr",
  Mrs = "mrs",
  Miss = "miss",
  Ms = "ms",
  Dr = "dr",
  Prof = "prof",
  Rev = "rev",
  Sir = "sir",
  Lady = "lady",
  Lord = "lord",
}


export const prettyTitle = (t: Titles | undefined) => {
  return !t
    ? ""
    : {
      [Titles.Mr]: "Mr.",
      [Titles.Mrs]: "Mrs.",
      [Titles.Miss]: "Miss",
      [Titles.Ms]: "Ms.",
      [Titles.Dr]: "Dr.",
      [Titles.Prof]: "Prof.",
      [Titles.Rev]: "Rev.",
      [Titles.Sir]: "Sir",
      [Titles.Lady]: "Lady",
      [Titles.Lord]: "Lord"
    }[t];
};


export enum ContactMethods {
  Email = "EMAIL",
  Post = "POST",
  Phone = "PHONE",
  Either = "EITHER",
}


export const readableRelationship = (
  r?: Relationship,
  fallback?: string
): string => {
  switch (r) {
    case Relationship.Spouse:
      return "Spouse (Husband or Wife)";
    case Relationship.CivilPartner:
      return "Civil Partner";
    case Relationship.Partner:
      return "Partner";
    case Relationship.Child:
      return "Child";
    case Relationship.Grandchild:
      return "Grandchild";
    case Relationship.Parent:
      return "Parent";
    case Relationship.Grandparent:
      return "Grandparent";
    case Relationship.GreatGrandparent:
      return "Great-Grandparent";
    case Relationship.Sibling:
      return "Sibling";
    case Relationship.NephewOrNiece:
      return "Nephew or Niece";
    case Relationship.GreatNephewOrGreatNiece:
      return "Great-nephew or Great-niece";
    case Relationship.UncleOrAunt:
      return "Uncle or Aunt";
    case Relationship.Stepchild:
      return "Stepchild";
    case Relationship.Stepmother:
      return "Stepmother";
    case Relationship.Stepfather:
      return "Stepfather";
    case Relationship.BrotherInLaw:
      return "Brother-in-law";
    case Relationship.SisterInLaw:
      return "Sister-in-law";
    case Relationship.FatherInLaw:
      return "Father-in-law";
    case Relationship.MotherInLaw:
      return "Mother-in-law";
    case Relationship.SonInLaw:
      return "Son-in-law";
    case Relationship.DaughterInLaw:
      return "Daughter-in-law";
    case Relationship.Cousin:
      return "Cousin";
    // case Relationship.LegalRepresentative:
    //   return "Solicitor/Legal Representative";
    case Relationship.Other:
      return "Other";

    // removed
    case Relationship.RelatedByMarriage:
      return "Related By Marriage (in-law)";
    case undefined:
      return fallback || "Unknown";
    case null:
      return fallback || "Unknown";
  }
  assertUnreachable(r);
};


export enum NotifierRoles {
  SoleExecutor = "sole_executor",
  Executor = "executor",
  Administrator = "administrator",
  DelegatedNotifier = "delegated_notifier",
  Solicitor = "solicitor",
}


export const NotifierRolesToUI: { [key in NotifierRoles]: string } = {
  [NotifierRoles.SoleExecutor]: "The Sole Executor named on the will",
  [NotifierRoles.Executor]: "An Executor named on the will, alongside others",
  [NotifierRoles.Administrator]: "An Administrator (there is no will)",
  [NotifierRoles.DelegatedNotifier]: "Notifying on behalf of someone else",
  [NotifierRoles.Solicitor]: "A Solicitor"
};

export const roleOptions = [
  {
    value: NotifierRoles.SoleExecutor,
    label: "The Sole Executor named on the will"
  },
  {
    value: NotifierRoles.Executor,
    label: "An Executor named on the will, alongside others"
  },
  {
    value: NotifierRoles.Administrator,
    label: "An Administrator (there is no will)"
  },
  {
    value: NotifierRoles.DelegatedNotifier,
    label: "Notifying on behalf of someone else"
  },
  { value: NotifierRoles.Solicitor, label: "A Solicitor" }
];

// Defining the Person type
export type Person = {
  readonly id?: string;
  readonly title?: Titles;
  readonly firstName?: string;
  readonly lastName?: string;

  readonly dateOfBirth?: string;

  readonly doNotContact?: boolean;
  readonly email?: string;
  readonly contactNumber?: string;
  readonly contactMethod?: ContactMethods;
  readonly emailAddressVerified?: boolean;
  readonly phoneNumberVerified?: boolean | null;
  readonly contactTime?: string;

  readonly address?: string;
  readonly postcode?: string;
  readonly city?: string;
  readonly nationality?: string;

  readonly relationshipToDeceased?: Relationship;
  readonly relationshipToDeceasedOther?: string | null;

  readonly isVulnerable?: boolean;
  readonly proofOfAddress?: string[];
  readonly grantOfAuthorityAt?: string;
  readonly kycCompleted?: boolean;

  // roles are define by server
  readonly roles?: NotifierRolesByServer[] | string[]; // Array of roles

  readonly role?: NotifierRoles | string;
  readonly nok?: boolean;
  readonly willAvailable?: boolean;
};

export enum NotifierRolesByServer {
  Nok = "nok",
  Executor = "executor",
  Notifier = "notifier",
  Responsible = "responsible"
}
// # this is for any person or general field set by backend, possbile values "nok", "executor", "notifier"
// field(: roles, {: array, : string }, default: [])

// # set by frontend, its for specifcally notifier, one of possible notifier roles, nil for persons who are not notifier
// field(: role, Ecto.Enum, values: @all_roles)
// @required_attrs [:first_name, :last_name, :email]
// @notifier_roles [:sole_executor, :executor, :administrator, :solicitor, :delegated_notifier]
// def notifier_roles, do: @notifier_roles
// def notifier_roles(:executor), do: [:sole_executor, :executor, :solicitor]

// @all_roles [nil | @notifier_roles]

export interface Deceased extends Person {
  readonly aliases?: string;
  readonly caseOfDeath?: string;
  readonly dateOfDeath?: string;
  readonly dateOfBirth?: string;
  readonly deathCertificateNumber?: string;
  readonly idNumber?: string;
  readonly maritalStatus?: string;
  propertyId?: string;
  readonly regularCreditsFromDWP?: boolean;
}

export type PersonDetails = Person;
export type Nok = Person;
export type NotifierDetails = Person;
export type DeceasedDetails = Deceased;
export type Executor = Person;
export type PersonErrors = Partial<Record<keyof Person, string>>;

export const notifierRequiredFields: Map<keyof Person, true> = new Map([
  ["title", true], ["firstName", true], ["lastName", true],
  ["role", true], ["dateOfBirth", true],
  ["email", true], ["contactNumber", true],
  ["address", true], ["city", true], ["postcode", true],
  ["relationshipToDeceased", true]
]);

export const executorRequiredFields: Map<keyof Person, boolean> = new Map([
  ["title", true], ["firstName", true], ["lastName", true],
  ["email", true], ["contactNumber", true],
  ["relationshipToDeceased", true],
  ["address", true], ["city", true], ["postcode", true],
  // optional
  ["dateOfBirth", true],
]);

export const nokRequiredFields: Map<keyof Person, boolean> = new Map([
  ["title", true], ["firstName", true], ["lastName", true],
  ["email", true], ["contactNumber", true],
  ["relationshipToDeceased", true],
  ["address", true], ["city", true], ["postcode", true],
  // optional
  ["dateOfBirth", true],
]);
