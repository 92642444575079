import * as React from "react";
import { Typography } from "@mui/material";
import { WithFormTemplate } from "@templates/FormTemplate";
import { Accounts } from "./Accounts";
import { DeceasedDetails } from "./DeceasedDetails";
import { Documents } from "./Documents";
import { KYC } from "./KYC/KYC";
import { NotifierDetails } from "./NotifierDetails";
import { Submit } from "./Submit";
import { ExecutorForm } from "./ExecutorForm";
import { NokForm } from "./NokForm";
import { ReactElement } from "react";
import { Section } from "@src/Sections";

export function notifierComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedStates,
  persistedStateSetters,
  updatePerson,
  updatingNotifierEmailAddress,
  setUpdatingNotifierEmailAddress,
  onSectionClick
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <NotifierDetails
          caseId={caseId}
          signature={signature}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          continueWithoutChanges={continueWithoutChanges}
          persistedState={persistedStates.notifier}
          setPersistedState={persistedStateSetters.notifier}
          updatePerson={updatePerson}
          updatingNotifierEmailAddress={updatingNotifierEmailAddress}
          setUpdatingNotifierEmailAddress={setUpdatingNotifierEmailAddress}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          persistedStateSetters={persistedStateSetters}
        />
      )}
    />
  );
}

export function nokComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedStates,
  persistedStateSetters,
  onSectionClick, updatePerson
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <NokForm
          caseId={caseId}
          signature={signature}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          continueWithoutChanges={continueWithoutChanges}
          persistedState={persistedStates.nok}
          setPersistedState={persistedStateSetters.nok}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          updatePerson={updatePerson}
          persistedStateSetters={persistedStateSetters}

        />
      )}
    />
  );
}

export function executorComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedStates,
  persistedStateSetters,
  onSectionClick,
  updatePerson
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <ExecutorForm
          caseId={caseId}
          signature={signature}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          continueWithoutChanges={continueWithoutChanges}
          persistedStates={persistedStates}
          persistedStateSetters={persistedStateSetters}
          persistedState={persistedStates.executor}
          setPersistedState={persistedStateSetters.executor}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          updatePerson={updatePerson}
        />
      )}
    />
  );
}

export function deceasedComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedStates,
  persistedStateSetters,
  onSectionClick, properties, remoteError, removeRemoteError, updateProperty
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <DeceasedDetails
          caseId={caseId}
          signature={signature}
          properties={properties}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          continueWithoutChanges={continueWithoutChanges}
          remoteError={remoteError}
          persistedState={persistedStates.deceased}
          setPersistedState={persistedStateSetters.deceased}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          removeRemoteError={removeRemoteError}
          updateProperty={updateProperty}
        />
      )}
    />
  );
}

export function accountsComponent({
  menuEntries, caseId, signature, prepareUpdate,
  updateSuccessful, updateFailure, form, busy,
  continueWithoutChanges, persons, serviceProviders,
  onSectionClick, properties, remoteError, removeRemoteError, serviceProvidersMap,
  updateProperty, setBusy, updateServiceProvider, updatePerson, uploadedFileInfo
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <Accounts
          caseId={caseId}
          signature={signature}
          serviceProviders={serviceProviders}
          persons={persons}
          properties={properties}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          setBusy={setBusy}
          continueWithoutChanges={continueWithoutChanges}
          remoteError={remoteError}
          updateServiceProvider={updateServiceProvider}
          updatePerson={updatePerson}
          updateProperty={updateProperty}
          uploadedFileInfo={uploadedFileInfo}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          removeRemoteError={removeRemoteError}
          serviceProvidersMap={serviceProvidersMap}
        />
      )}
    />
  );
}

export function documentComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedStates,
  serviceProvidersMap,
  onSectionClick, uploadFile, remoteError, removeRemoteError, persistedStateSetters, uploadedFileInfo
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <Documents
          caseId={caseId}
          signature={signature}
          form={form}
          serviceProvidersMap={serviceProvidersMap}
          busy={busy}
          persistedState={persistedStates.documents}
          remoteError={remoteError}
          continueWithoutChanges={continueWithoutChanges}
          uploadFile={uploadFile}
          uploadedFileInfo={uploadedFileInfo}
          setPersistedState={persistedStateSetters.documents}
          prepareUpdate={prepareUpdate}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          removeRemoteError={removeRemoteError}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
        />
      )}
    />
  );
}

export function kycComponent({ menuEntries, form, continueWithoutChanges, onSectionClick }: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <KYC
          form={form}
          continueWithoutChanges={continueWithoutChanges}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
        />
      )}
    />
  );
}

export function submitComponent({
  menuEntries,
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  onSectionClick, remoteError,
  removeRemoteError, uploadedFileInfo,
  formCompletionState, serviceProviders,
  persons, properties, changeNotifierEmailAddress
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <Submit
          caseId={caseId}
          signature={signature}
          formCompletionState={formCompletionState}
          serviceProviders={serviceProviders}
          persons={persons}
          properties={properties}
          prepareUpdate={prepareUpdate}
          updateSuccessful={updateSuccessful}
          updateFailure={updateFailure}
          form={form}
          busy={busy}
          continueWithoutChanges={continueWithoutChanges}
          remoteError={remoteError}
          changeNotifierEmailAddress={changeNotifierEmailAddress}
          onSectionClick={onSectionClick}
          updateTemplate={updateTemplate}
          removeRemoteError={removeRemoteError}
          uploadedFileInfo={uploadedFileInfo}
        />
      )}
    />
  );
}

export function otherComponent({
  menuEntries,
  match,
  setActiveSection,
  busy,
  source,
  onSectionClick, form, bypassKYC
}: any): ReactElement {
  return (
    <WithFormTemplate
      menuEntries={menuEntries}
      proc={(updateTemplate) => (
        <Router
          busy={busy}
          bypassKYC={bypassKYC}
          form={form}
          match={match}
          onSectionClick={onSectionClick}
          setActiveSection={setActiveSection}
          source={source}
          updateTemplate={updateTemplate}
        />
      )}
    />
  );
}

export const Router = ({
  busy,
  bypassKYC,
  form,
  match,
  onSectionClick,
  setActiveSection,
  source,
  updateTemplate
}: {
  busy: any;
  bypassKYC: boolean;
  form: any;
  match: any;
  onSectionClick: any;
  setActiveSection: any;
  source: any;
  updateTemplate: any;
}) => {
  const isRoot = match.params["*"] === "";
  const onNext = React.useCallback(() => setActiveSection(Section.Accounts), [setActiveSection]);

  React.useEffect(() => {
    if (isRoot) {
      setTimeout(() => {
        setActiveSection(Section.Accounts, form);
      }, 10);
    }
  }, [source, isRoot, form, setActiveSection, bypassKYC]);

  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: undefined,
      saveLabel: null,
      onNext,
      onSectionClick
    });
  }, [busy, onNext, onSectionClick, updateTemplate]);

  if (isRoot) return null;
  return <Typography variant="body1">Use the menu to navigate through the form.</Typography>;
};

