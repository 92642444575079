import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";
import "./styles/index.scss";
import * as React from "react";
import { ReactElement } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import monitor from "@utils/monitoring";
import { theme } from "@styles/stylesheet";
import { AppRoutes } from "./AppRoutes";
import { BugFallbackPage } from "./pages/Error/BugFallbackPage";
import CookieNotice from "./components/organisms/cookie";
import onLoadSetup from "./init";
import { isBackendOnline } from "./services/api/sessionApi";
import { BrowserRouter } from "react-router-dom";

export const App = (): ReactElement => {
  // Execute `onLoadSetup` ASAP using `React.useMemo`.
  React.useMemo(() => onLoadSetup(), []);

  return (
    <BrowserRouter>
      <BackendActive>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <monitor.ErrorBoundary fallback={() => <BugFallbackPage />}>
              <AppRoutes />
              <CookieNotice />
            </monitor.ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </BackendActive>
    </BrowserRouter>
  );
};

interface BackendActiveProps {
  children: React.ReactNode;
}

export const BackendActive = ({ children }: BackendActiveProps): JSX.Element | null => {
  const [online, setOnline] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const checkBackendStatus = async () => {
      const status = await isBackendOnline();
      setOnline(status);
    };

    checkBackendStatus();

  }, []);

  // Show a loading indicator while checking the backend status.
  if (online === null) return <>Checking backend status...</>;

  // Do not render children if the backend is offline.
  if (!online) return <BugFallbackPage />;

  return <>{children}</>;
};
