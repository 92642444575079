import * as React from "react";

import { Alert, Snackbar } from "@mui/material";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, AccountNextStep, AccountType, ServiceProvider, ServiceProviderType } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "@atoms/TextInput";
import { AccountNextStepInput } from "@src/components/molecules";
import { FormField } from "@molecules/FormField";
import { useOnChange, useOnValueChange, useUpdater } from "@utils/callbacks";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields
} from "./ResponsibleFields";
import { SelectInput } from "@atoms/SelectInput";
import {
  DeceasedDetailsFields,
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord
} from "./DeceasedDetailsFields";
import { AccountFieldProps } from "./AccountForm";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly holderNumber?: string;
  readonly accountType?: string;
  readonly accountTypeOther?: string;
  readonly accountName?: string;
  readonly companyName?: string;
  readonly accountRequest?: AccountNextStep;
  readonly responsible?: ResponsibleRecord;
};

export type Errors =
  | undefined
  | {
  readonly deceased?: DeceasedErrors;
  readonly holderNumber?: string;
  readonly accountType?: string;
  readonly accountTypeOther?: string;
  readonly accountRequest?: string;
  readonly responsible?: ResponsibleErrors;
};

export const errorsOf: (r: Record, askForDeceasedDetails: boolean) => Errors = (
  record,
  askForDeceasedDetails
) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    accountType: record?.accountType ? undefined : "required",
    accountTypeOther:
      record?.accountType !== "other" || record?.accountTypeOther ? undefined : "required",
    accountRequest: record?.accountRequest ? undefined : "required",
    responsible: errorsOfResponsible(record?.responsible, record?.accountRequest)
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
      responsible: responsibleRecordFromAccount(account)
    };
  }

  return {
    deceased: {},
    holderNumber: account.holderNumber,
    accountType: account.accountType,
    accountTypeOther: account.accountTypeOther,
    accountRequest: account.nextSteps,
    responsible: responsibleRecordFromAccount(account)
  };
};

export type PensionAccountFieldsProps = AccountFieldProps;

export const PensionAccountFields: React.FC<PensionAccountFieldsProps> = ({
  persons,
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");
  const onAccountRequestValueChange = React.useCallback(
    (accountRequest) => {
      update((s: any) => ({
        ...s,
        accountRequest,
        responsible: {}
      }));
    },
    [update]
  );

  const updateAccountHolder = useUpdater(update, "responsible");
  const onChange = useOnChange(update);
  const onAccountTypeValueChange = useOnValueChange(update, "accountType");

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      onNext: !serviceProvider?.serviceProviderType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const responsible = {
            id: record.responsible?.responsibleId || undefined,
            details: record.responsible?.responsible || undefined,
            bankAccount: record.responsible?.responsibleBankAccount || undefined
          };

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              serviceProviderType: serviceProvider.serviceProviderType
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            holderNumber: record.holderNumber,
            accountType: record.accountType,
            accountTypeOther: record.accountTypeOther,
            accountName: record.accountName,
            companyName: record.companyName,
            nextStep: record.accountRequest,
            responsible
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);

          return saveAccount({
            sector: ServiceProviderType.Pension,
            record: data
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        }
    });
  }, [updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy, saveAccount]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField halfWidth label="Policy or Customer reference number (if known)"> <TextInput
        name="holderNumber"
        value={record.holderNumber || ""}
        onChange={onChange}
        error={errors?.holderNumber}
      /> </FormField>

      <FormField halfWidth label="Policy type"> <SelectInput
        name="accountType"
        value={record.accountType || ""}
        onValueChange={onAccountTypeValueChange}
        error={errors?.accountType}
        options={accountTypeOptions}
      />

        {record.accountType === "other" && (
          <TextInput
            name={"accountTypeOther"}
            value={record.accountTypeOther || ""}
            error={errors?.accountTypeOther}
            onChange={onChange}
          />
        )}
      </FormField>

      <FormField halfWidth label="Policy name/Scheme Type"> <TextInput
        name="accountName"
        value={record.accountName || ""}
        onChange={onChange} /> </FormField>

      {record.accountType === AccountType.WorkplacePension && (
        <FormField halfWidth label="Company name"> <TextInput
          name="companyName"
          value={record.companyName || ""}
          onChange={onChange} /> </FormField>
      )}

      <FormField label="Next steps"> <AccountNextStepInput
        onValueChange={onAccountRequestValueChange}
        value={record.accountRequest || ""}
        serviceProviderType={ServiceProviderType.Pension}
        error={errors?.accountRequest}
      /> </FormField>

      {record.responsible && record.accountRequest && (
        <ResponsibleFields
          serviceProvider={serviceProvider}
          persons={persons}
          nextStep={record.accountRequest}
          record={record.responsible}
          errors={errors?.responsible}
          update={updateAccountHolder}
        />
      )}

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};

const accountTypeOptions = [
  { value: AccountType.RetirementAccount, label: "Retirement account" },
  { value: AccountType.Annuities, label: "Annuities" },
  { value: AccountType.WorkplacePension, label: "Workplace pension" },
  { value: AccountType.Other, label: "Other" }
];
