import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Stack } from "@mui/material";

import { createDocumentAndUpload, getDocument, getCase } from "@api/caseApi";
import { AccountDetails } from "@customTypes/index";
import urlPaths from "../../urlPaths";
import { Header } from "../../components/atoms/Header";
import { BackLink } from "../../components/atoms/BackLink";
import { NeedHelpBox2 } from "../../components/molecules/NeedHelpBox";
import { AccountForm } from "../NotifierForm/AccountForm";
import { ServiceProvider } from "@customTypes/index";
import { Property } from "../NotifierForm/AccountForm/PropertyFields";
import { Person } from "../NotifierForm/AccountForm/ResponsibleFields";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { Button } from "../../components/atoms/Button";

export const EditAccountPage: React.FC<{
  caseId: string;
  signature: string | null;
  userIsAdmin: boolean;
}> = ({ caseId, signature, userIsAdmin }) => {
  const navigate = useNavigate();

  const params = useParams();

  const { accountId } = params as { accountId: string };

  const [{ account, persons, properties, serviceProviders }, setState] = React.useState({
    account: null as AccountDetails | null,
    serviceProviders: [] as ReadonlyArray<ServiceProvider>,
    properties: [] as ReadonlyArray<Property>,
    persons: [] as ReadonlyArray<Person>,
  });

  const [remoteError, setRemoteError] = React.useState<string | undefined>();

  const [busy, setBusy] = React.useState(false);

  const setAddingAccount = React.useCallback(() => {
    setBusy(true);
  }, [setBusy]);

  const onAccountUpdated = React.useCallback(() => {
    setBusy(false);
    if (userIsAdmin) {
      window.close();
    } else {
      navigate(`/status`, { replace: true });
    }
  }, [setBusy, navigate, userIsAdmin]);

  const closeWindow = React.useCallback(() => {
    window.close();
  }, []);

  // TODO: Refactor this repeated code
  const uploadedFileInfo = React.useCallback(
    (documentId: string) => {
      return getDocument({ caseId, signature, documentId })
        .then((res: any) => {
          if (!res.data) {
            throw new Error(`Missing results for document ${documentId}`);
          }

          return res.data;
        })
        .catch((err: Error) => {
          console.warn(err.message);
          setRemoteError("Failed to download the file you previously uploaded.");
          throw err;
        });
    },
    [caseId, signature]
  );

  React.useEffect(() => {
    getCase({ caseId, signature })
      .then((res) => {
        if (!res.data?.case) {
          return Promise.reject(new Error("Missing data."));
        }

        const { case: caseRecord, persons, properties, serviceProviders, form } = res.data;

        if (!caseRecord?.formSubmittedAt) {
          navigate(urlPaths.root(), { replace: true });
          return;
        }

        setState({
          account: form.accounts.find((account: AccountDetails) => account.id === accountId),
          persons,
          properties,
          serviceProviders,
        });
      })
      .catch((err) => {
        setState((s) => ({ ...s }));
        console.warn({ err });
      });
  }, [caseId, signature, accountId, navigate]);

  if (!account) {
    return null;
  }

  return (
    <EditAccountPageView
      caseId={caseId}
      signature={signature}
      account={account}
      persons={persons}
      properties={properties}
      serviceProviders={serviceProviders}
      uploadedFileInfo={uploadedFileInfo}
      setAddingAccount={setAddingAccount}
      onAccountUpdated={onAccountUpdated}
      closeWindow={closeWindow}
      busy={busy}
      setBusy={setBusy}
      userIsAdmin={userIsAdmin}
      errorMessage={remoteError}
    />
  );
};

export type EditAccountPageViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly userIsAdmin: boolean;
  readonly account: AccountDetails;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly busy: boolean;
  readonly setBusy: (busy: boolean) => void;
  readonly closeWindow: () => void;
  readonly onAccountUpdated: () => void;
  readonly setAddingAccount: () => void;
  readonly uploadedFileInfo: (documentId: string) => Promise<any>;
  readonly errorMessage?: string;
};

export const EditAccountPageView: React.FC<EditAccountPageViewProps> = ({
  caseId,
  signature,
  userIsAdmin,
  account,
  serviceProviders,
  properties,
  persons,
  busy,
  setBusy,
  closeWindow,
  onAccountUpdated,
  uploadedFileInfo,
  errorMessage,
}) => {
  const { onNext, update: updateTemplate } = useActions();

  return (
    <SimpleTemplate busy={busy} headerType="signout" errorMessage={errorMessage}>
      <Stack rowGap={4}>
        {userIsAdmin ? (
          <BackLink onClick={closeWindow}>Cancel and close this window</BackLink>
        ) : (
          <BackLink to="/status">Back to dashboard</BackLink>
        )}

        <Stack rowGap={4}>
          <Header level={1}>{account.companyName} account</Header>

          {!userIsAdmin ? null : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Stack rowGap={4}>
                  <AccountForm
                    caseId={caseId}
                    signature={signature}
                    account={account}
                    serviceProviders={serviceProviders || []}
                    properties={properties}
                    persons={persons}
                    // busy={busy}
                    setBusy={setBusy}
                    onAccountUpdated={onAccountUpdated}
                    uploadedFileInfo={uploadedFileInfo}
                    createDocumentAndUpload={(...args) =>
                      createDocumentAndUpload(caseId, signature, ...args)
                    }
                    updateTemplate={updateTemplate}
                  />

                  <Box>
                    <Button
                      variant="secondary"
                      size="large"
                      disabled={!onNext || busy}
                      onClick={onNext}
                    >
                      Update account
                    </Button>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <NeedHelpBox2 />
              </Grid>
            </Grid>
          )}
        </Stack>
      </Stack>
    </SimpleTemplate>
  );
};

type State = {
  onNext?: () => void;
};

const useActions = () => {
  const [{ onNext }, update] = React.useState<State>({});

  return {
    onNext,
    update,
  };
};
