import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { colors } from "../../styles/constants";
import { Account  } from "../../types/Account";
import { Button } from "../../components/atoms/Button";
import { AccountCollapsible } from "./AccountCollapsible";
import { Stack, Typography } from "@mui/material";
import { Header } from "../../components/atoms/Header";
import { Add } from "@mui/icons-material";
import urlPaths from "../../urlPaths";
import { SelfManagedAccountInfoDialog } from "@src/components/atoms/infoBox";
import { InfoBoxTrigger } from "@src/components/molecules";
import { hasUnreadMessage, isAdditonalServices, isCompleted, isInProgress, isRequiresAttention, isSelfManaged } from "@src/utils/accounts";

type Sections = {
  requiresAttention: Array<Account>;
  inProgress: Array<Account>;
  completed: Array<Account>;
  additionalServicesAccount: Array<Account>;
  selfManaged: Array<Account>;
};

export const Accounts: React.FC<{
  readonly caseId: string;
  readonly signature: string | null;
  readonly accounts: Array<Account>;
  readonly isExpressConvertedToNEForm: boolean;
  readonly readOnly?: boolean;
  // readonly busy: boolean;
  readonly setBusy: (busy: boolean) => void;
}> = ({ signature, caseId, accounts, isExpressConvertedToNEForm }) => {
  const navigate = useNavigate();

  const addAccount = React.useCallback(() => {
    navigate("/status/accounts/new");
  }, [navigate]);

  return (
    <AccountsView
      caseId={caseId}
      signature={signature}
      accounts={accounts}
      isExpressConvertedToNEForm={isExpressConvertedToNEForm}
      addAccount={addAccount}
    />
  );
};

export type AccountsViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly accounts: ReadonlyArray<Account>;
  readonly isExpressConvertedToNEForm: boolean;
  readonly addAccount: () => void;
};


export const AccountsView: React.FC<AccountsViewProps> = ({
  caseId,
  signature,
  accounts,
  addAccount,
  isExpressConvertedToNEForm,
}) => {
  console.log(accounts)
  const sectionsOfServices = React.useMemo(() => {
    const sections = {
      requiresAttention: [],
      inProgress: [],
      completed: [],
      additionalServicesAccount: [],
      selfManaged: []
    } as Sections;
    if (!accounts) return sections;

    accounts.forEach((account) => {
      if (isAdditonalServices(account)) {
        sections.additionalServicesAccount.push(account);
      }
      else if (hasUnreadMessage(account)) {
        sections.requiresAttention.push(account);
      }
       else if (isSelfManaged(account)) {
        sections.selfManaged.push(account);
      }
      else if (isInProgress(account)) {
        sections.inProgress.push(account);
      }
      else if (isCompleted(account)) {
        sections.completed.push(account);
      }
      else if (isRequiresAttention(account)) {
        sections.requiresAttention.push(account);
      }
    });
    return sections;
  }, [accounts]);

  const [highlightButton, setHighlightButton] = React.useState(false);
  const [searchParams] = useSearchParams();
  const account_id = searchParams.get("account_id");
  const add_accounts_focus = searchParams.get("add_accounts") === "true";

  React.useEffect(() => {
    if (account_id) {
      const element = document.getElementById(account_id);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [account_id]);

  React.useEffect(() => {
    if (add_accounts_focus) {
      setHighlightButton(true);

      const timeoutId = setTimeout(() => setHighlightButton(false), 2000);
      const buttonElement = document.getElementById('add-account-button');

      if (buttonElement) {
        buttonElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        buttonElement.focus({ preventScroll: true });
      }

      return () => clearTimeout(timeoutId);
    }
  }, [add_accounts_focus]);

  return (
    <>
      <Stack spacing={3} sx={{ paddingTop: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2>Selected Additional Services</h2>
        </Stack>

        <Section title="">
          {sectionsOfServices.additionalServicesAccount.length === 0 ? (
            <NoAccountsText>
              No Additional Services Requested.Select from above and reload
            </NoAccountsText>
          ) : (
            sectionsOfServices.additionalServicesAccount.map((account) => (
              <div key={account.id} id={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="blocked"
                  open={account_id === account.id}
                />
              </div>
            ))
          )}
        </Section>
      </Stack>

      <Stack spacing={3} sx={{ paddingTop: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2>Your accounts</h2>

          {isExpressConvertedToNEForm ? (
            <Button
              id="add-account-button"
              icon={<Add fontSize="small" />}
              href={urlPaths.form()}
              variant="primary"
              size="large"
              className={highlightButton ? 'highlight-button' : ''}
            >
              Add more accounts
            </Button>
          ) : (
            <Button
              id="add-account-button"
              icon={<Add fontSize="small" />}
              onClick={addAccount}
              variant="primary"
              size="large"
              className={highlightButton ? 'highlight-button' : ''}
            >
              Add account
            </Button>
          )}
        </Stack>

        <Section title={`Requires your attention (${sectionsOfServices.requiresAttention.length})`}>
          {sectionsOfServices.requiresAttention.length === 0 ? (
            <NoAccountsText>No accounts require your attention.</NoAccountsText>
          ) : (
            sectionsOfServices.requiresAttention.map((account) => (
              <div key={account.id} id={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="blocked"
                  open={account_id === account.id}
                />
              </div>
            ))
          )}
        </Section>

        <Section title={`In progress (${sectionsOfServices.inProgress.length})`}>
          {sectionsOfServices.inProgress.length === 0 ? (
            <NoAccountsText>No accounts in progress.</NoAccountsText>
          ) : (
            sectionsOfServices.inProgress.map((account) => (
              <div key={account.id} id={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="processing"
                  open={account_id === account.id}
                />
              </div>
            ))
          )}
        </Section>

        <Section
          title={
            <>
              Self Managed Accounts
              {"  "}
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <InfoBoxTrigger white width="448px" content={SelfManagedAccountInfoDialog()} />
              </span>
            </>
          }
        >
          {sectionsOfServices.selfManaged.length === 0 ? (
            <NoAccountsText>No accounts in progress.</NoAccountsText>
          ) : (
            sectionsOfServices.selfManaged.map((account) => (
              <div key={account.id} id={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="processing"
                  open={account_id === account.id}
                />
              </div>
            ))
          )}
        </Section>

        <Section title={`Completed (${sectionsOfServices.completed.length})`}>
          {sectionsOfServices.completed.length === 0 ? (
            <NoAccountsText>No accounts completed yet.</NoAccountsText>
          ) : (
            sectionsOfServices.completed.map((account) => (
              <div key={account.id} id={account.id}>
                <AccountCollapsible
                  key={account.id}
                  signature={signature}
                  caseId={caseId}
                  account={account}
                  section="settled"
                  open={account_id === account.id}
                />
              </div>
            ))
          )}
        </Section>
      </Stack>
    </>
  );
};

export type SectionProps = {
  readonly title: React.ReactNode;
  readonly children: React.ReactNode;
};

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <Stack rowGap={1}>
      <Header level={2}>{title}</Header>
      <Stack rowGap={2}>{children}</Stack>
    </Stack>
  );
};

type NoAccountsTextProps = {
  readonly children: React.ReactNode;
};

const NoAccountsText: React.FC<NoAccountsTextProps> = ({ children }) => (
  <Typography variant="body1" color={colors.fadedText}>
    {children}
  </Typography>
);

