import monitor from "../../utils/monitoring";
import { apiFetch } from "@api/restApi";
export const getApplicantAndToken = async (args: { caseId: string; signature: string | null }) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/onfido/get_applicant_and_token`,
      search: !signature ? undefined : { caseId, sig: signature }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/onfido/get_applicant_and_token",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const createChecks = async (args: {
  caseId: string;
  signature: string | null;
  variant: string;
}) => {
  const { caseId, signature, variant } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/onfido/checks`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: variant ? { variant } : {}
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/onfido/checks",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const getChecks = async (args: {
  caseId: string;
  signature: string | null;
  checkId: string;
}) => {
  const { caseId, signature, checkId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/onfido/checks/${checkId}`,
      search: !signature ? undefined : { caseId, sig: signature }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/onfido/checks/{checkId}",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const downloadCheck = async (args: {
  caseId: string;
  signature: string | null;
  checkId: string;
}) => {
  const { caseId, signature, checkId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/onfido/checks/${checkId}/download`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: {}
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/onfido/checks/{checkId}/download",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const getReports = (args: {
  caseId: string;
  signature: string | null;
  reportIds: readonly string[];
}) => {
  const { caseId, signature, reportIds } = args;

  return Promise.all(
    reportIds.map((reportId) => {
      return getReport({ caseId, signature, reportId });
    })
  );
};

const getReport = async (args: { caseId: string; signature: string | null; reportId: string }) => {
  const { caseId, signature, reportId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/onfido/reports/${reportId}`,
      search: !signature ? undefined : { caseId, sig: signature }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/onfido/reports/{reportId}",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const getCase = async (args: {
  caseId: string;
  signature: string | null;
  email?: string | null;
}) => {
  const result = await getCaseRequest(args);

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  

  return result.data;
};

export const getCaseRequest = async (args: {
  caseId: string;
  signature: string | null;
  email?: string | null;
}) => {
  const { caseId, signature, email } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}`,
      search: !signature ? { email } : { caseId, sig: signature, email }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}",
      args,
      error
    });
    throw error;
  }

  return result;
};
