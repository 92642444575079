import monitor from "../../utils/monitoring";
import { apiFetch } from "./restApi";
import { waitP } from "@utils/Functions";
import { isUserLoggedIn, LocalSession, RemoteSession } from "@utils/session";

export type ServerRecord = {
  emailAddress: string;
  password?: string;
  captcha: string;
};

export const isBackendOnline = async (): Promise<any> => {
  try {
    const result = await apiFetch({ path: "/status", method: "GET" });
    if (result.status === "active") return true;
  }
  catch (e) {
    console.log("Cant access backend", e)
    return false;
  }
};


export const checkOnlineSession = async (): Promise<RemoteSession> => {
  let result;

  try {
    if (isUserLoggedIn()) {
      result = await checkOnlineSessionRequest();
    } else {
      result = { error: "unauthorized" };
    }
  } catch {
    return waitP(1000).then(() => {
      return checkOnlineSession();
    });
  }

  switch (result.error) {
    case undefined:
      return result.data;

    case "unauthorized":
    default:
      return {
        session:
        {
          caseId: null, signature: null,
          token: null,
        }

      };
  }
};

const checkOnlineSessionRequest = async () => {
  let result;
  try {
    result = await apiFetch({
      path: "/notifier/session",
      method: "GET",
      timeout: 2000
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/session",
      args: {},
      error
    });
    throw error;
  }

  return result;
};

type FormStatusRequestProps = {
  caseId: string;
  signature: string;
};

export const checkFormStatus = async ({
  caseId,
  signature
}: FormStatusRequestProps): Promise<LocalSession | { session: null }> => {
  let result;
  try {
    result = await checkFormStatusRequest({ caseId, signature });
  } catch {
    return waitP(1000).then(() => {
      return checkFormStatus({ caseId, signature });
    });
  }

  if (result.error) {
    throw new Error(result.error);
  }

  return result.data;
};

const checkFormStatusRequest = async ({ caseId, signature }: FormStatusRequestProps) => {
  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/form-status`,
      search: { caseId, sig: signature },
      timeout: 2000
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}/form-status",
      args: { caseId, sig: signature },
      error
    });
    throw error;
  }

  return result;
};

type ServerResponseRecord = {
  session: {
    caseId: string;
    signature: string;
    token: string;
  };
};
export const requestSignIn = async (args: ServerRecord): Promise<ServerResponseRecord> => {
  let result;

  try {
    result = await doRequestSignIn(args);
  } catch(error) {
    console.log(error);
  }

  if (result.error) {
    switch (result.error) {
      case "invalid credentials":
        throw new Error("Invalid email and password combination");
      default:
        throw new Error(result.error);
    }
  }

  return result.data;
};

const doRequestSignIn = async (args: ServerRecord) => {
  let result;
  try {
    result = await apiFetch({
      path: "/notifier/session",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/session",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const initiatePasswordReset = async (args: ServerRecord) => {
  let result;
  try {
    result = await doInitiatePasswordReset(args);
  } catch {
    throw new Error("Error; please try again");
  }

  if (result.error) {
    throw new Error("Unexpected error; please contact customer support");
  }


  return result.data;
};

const doInitiatePasswordReset = async (args: ServerRecord) => {
  let result;

  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials/initiate-password-reset",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials/initiate-password-reset",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const resetPassword = async (args: PasswordServerRecord) => {
  let result;

  try {
    result = await resetPasswordRequest(args);
  } catch {
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "invalid_token":
        throw new InvalidTokenError();
      default:
        throw new Error("Unexpected error; please contact customer support");
    }
  }
  return result.data;
};


export class InvalidTokenError extends Error {
}


type PasswordServerRecord = {
  readonly token: string;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly captcha: string;
};

const resetPasswordRequest = async (args: PasswordServerRecord) => {
  let result;
  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials/reset-password",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials/reset-password",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const validateTokenRequest = async (args: { token: string }) => {
  let result;
  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials/validate-password-reset-token",
      search: args,
      method: "GET"
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials/validate-password-reset-token",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const signupRequest = async (args: {
  password: string;
  passwordConfirmation: string;
  captcha: string;
}) => {
  let result;

  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials",
      args,
      error
    });
    throw error;
  }

  return result;
};
