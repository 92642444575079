import * as React from "react";

import { ErrorPane } from "@organisms/ErrorPane";
import { onfidoConfig } from "./onfidoConfig";

export type KYCApi = {
  getApplicantAndToken: () => Promise<
    | { error: Error; data: undefined }
    | { error: undefined; data: { applicantId: string; token: string } }
  >;
  createChecks: (_: {
    applicantId: string;
    variant: string;
  }) => Promise<
    { error: Error; data: undefined } | { error: undefined; data: { checkId: string } }
  >;
  getChecks: (_: {
    checkId: string;
  }) => Promise<
    | { error: Error; data: undefined }
    | { error: undefined; data: { status: string; result: string; reportIds: readonly string[] } }
  >;
  getReports: (_: {
    reportIds: readonly string[];
  }) => Promise<
    ReadonlyArray<
      { error: Error; data: undefined } | { error: undefined; data: { subResult: string } }
    >
  >;
  downloadCheck: (_: {
    checkId: string;
  }) => Promise<{ error: Error; data: undefined } | { error: undefined; data: any }>;
};

export type KYCProps = {
  api: KYCApi;
  setBusy: (busy: boolean) => void;
  onDone: () => void;
};

export const KYC: React.FC<KYCProps> = ({ api, setBusy, onDone }) => {
  const [state, setState] = React.useState({
    applicantId: null as string | null,
    token: null as string | null
  });

  const [unrecoverableError, setUnrecoverableError] = React.useState<Error | null>(null);

  const [acc, setAcc] = React.useState(0);

  const refresh = React.useCallback(() => {
    setAcc(acc + 1);
  }, [acc, setAcc]);

  const { applicantId, token } = state;

  const done = React.useCallback(
    (checkId: string) => {
      api
        .downloadCheck({ checkId })
        .catch((error) => {
          console.warn(error);
        })
        .then(() => {
          setBusy(false);
          onDone();
        });
    },
    [api, setBusy, onDone]
  );

  React.useEffect(() => {
    api.getApplicantAndToken().then(
      (response) => {
        if (response.error) {
          setUnrecoverableError(response.error);
          return;
        }
        const { applicantId, token } = response.data;
        setState((s) => ({
          ...s,
          applicantId,
          token
        }));
      },
      (error) => {
        setUnrecoverableError(error);
      }
    );
  }, [api, acc]);

  React.useEffect(() => {
    if (!applicantId || !token) return;

    const config = onfidoConfig({
      token,
      onComplete: (data: any) => {
        onfido.tearDown();
        setBusy(true);

        callWithReference((repeat) => {
          api.createChecks({ applicantId, variant: data.face?.variant }).then(
            (response) => {
              if (response.error) {
                console.warn(response.error);
                return;
              }

              const { data } = response;

              const interval = setInterval(() => {
                const { checkId } = data;
                api.getChecks({ checkId }).then(
                  (response) => {
                    if (response.error) {
                      console.warn(response.error);
                      return;
                    }
                    const { data } = response;
                    if (data.status !== "complete") {
                      return;
                    }

                    clearInterval(interval);

                    if (data.result !== "consider") {
                      done(checkId);
                    } else {
                      api.getReports({ reportIds: data.reportIds }).then(
                        (results) => {
                          if (
                            results.some(
                              (result) => result.error || result.data.subResult === "rejected"
                            )
                          ) {
                            setBusy(false);
                            refresh();
                            window.alert("Verification failed; please try again.");
                            return;
                          }

                          done(checkId);
                        },

                        (error) => {
                          console.warn(error);
                          window.alert("Verification failed; please try again.");
                        }
                      );
                    }
                  },
                  (error) => {
                    console.warn(error);
                  }
                );
              }, 2000);
            },
            _ => repeat()
          );
        });
      },

      onError: (error: any) => {
        if (error.type === "expired_token") return;
        console.log("error from onfido",error);
        // exception
      },

      onModalRequestClose: () => {
        onfido.setOptions({ isModalOpen: false });
      },

      onUserExit: (userExitCode: any) => {
        if (userExitCode === "USER_CONSENT_DENIED") {
          return;
        }
      }
    });

    // @ts-ignore
    const onfido = window.Onfido.init(config);
  }, [api, applicantId, token, setBusy, onDone, done, refresh]);

  if (unrecoverableError) {
    return (
      <ErrorPane
        message="Unexpected failure"
        description="Please reload the page. If the problem persists, please contact customer support."
      />
    );
  }

  return <div id="onfido-mount"></div>;
};

const callWithReference = (proc: (a: () => void) => void) => {
  const wrapper = () => {
    proc(wrapper);
  };
  return proc(wrapper);
};
