import { apiFetch } from "./restApi";
import monitor from "../../utils/monitoring";

export const createDocumentRequest = async (args: { filename: string; tags?: string[] }) => {
  const { filename, tags } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/documents`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { filename, tags }
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/express/documents`,
      args,
      error
    });
    throw error;
  }

  return result;
};

type SlugLookupRequest = {
  slug: string;
};

export const lookup = async (args: SlugLookupRequest) => {
  let result;

  try {
    result = await lookupRequest(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    throw new Error(result.error);
  }
  return result.data;
};

export const lookupRequest = async (args: SlugLookupRequest) => {
  const { slug } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/service_providers/${slug}`,
      search: undefined,
      method: "GET"
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/express/service_provider/${slug}`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export type SubmitServerRecord = { data: any };

export const submitExpressRecord = async (args: SubmitServerRecord) => {
  let result;

  try {
    result = await doSubmitExpressRecord(args);
  } catch (error: any) {
    
    throw new Error(error?.toString());
  }

  if (result.error) {
    switch (result.error) {
      default:  
        throw new Error(result.error);
    }
  }

  return result.data;
};

export const doSubmitExpressRecord = async (args: SubmitServerRecord) => {
  const { data } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/submit`,
      search: undefined,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { data }
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/express/submit`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export type Record = {
  caseId: string;
  signature: string | null;
  subject: "case_closed";
  score: number;
  comment: string;
};

export const saveUserRatings = async (args: Record) => {
  let result;
  try {
    result = await apiFetch({
      path: `/user-ratings`,
      search: !args.signature ? undefined : { caseId: args.caseId, sig: args.signature },
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /user-ratings",
      args,
      error
    });
    throw error;
  }

  return result;
};
type ServerRecord = {
  caseId: string;
  signature: string | null;
  subject: "form";
  score: number;
  comment: string;
};

export const saveUserRating = async ({ caseId, signature, ...args }: ServerRecord) => {
  let result;
  try {
    result = await apiFetch({
      path: `/user-ratings`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: "POST /user-ratings",
      args,
      error
    });
    throw error;
  }

  return result;
};
