import * as React from "react";
import { FileRejection } from "react-dropzone";
import { CheckCircle, CloseOutlined } from "@mui/icons-material";
import { Box, Container, Stack } from "@mui/material";
import { BasicDropzone } from "../molecules/BasicDropzone";
import { UploadRecord } from "@src/types/Document";


export type SimpleUploadFieldProps = {
  busy: boolean;
  entries: ReadonlyArray<UploadRecord>;
  error?: string;
  upload: (fs: File[]) => void;
  remove: (id: string) => void;
  setError: (message: string) => void;
  tag?: string;
};

export const SimpleUploadField: React.FC<SimpleUploadFieldProps> = ({
  busy,
  entries,
  error,
  upload,
  remove,
  setError,
}) => {
  const handleDropRejected = React.useCallback(
    (t: FileRejection[]) => {
      setError(t[0].errors.map((e) => e.message)[0]);
    },
    [setError]
  );
  return (
    <Stack rowGap={2}>
      <BasicDropzone
        files={undefined}
        errors={!error ? undefined : ([error] as readonly string[])}
        onDrop={upload}
        onDropRejected={handleDropRejected}
        isUploading={busy}
        multiple={true}
      />

      <Container>
        <Stack>
          {entries.map(({ id, filename }) => (
            <Stack key={id} direction="row" alignItems="start" columnGap={1}>
              <span style={{ verticalAlign: "sub" }}>
                <CheckCircle fontSize="small" color="success" />
              </span>
              <Box >{filename}</Box>
              <a
                href="#"
                style={{ color: "#b00000", cursor: "pointer", textDecoration: "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  remove(id);
                }}
              >
                <span style={{ verticalAlign: "top" }}>
                  remove
                </span>
                <span style={{ verticalAlign: "sub" }}>
                  <CloseOutlined fontSize="small" />
                </span>

              </a>
            </Stack>
          ))}
        </Stack>
      </Container>
    </Stack>
  );
};

// const PreviousDocuments: React.FunctionComponent<{
//   readonly documents: ReadonlyArray<Document>;
//   readonly remove: (id: string) => void;
// }> = (props) => {
//   const { documents: files, remove } = props;

//   return (
//     <>
//       {files.map((file, index) => (
//         <Stack key={index} direction="row" alignItems="start" columnGap={1}>
//           <a style={{ color: "#b00000", cursor: "pointer" }} onClick={() => remove(file.id)}>
//             <CloseOutlined fontSize="small" />
//           </a>

//           <Link
//             download={file.filename}
//             target="_blank"
//             rel="noopener noreferrer"
//             to={file.downloadUrl}
//           >
//             {file.filename}
//           </Link>
//         </Stack>
//       ))}
//     </>
//   );
// };
