import * as React from "react";

import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { InfoBoxTrigger } from "../molecules/InfoBoxTrigger";
import { ReactElement } from "react";

export type RadioGroupInputProps = {
  readonly value?: string;
  readonly fields: Array<{ label: React.ReactNode; value: string; info?: string }>;
  readonly error?: string;
  readonly disabled?: boolean;
  readonly onValueChange: (value: string) => void;
};

export const RadioGroupInput = (props: RadioGroupInputProps): ReactElement => {
  const { value, fields, onValueChange, error, disabled } = props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        return;
      }
      onValueChange((event.target as HTMLInputElement).value);
    },
    [onValueChange]
  );

  return (
    <FormControl error={!!error} disabled={disabled}>
      <RadioGroup value={value === undefined ? null : value} onChange={handleChange}>
        {fields.map(({ label, value, info }) => {
          return (
            <FormControlLabel
              value={value}
              label={
                <>
                  {label}
                  {info && <InfoBoxTrigger white width="448px" content={info} />}
                </>
              }
              key={value}
              control={<Radio />}
            />
          );
        })}
        <FormHelperText>{error}</FormHelperText>
      </RadioGroup>
    </FormControl>
  );
};
