import * as React from "react";

import { makeStyles } from "@mui/styles";
import { Button as MuiButton } from "@mui/material";

import { colors } from "@styles/constants";

export type ButtonProps = {
  variant: "primary" | "secondary";
  size: "large" | "medium" | "small";
  icon?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  target?: "_blank";
  children: React.ReactNode;
  fullWidth?: boolean;
  autoFocus?: boolean;
  submit?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
  id?: string | undefined,
  className?: string | undefined,
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant, size, icon, disabled, href, target, fullWidth, submit, onClick, id, className: userClass }, ref) => {
    const classes = useStyles();

    const variantClass =
      variant === "primary"
        ? size === "large"
          ? classes.primaryOrange
          : classes.primaryTeal
        : variant === "secondary"
          ? size === "large"
            ? icon
              ? classes.secondaryTeal
              : classes.secondaryOrange
            : classes.secondaryTeal
          : "";

    const sizeClass =
      size === "large"
        ? icon
          ? classes.largeWithIcon
          : classes.large
        : size === "medium"
          ? classes.medium
          : size === "small"
            ? classes.small
            : "";

    const className = `${userClass} ${classes.button} ${variantClass} ${sizeClass} ${fullWidth ? classes.fullWidth : ""
      }`;

    if (ref) {
      return (
        <MuiButton
          id={id}
          ref={ref}
          variant={variant === "primary" ? "contained" : "outlined"}
          className={className}
          disabled={disabled}
          href={href}
          // @ts-ignore
          target={target}
          type={submit ? "submit" : "button"}
          onClick={onClick}
        >
          {icon ? (
            <div>
              {icon}
              {children}
            </div>
          ) : (
            children
          )}
        </MuiButton>
      );
    }

    return (
      //@ts-ignore
      <MuiButton
        id={id}
        variant={variant === "primary" ? "contained" : "outlined"}
        className={className}
        disabled={disabled}
        href={href}
        // @ts-ignore
        target={target}
        type={submit ? "submit" : "button"}
        onClick={onClick}
      >
        {icon ? (
          <div className={classes.iconContainer}>
            {icon}
            {children}
          </div>
        ) : (
          children
        )}
      </MuiButton>
    );
  }
);
Button.displayName = 'Button';

const useStyles = makeStyles({
  button: {
    borderWidth: "2px",
    borderRadius: "50%",
    textTransform: "uppercase",
    "&:hover": {
      borderWidth: "2px"
    },
    "&:disabled": {
      borderWidth: "2px"
    }
  },

  fullWidth: {
    display: "block",
    width: "100%"
  },

  primaryOrange: {
    backgroundColor: `${colors.accentOrange} !important`,
    borderColor: `${colors.accentOrange} !important`,
    color: `${colors.white} !important`,
    "&:hover": {
      backgroundColor: `${colors.fadedOrange} !important`,
      borderColor: `${colors.fadedOrange} !important`
    },
    "&:disabled": {
      backgroundColor: `${colors.lightGrey} !important`,
      borderColor: `${colors.lightGrey} !important`
    }
  },

  primaryTeal: {
    backgroundColor: `${colors.accentTeal} !important`,
    borderColor: `${colors.accentTeal} !important`,
    color: `${colors.white} !important`,
    "&:hover": {
      backgroundColor: `${colors.lightTeal} !important`,
      borderColor: `${colors.lightTeal} !important`,
      color: `${colors.white} !important`
    },
    "&:disabled": {
      backgroundColor: `${colors.lightGrey} !important`,
      borderColor: `${colors.lightGrey} !important`
    }
  },

  secondaryOrange: {
    backgroundColor: `transparent !important`,
    borderColor: `${colors.accentOrange} !important`,
    color: `${colors.accentOrange} !important`,
    "&:hover": {
      backgroundColor: `transparent !important`,
      borderColor: `${colors.fadedOrange} !important`,
      color: `${colors.fadedOrange} !important`
    },
    "&:disabled": {
      borderColor: `${colors.lightGrey} !important`,
      color: `${colors.lightGrey} !important`
    }
  },

  secondaryTeal: {
    backgroundColor: `transparent !important`,
    borderColor: `${colors.accentTeal} !important`,
    color: `${colors.accentTeal} !important`,
    "&:hover": {
      backgroundColor: `transparent !important`,
      borderColor: `${colors.lightTeal} !important`,
      color: `${colors.lightTeal} !important`
    },
    "&:disabled": {
      borderColor: `${colors.lightGrey} !important`,
      color: `${colors.lightGrey} !important`
    }
  },

  large: {
    borderRadius: "30px",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    padding: "12px 48px"
  },

  medium: {
    borderRadius: "30px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    padding: "8px 32px"
  },

  small: {
    borderRadius: "30px",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "13px",
    padding: "5px 24px"
  },

  largeWithIcon: {
    borderRadius: "30px",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    padding: "12px 48px 12px 24px"
  },

  iconContainer: {
    display: "flex",
    columnGap: "24px"
  }
});
