import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

const appElement = React.createElement(App);
/*
ESLint marking ReactDOM as depreciated, but alternative isn't valid for React 17. If project updates to React>=18,
this can be changed to:
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
*/
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(appElement, document.getElementById("root"));

