import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Container, Snackbar, Stack, Typography } from "@mui/material";
import { YesNo } from "@src/types";
import urlPaths from "@src/urlPaths";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { YesNoCheckboxField } from "../../components/atoms/YesNoField";
import { updateAdditionalServices } from "@api/caseApi";

type StateRecord = {
  requestFinancialGuidance?: YesNo;
  requestFuneralDirector?: YesNo;
  requestHelpDealingWithDebt?: YesNo;
  requestHelpDealingWithFamilyConflict?: YesNo;
  requestHomeRemoval?: YesNo;
  requestLocalGriefSupport?: YesNo;
  requestProbateBridgingLoans?: YesNo;
  requestProbateSolicitor?: YesNo;
  requestPropertyValuationService?: YesNo;
  requestMissingAssetSearch?: YesNo;
};

export const AdditionalServicesPage: React.FunctionComponent<{
  caseId: string;
  signature: string | null;
}> = ({ caseId, signature }) => {
  const navigate = useNavigate();

  const [busy, setBusy] = React.useState(false);
  const [record, setRecord] = React.useState({} as StateRecord);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [remoteError, setRemoteError] = React.useState("");

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | undefined } }) => {
      setRecord((s) => ({ ...s, [name]: value }));
      setHasChanges(true);
    },
    [setRecord]
  );

  const onContinue = () => {
    setBusy(true);
    updateAdditionalServices({
      caseId,
      signature,
      record
    }).then(
      () => {
        setBusy(false);
        navigate(urlPaths.status());
        if (hasChanges) {
          localStorage.setItem("additional-services-requested", "true");
        }
      },
      (error) => {
        setRemoteError(error.message);
      }
    );
  };

  const removeRemoteError = React.useCallback(() => {
    setRemoteError("");
  }, [setRemoteError]);

  return (
    <AdditionalServicesPageView
      busy={busy}
      record={record}
      remoteError={remoteError}
      removeRemoteError={removeRemoteError}
      onFieldChange={onFieldChange}
      onContinue={onContinue}
    />
  );
};

export type AdditionalServicesPageViewProps = {
  busy: boolean;
  record: StateRecord;
  remoteError?: string;
  removeRemoteError: () => void;
  onFieldChange: (event: { target: { name: string; value: string } }) => void;
  onContinue: () => void;
};

export const AdditionalServicesPageView: React.FC<AdditionalServicesPageViewProps> = ({
  busy,
  record,
  remoteError,
  removeRemoteError,
  onFieldChange,
  onContinue
}) => {
  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });

  const Entry = ({ label, name }: { label: string; name: keyof StateRecord }) => (
    <FormField> <YesNoCheckboxField
      label={label}
      value={record[name]}
      onValueChange={onFieldValueChange(name)}
    /> </FormField>
  );

  return (
    <SimpleTemplate headerType="signout" busy={busy}> <Stack rowGap={2}> <Header level={1}>Other service
      options</Header> <Stack rowGap={4}> <Typography variant="body1"> We know that this is a very difficult time for
      you and that you may require support beyond Settld. Please let us know if you would like to receive
      recommendations for any of the additional services listed below. We will follow up by email detailing a few
      options for the selected services. </Typography>

      <Container> <FormStack spacing={0}> <Entry label="Missing Asset Search" name="requestMissingAssetSearch" /> <Entry
        label="Selling the property"
        name="requestPropertyValuationService" /> <Entry
        label="Probate and Estate Admin"
        name="requestProbateSolicitor" /> <Entry
        label="Dealing with debt left behind"
        name="requestHelpDealingWithDebt" /> <Entry label="Arranging the funeral" name="requestFuneralDirector" />
        <Entry
          label="Accessing grief support"
          name="requestLocalGriefSupport" /> <Entry label="House clearance" name="requestHomeRemoval" /> <Entry
          label="Dealing with family conflicts"
          name="requestHelpDealingWithFamilyConflict"
        /> <Entry label="Probate bridging loans" name="requestProbateBridgingLoans" />
        <Entry label="Seeking financial guidance" name="requestFinancialGuidance" /> </FormStack> </Container>

          <FormStack>
            <FormField twoThirdsWidth>
              <Button variant="primary" size="large" disabled={busy} onClick={onContinue}>
                Submit &amp; go to Account dashboard
              </Button>
            </FormField>
          </FormStack>
        </Stack>
      </Stack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar> </SimpleTemplate>
  );
};


