import * as React from "react";

import { SimpleTemplate } from "@templates/SimpleTemplate";
import { Header } from "@atoms/Header";

export type OpenBankingDonePageProps = object;

export const OpenBankingDonePage: React.FC<OpenBankingDonePageProps> = () => {
  return (
    <SimpleTemplate headerType="logo">
      <Header level={1}>Settld Account Search</Header>

      <p>
        Thank you. We will process this data and get back to you shortly. You may now close this
        window.
      </p>
    </SimpleTemplate>
  );
};
