import { s3Upload } from "@api/caseApi";
import { createDocumentRequest } from "@api/formApi";
import { filenameFromFileAndPrefix } from "@utils/Functions";
import { UploadRecord } from "@src/types/Document";

export const createDocumentAndUpload = async (
  file: File,
  filename?: string,
  tags?: string[]
): Promise<UploadRecord> => {
  const fname = filenameFromFileAndPrefix(file, filename);

  let result;
  try {
    result = await createDocumentRequest({ filename: fname, tags: tags || [] });
  } catch {
    throw new Error("Error; please try again");
  }

  if (result.error) {
    throw result.error;
  }

  const { uploadUrl, document } = result.data || {};
  if (!uploadUrl || !document) {
    throw new Error("Error uploading file. Please try again.");
  }

  try {
    result = await s3Upload(uploadUrl, file);
  } catch {
    throw new Error("Error; lease try again.");
  }

  if (result.status !== 200) {
    console.warn(result);
    throw new Error("Error uploading file. Please try again.");
  }

  return { id: document.id, filename: fname };
};

// const upload = async (args: ServerRecord) => {
//   let result;

//   try {
//     result = await uploadRequest(args);
//   } catch (error) {
//     
//     throw new Error("Error; please try again");
//   }

//   if (result.error) {
//     switch (result.error) {
//       default:
//         
//         throw new Error("Unexpected error; please contact customer support");
//     }
//   }

//   
//   return result.data;
// };

// export const documentIdsFromUploadsRecord = (record?: UploadsRecord, tag?: string) => {
//   if (!record) {
//     return Promise.resolve([]);
//   }

//   return Promise.all(record.files)
//     .then((files) => {
//       const promises = files.map((file: File) =>
//         createDocument({
//           caseId,
//           signature,
//           filename: file.name,
//           tags: tag ? [tag] : undefined,
//         }).then((result) => ({
//           file,
//           result,
//         }))
//       );
//       return Promise.all(promises);
//     })
//     .then((filesAndResults) => {
//       const promises = filesAndResults.map(({ file, result }: any) => {
//         if (!result.data) {
//           return Promise.reject("Missing payload from document creation");
//         }
//         const { document, uploadUrl } = result.data;
//         return s3Upload(uploadUrl, file).then((result) => ({
//           documentId: document.id,
//           result,
//         }));
//       });
//       return Promise.all(promises);
//     })
//     .then((documentIdsAndResults) => {
//       return filterMap(documentIdsAndResults, ({ documentId, result }: any) => {
//         return result.status === 200 ? documentId : null;
//       });
//     })
//     .then((documentIds) => {
//       return record.currentIds.concat(documentIds);
//     });
// };
