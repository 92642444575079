import _uniqueId from "lodash/uniqueId";
import * as React from "react";

import { makeStyles } from "@mui/styles";

import { colors } from "@styles/constants";
import { AddOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

export type AddFileButtonProps = {
  onFileChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
};

export const AddFileButton: React.FC<AddFileButtonProps> = ({ onFileChange }) => {
  const classes = useStyles();
  const id = React.useMemo(() => _uniqueId("file-"), []);

  return (
    <span>
      <input type="file" className={classes.input} id={id} onChange={onFileChange} value="" />
      <label htmlFor={id} style={{ display: "block" }}>
        <Box className={classes.label} sx={{ display: "inline-block", paddingTop: "2px" }}>
          <AddOutlined fontSize="small" />
        </Box>
      </label>
    </span>
  );
};

const useStyles = makeStyles({
  input: {
    display: "none",
  },

  label: {
    backgroundColor: `${colors.continueButtonBackground}`,
    borderColor: `${colors.continueButtonBackground}`,
    borderRadius: "30px",
    borderStyle: "solid",
    borderWidth: "2px",
    color: `#ffffff`,
    cursor: "pointer",
    height: "30px",

    margin: "0",
    textAlign: "center",
    width: "30px",
    "&:hover": {
      backgroundColor: "#ffffff !important",
      borderColor: `${colors.continueButtonBackground} !important`,
      color: `${colors.continueButtonBackground} !important`,
    },
  },
});

export const PlusButton: React.FC<{ onClick: any }> = ({ onClick }) => {
  const classes = useStyles();
  const id = React.useMemo(() => _uniqueId("+"), []);

  return (
    <span>
      <input type="button" className={classes.input} id={id} onClick={onClick} value="" />
      <label htmlFor={id} style={{ display: "block" }}>
        <Box className={classes.label} sx={{ display: "inline-block", paddingTop: "2px" }}>
          <AddOutlined fontSize="small" />
        </Box>
      </label>
    </span>
  );
};
