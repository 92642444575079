import * as React from "react";
import { Stack, Typography } from "@mui/material";
import { colors } from "@styles/constants";
import { notifierRequiredFields, NotifierRoles, Person, PersonErrors, roleOptions } from "@customTypes/index";
import { BooleanInput } from "@atoms/BooleanInput";
import { DateInput } from "@atoms/DateInput";
import { EmailAddressInput } from "@src/components/atoms";
import { Header } from "@atoms/Header";
import { PhoneNumberTextInput } from "@src/components/atoms";
import { SelectInput } from "@atoms/SelectInput";
import { TextInput } from "@atoms/TextInput";
import { NewTitleInput } from "@atoms/TitleInput";
import { NewAddressInput } from "@molecules/AddressInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@src/components/molecules";
import { RelationToDeceased } from "@src/components/molecules";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { IDVerificationDialog } from "./IDVerificationDialog";
import { VulnerableInfoBox } from "@src/components/molecules/VulnerableInfoBox";
import { RepresentativeInfoBox } from "@src/components/atoms/RepresentativeInfoBox";
import { NokQuestion } from "@src/components/molecules/NokQuestion";
import { WillExistsQuestion } from "@src/components/molecules/WillExistsQuestion";
import { ServiceProvider } from "@src/types/ServiceProvider";
import { isAskWillAndNok } from "@src/utils/Intestacy";

const currentSection = "notifier";

export type NotifierDetailsViewProps = {
  serviceProvider: ServiceProvider;
  record: Person;
  errors: PersonErrors;
  onRoleChange: (value: string) => void;
  onDateOfBirthChange: any;
  onNext: () => void;
  onFieldChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onIsVulnerableChange: (value: boolean) => void;
  showIDVerificationDialog: boolean;
  hideIDVerificationDialog: () => void;
  onNokChange: any;
  caseData: any;
  setCaseData: (v: any) => void;
};

export const NotifierDetailsView: React.FC<NotifierDetailsViewProps> = ({
  serviceProvider,
  errors,
  record,
  onNokChange,
  onRoleChange,
  onDateOfBirthChange,
  onNext,
  onFieldChange,
  onSectionClick,
  onIsVulnerableChange,
  updateTemplate,
  showIDVerificationDialog,
  hideIDVerificationDialog,
  caseData,
  setCaseData,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy: false,
      currentSection,
      onNext,
      saveLabel: null,
      onSectionClick
    });
  }, [onNext, onSectionClick, updateTemplate]);
  const [askForWill, isAskForNok]: [boolean, boolean] =
    isAskWillAndNok(serviceProvider.intestacyFlow, record.role as NotifierRoles, caseData.record.willAvailable);
  // const isHideIntestacyInfoBox = [NotifierRoles.Administrator, NotifierRoles.DelegatedNotifier].includes(record.role
  // as NotifierRoles);
  return (
    <>
      <IDVerificationDialog
        visible={showIDVerificationDialog}
        hideDialog={hideIDVerificationDialog}
      />

      <Stack rowGap={4}>
        <Header level={1} color={colors.accentTeal}> Now, let's find out more about you </Header>

        <FormStack>
          <FormField
            halfWidthByItself
            label={
              <span className="required">
                I am a personal representative and I am...{" "}
                <InfoBoxTrigger white width="448px" content={<RepresentativeInfoBox />} />
              </span>
            }
          >
            <SelectInput
              name="role"
              onValueChange={onRoleChange}
              value={record.role || ""}
              options={roleOptions}
              error={errors?.role}
            />
          </FormField>

          <WillExistsQuestion
            caseData={caseData}
            setCaseData={setCaseData}
            serviceProvider={serviceProvider}
            askForWill={askForWill}
          />
          <NokQuestion
            errors={errors}
            record={record}
            onNokChange={onNokChange}
            askForNok={isAskForNok}
          />
          {(isAskForNok || askForWill) && <FormField>
            <Typography>Please provide your details below</Typography> </FormField>}

          <FormField halfWidthByItself label="Title" required={notifierRequiredFields.get("title")}>
            <NewTitleInput
              name="title"
              onValueChange={(value) => onFieldChange({ target: { name: "title", value } })}
              value={record.title || ""}
              error={errors?.title}
            />
          </FormField>

          <FormField halfWidth label="First name" required={notifierRequiredFields.get("firstName")}>
            <TextInput
              name="firstName"
              value={record.firstName || ""}
              error={errors?.firstName}
              onChange={onFieldChange}
            />
          </FormField>

          <FormField halfWidth label="Last name" required={notifierRequiredFields.get("lastName")}>
            <TextInput
              name="lastName"
              value={record.lastName || ""}
              error={errors?.lastName}
              onChange={onFieldChange}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormField halfWidthByItself label="Date of birth" required={notifierRequiredFields.get("dateOfBirth")}>
              <DateInput
                value={record.dateOfBirth || ""}
                onValueChange={onDateOfBirthChange}
                error={errors?.dateOfBirth}
                pastOnly
              />
            </FormField>
          )}

          <FormField label="Address" required={notifierRequiredFields.get("address")}>
            <NewAddressInput
              name="address"
              value={record.address || ""}
              onChange={onFieldChange}
              error={errors?.address}
            />
          </FormField>

          <FormField halfWidth label="City or Town" required={notifierRequiredFields.get("city")}>
            <TextInput
              name="city"
              value={record.city || ""}
              onChange={onFieldChange}
              error={errors?.city}
            />
          </FormField>

          <FormField halfWidth label="Postcode" required={notifierRequiredFields.get("postcode")}>
            <TextInput
              name="postcode"
              value={record.postcode || ""}
              onChange={onFieldChange}
              error={errors?.postcode}
            />
          </FormField>

          <FormField halfWidth label="Telephone number" required={notifierRequiredFields.get("contactNumber")}>
            <PhoneNumberTextInput
              name="contactNumber"
              value={record.contactNumber || ""}
              onChange={onFieldChange}
              error={errors?.contactNumber}
            />
          </FormField>

          <FormField halfWidth label="Email address" required={notifierRequiredFields.get("email")}>
            <EmailAddressInput
              name="email"
              value={record.email || ""}
              onChange={onFieldChange}
              error={errors?.email}// autoFocus={updatingNotifierEmailAddress}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormStack substack spacing={0}>
              <FormField>
                <BooleanInput
                  label={
                    <span>
                      I feel vulnerable (temporarily or long-term) and would appreciate support{" "}
                      <InfoBoxTrigger
                        white
                        width="448px"
                        content={VulnerableInfoBox()} />
                    </span>
                  }
                  value={record.isVulnerable}
                  onValueChange={onIsVulnerableChange}
                />
              </FormField>

              {serviceProvider.customForm === "portsmouth-water" && record.isVulnerable && (
                <FormField> To notify Portsmouth Water of vulnerability,{" "}<a
                  href="https://www.portsmouthwater.co.uk/customer-services/priority-services/"
                  rel="noreferrer"
                  target="_blank"
                >
                  click here
                </a>{" "} to sign up to their priority services register. </FormField>
              )}
            </FormStack>
          )}

          {record.role !== "solicitor" && (
            <FormField halfWidthByItself required={notifierRequiredFields.get("relationshipToDeceased")} label={<span>The person who died was my... &nbsp;</span>}>
              <br></br>
              <RelationToDeceased
                name="relationshipToDeceased"
                value={record.relationshipToDeceased || ""}
                otherValue={record.relationshipToDeceasedOther || ""}
                error={errors.relationshipToDeceased || errors.relationshipToDeceasedOther}
                onFieldChange={onFieldChange}
              /> </FormField>
          )}
        </FormStack>
      </Stack>
    </>
  );
};
