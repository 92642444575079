import { Stack, Typography } from "@mui/material";

export const VulnerableInfoBox = () => {
    return (
        <Stack rowGap={2}>
            <Typography variant="body1">
                <b>What help is available?</b>
            </Typography>

            <Typography variant="body1">
                After a bereavement, many people find they require support with mental or physical health,
                debts or managing money, and other sensitive matters. Banks, Insurers, Utilities and other
                organisations can help, but only if people ask for it.
            </Typography>
            <Typography variant="body1">
                If you select ‘I consider this person to be vulnerable’, Settld will alert all companies to follow up with them, to offer special care.
            </Typography>
        </Stack>
    );
};
