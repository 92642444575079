import { prettyTitle, Titles } from "@src/types";

export type Person = {
  readonly id: string;
  readonly title: Titles;
  readonly firstName: string;
  readonly lastName: string;
  readonly roles: string[];
  readonly bankAccountNumber?: string;
  readonly bankSortCode?: string;
};

export const fieldsFromPersons = (persons: ReadonlyArray<Person>, includeSomeoneElse: boolean) => {
  const fields = persons.map(({ id, title, firstName, lastName }) => {
    return { value: id, label: `${prettyTitle(title)} ${firstName} ${lastName}` };
  });

  if (!includeSomeoneElse) {
    return fields;
  }

  return fields.concat({
    value: "",
    label: "Someone else",
  });
};

export const defaultResponsible = (persons: ReadonlyArray<Person>) => {
  if (persons.length === 1) {
    return persons[0];
  }

  const responsiblePersons
    = persons.filter((person) => (person.roles || []).includes("responsible"));

  if (responsiblePersons
    .length === 1) {
    return responsiblePersons[0];
  }
};
