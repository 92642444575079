import * as React from "react";
import { ReactElement } from "react";
import { Alert, Snackbar, Stack } from "@mui/material";
import {
  DeceasedDetails as DeceasedDetailsType,
  NonExpressFormData,
  ServiceProviderType,
  Titles
} from "@customTypes/index";
import { cleanedErrors, emailErrors, validPhoneNumber } from "@utils/Functions";
import { ReactStateSetter } from "@utils/Types";

import { DateInput } from "@atoms/DateInput";
import { Header } from "@atoms/Header";
import { PhoneNumberTextInput } from "@atoms/PhoneNumberTextInput";
import { SelectInput } from "@atoms/SelectInput";
import { EmailAddressInput } from "@atoms/EmailAddressInput";
import { TextInput } from "@atoms/TextInput";
import { NewTitleInput } from "@atoms/TitleInput";
import { emailAddressInfoBox, phoneNumberInfoBox } from "@atoms/infoBox";

import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@molecules/InfoBoxTrigger";

import { Section } from "@src/Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import {
  Errors as PropertyErrors,
  errorsOfAddressOnly as errorsOfProperty,
  Property,
  PropertyFields,
  propertyFieldsFromRecordAddressOnly,
  Record as PropertyFieldRecord
} from "./AccountForm/PropertyFields";

import { updateDeceased } from "@api/caseApi";
import { maritalStatusOptions } from "@src/types/Case";

export type DeceasedRecord = {
  readonly title?: Titles;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly aliases?: string;
  readonly dateOfBirth?: string;
  readonly dateOfDeath?: string;
  readonly maritalStatus?: string;
  readonly contactNumber?: string;
  readonly email?: string;
  readonly idNumber?: string;
  readonly property?: PropertyFieldRecord;
};

export type DeceasedPersistedState = {
  record: DeceasedRecord;
  hasChanges: boolean;
  errors: Errors;
};

type Errors =
  | undefined
  | {
  title?: string;
  firstName?: string;
  lastName?: string;
  aliases?: string;
  dateOfBirth?: string;
  dateOfDeath?: string;
  maritalStatus?: string;
  contactNumber?: string;
  email?: string;
  idNumber?: string;
  property?: PropertyErrors;
};

export const deceasedPersistedStateFromForm = (form: NonExpressFormData): DeceasedPersistedState => {
  const { propertyId, ...deceased } = form.deceased || { propertyId: undefined };

  return {
    record: {
      ...deceased,
      property: propertyId ? { propertyId } : {}
    },
    hasChanges: false,
    errors: {}
  };
};

const updatedFormFromPersistedState = (
  form: NonExpressFormData,
  state: DeceasedPersistedState
): NonExpressFormData => {
  const { property, ...record } = deceasedRecordFromPersistedState(state);

  const deceased = {
    ...record,
    propertyId: property?.propertyId
  };

  return { ...form, deceased };
};

const deceasedRecordFromPersistedState = (state: DeceasedPersistedState) => {
  return { ...state.record };
};

const serverRecordFromPersistedState = (state: DeceasedPersistedState) => {
  return {
    title: state.record.title,
    firstName: state.record.firstName,
    lastName: state.record.lastName,
    aliases: state.record.aliases,
    dateOfBirth: state.record.dateOfBirth,
    dateOfDeath: state.record.dateOfDeath,
    maritalStatus: state.record.maritalStatus,
    emailAddress: state.record.email,
    phoneNumber: state.record.contactNumber,
    idNumber: state.record.idNumber,
    ...propertyFieldsFromRecordAddressOnly(state.record.property)
  };
};

export type DeceasedDetailsProps = {
  caseId: string;
  signature: string | null;
  prepareUpdate: (section: Section, form: NonExpressFormData) => NonExpressFormData;
  updateSuccessful: (form: NonExpressFormData, section: Section, justSave?: boolean) => void;
  updateFailure: (error: Error) => void;
  form: NonExpressFormData;
  properties: ReadonlyArray<Property>;
  busy: boolean;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  remoteError: string | undefined;
  persistedState: DeceasedPersistedState;
  setPersistedState: ReactStateSetter<DeceasedPersistedState>;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
  updateProperty: (property: Property) => void;
};

export const DeceasedDetails: React.FC<DeceasedDetailsProps> = ({
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  properties,
  busy,
  continueWithoutChanges,
  remoteError,
  persistedState,
  setPersistedState,
  updateTemplate,
  removeRemoteError,
  onSectionClick,
  updateProperty
}): ReactElement => {
  const { errors, record, hasChanges } = persistedState;

  // State setters.
  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
      setPersistedState({
        errors: {
          ...errors,
          [name]: undefined
        },
        hasChanges: true,
        record: {
          ...record,
          [name]: value ? value : undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  const onDateOfBirthChange = React.useCallback(
    (date: string | null) => {
      const name = "dateOfBirth";
      setPersistedState({
        errors: {
          ...errors,
          [name]: undefined
        },
        hasChanges: true,
        record: {
          ...record,
          [name]: date ? date : undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  const onDateOfDeathChange = React.useCallback(
    (date: string | null) => {
      const name = "dateOfDeath";
      setPersistedState({
        errors: {
          ...errors,
          [name]: undefined
        },
        hasChanges: true,
        record: {
          ...record,
          [name]: date ? date : undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  const updatePropertyRecord = React.useCallback(
    (value: any) => {
      if (typeof value === "function") {
        value = value(record.property);
      }

      const name = "property";
      setPersistedState({
        errors: {
          ...errors,
          [name]: undefined
        },
        hasChanges: true,
        record: {
          ...record,
          [name]: value ? value : undefined
        }
      });
    },
    [record, errors, setPersistedState]
  );

  // Submit callback.
  const onContinue = React.useCallback(
    (justSave?: boolean) => {
      const errors = errorsOf(record);

      if (errors) {
        setPersistedState({
          ...persistedState,
          errors
        });
        return;
      }

      if (!hasChanges) {
        continueWithoutChanges(Section.Deceased, justSave);
        return;
      }

      const futureForm = prepareUpdate(
        Section.Deceased,
        updatedFormFromPersistedState(form, persistedState)
      );
      updateDeceased({
        caseId,
        signature,
        record: serverRecordFromPersistedState(persistedState)
      }).then((result) => {
        if (result.newProperty) {
          updateProperty(result.newProperty);
          if(futureForm.deceased)
            futureForm.deceased.propertyId = result.newProperty.propertyId;
        }

        updateSuccessful(futureForm, Section.Deceased, justSave);
        setPersistedState({
          ...persistedState,
          record: {
            ...persistedState.record,
            property: {
              propertyId: persistedState.record.property?.propertyId || result.newProperty?.id
            }
          },

          hasChanges: false
        });
      }, updateFailure);
    },
    [
      caseId,
      form,
      hasChanges,
      persistedState,
      signature,
      updateFailure,
      updateSuccessful,
      continueWithoutChanges,
      prepareUpdate,
      setPersistedState,
      record,
      updateProperty
    ]
  );

  const fullDetails = persistedState.record;

  return (
    <DeceasedDetailsView
      properties={properties}
      busy={busy}
      askForNino={
        !!form.accounts?.some(
          (account) =>
            account.serviceProviderType === ServiceProviderType.Insurance ||
            account.serviceProviderType === ServiceProviderType.Investments ||
            account.serviceProviderType === ServiceProviderType.Pension
        )
      }
      errors={errors}
      hasChanges={hasChanges}
      record={fullDetails}
      remoteError={remoteError}
      removeRemoteError={removeRemoteError}
      onContinue={onContinue}
      onFieldChange={onFieldChange}
      onDateOfBirthChange={onDateOfBirthChange}
      onDateOfDeathChange={onDateOfDeathChange}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
      updateProperty={updatePropertyRecord}
    />
  );
};

export type DeceasedDetailsViewProps = {
  properties: ReadonlyArray<Property>;
  busy: boolean;
  askForNino: boolean;
  errors: Errors;
  hasChanges: boolean;
  record: DeceasedRecord;
  remoteError?: string;
  removeRemoteError: () => void;
  onContinue: (exit?: boolean) => void;
  onFieldChange: any;
  onDateOfBirthChange: any;
  onDateOfDeathChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  updateProperty: any;
};

export const DeceasedDetailsView: React.FC<DeceasedDetailsViewProps> = ({
  properties,
  busy,
  errors,
  hasChanges,
  record,
  remoteError,
  removeRemoteError,
  onContinue,
  onFieldChange,
  onDateOfBirthChange,
  onDateOfDeathChange,
  onSectionClick,
  updateTemplate,
  // askForNino,
  updateProperty
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: "deceased",
      onNext: () => onContinue(false),
      onSave: hasChanges ? () => onContinue(true) : undefined,
      onSectionClick
    });
  }, [busy, onContinue, onSectionClick, updateTemplate, hasChanges]);

  return (
    <Stack rowGap={4}> <Header level={1}>Next, could you tell us more about the person who died?</Header>

      <FormStack> <FormField halfWidthByItself label="Title"> <NewTitleInput
        name="title"
        onValueChange={(value) =>
          onFieldChange({
            target: { name: "title", value }
          })
        }
        value={record.title || ""}
        error={errors?.title}
      /> </FormField>

        <FormField halfWidth label="First name"> <TextInput
          name="firstName"
          value={record.firstName || ""}
          onChange={onFieldChange}
          error={errors?.firstName}
        /> </FormField>

        <FormField halfWidth label="Last name"> <TextInput
          name="lastName"
          value={record.lastName || ""}
          onChange={onFieldChange}
          error={errors?.lastName}
        /> </FormField>

        <FormField halfWidth label="Nicknames(s)"> <TextInput
          name="aliases"
          value={record.aliases || ""}
          onChange={onFieldChange}
          error={errors?.aliases}
        /> </FormField>

        <FormField halfWidth label="Marital status"> <SelectInput
          name="maritalStatus"
          value={record.maritalStatus || ""}
          onValueChange={(value) => onFieldChange({ target: { name: "maritalStatus", value } })}
          options={maritalStatusOptions}
          error={errors?.maritalStatus}
        /> </FormField>

        <FormField halfWidth label="Date of birth"> <DateInput
          value={record.dateOfBirth || ""}
          onValueChange={onDateOfBirthChange}
          error={errors?.dateOfBirth}
          pastOnly
        /> </FormField>

        <FormField halfWidth label="Date of death"> <DateInput
          value={record.dateOfDeath || ""}
          onValueChange={onDateOfDeathChange}
          error={errors?.dateOfDeath}
          pastOnly
        /> </FormField>

        <PropertyFields
          serviceProvider={{}}
          addressOnly
          label="Address"
          persons={[]}
          properties={properties}
          record={record.property || {}}
          errors={errors?.property}
          update={updateProperty}
        />

        <FormField
          halfWidth
          label={
            <span>
              Phone <InfoBoxTrigger white width="448px" content={phoneNumberInfoBox()} />
            </span>
          }
        > <PhoneNumberTextInput
          name="contactNumber"
          value={record.contactNumber || ""}
          onChange={onFieldChange}
          error={errors?.contactNumber}
        /> </FormField>

        <FormField
          halfWidth
          label={
            <span>
              Email <InfoBoxTrigger white width="448px" content={emailAddressInfoBox()} />
            </span>
          }
        > <EmailAddressInput
          name="email"
          value={record.email || ""}
          onChange={onFieldChange}
          error={errors?.email}
        /> </FormField>

        <FormField halfWidth label="National Insurance Number"> <TextInput
          name="idNumber"
          value={record.idNumber || ""}
          onChange={onFieldChange}
          error={errors?.idNumber}
        /> </FormField> </FormStack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar> </Stack>
  );
};



const errorsOf = (record: DeceasedRecord): Errors | undefined => {
  return cleanedErrors({
    title: record.title ? undefined : "required",
    firstName: record.firstName ? undefined : "required",
    lastName: record.lastName ? undefined : "required",
    dateOfBirth: record.dateOfBirth ? undefined : "required",
    dateOfDeath: record.dateOfDeath ? undefined : "required",
    maritalStatus: record.maritalStatus ? undefined : "required",
    contactNumber:
      !record.contactNumber || validPhoneNumber(record.contactNumber)
        ? undefined
        : "valid phone number required",
    email: emailErrors(record.email),
    idNumber: record.idNumber ? undefined : "required",
    property: errorsOfProperty(record.property)
  });
};

export const deceasedDetailsRequiredFields: ReadonlyArray<keyof DeceasedDetailsType> = [
  "title",
  "firstName",
  "lastName",
  "propertyId",
  "dateOfBirth",
  "dateOfDeath",
  "maritalStatus"
];

const validate = (form: NonExpressFormData): Errors | undefined => {
  let errors: Errors = {};

  if (form.deceased?.contactNumber && !validPhoneNumber(form.deceased?.contactNumber)) {
    errors.contactNumber = "Valid phone number required";
  }

  if (form.deceased?.email && emailErrors(form.deceased?.email)) {
    errors.email = "Valid email address required";
  }

  deceasedDetailsRequiredFields.forEach((key) => {
    if (!form.deceased || !form.deceased[key]) {
      errors = { ...errors, [key]: "required" };
    }
  });

  return Object.values(errors).length > 0 ? errors : undefined;
};

export const isComplete = (form: NonExpressFormData): boolean => validate(form) === undefined;
