export default function onLoadSetup() {
    /* eslint-disable-next-line no-undef */
    const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;
    /* eslint-disable-next-line no-undef */
    const REACT_APP_SETTLD_ENV = process.env.REACT_APP_SETTLD_ENV;
    /* eslint-disable-next-line no-undef */
    const REACT_APP_GA_ID = process.env.REACT_APP_GA_ID;

        const gaScript = document.createElement('script');
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_ID}`;
        document.head.appendChild(gaScript);
          try {
            window.settldTestModeEnabled = !!window.localStorage.getItem("settldTestModeEnabled");
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          } catch (_e) {
            window.settldTestModeEnabled = false;
          }
        
        if (!window.settldTestModeEnabled) {
            (function () {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                      /* eslint-disable-next-line no-undef */
                    dataLayer.push(arguments);
                }
        
                window.gtag = gtag;
                gtag("js", new Date());
                gtag("config", REACT_APP_GA_ID, {
                    cookie_domain: REACT_APP_DOMAIN,
                    linker: {
                        domains: [`www.${REACT_APP_DOMAIN}`, `help.${REACT_APP_DOMAIN}`],
                    },
                });
            })();
        }
        window.addEventListener("message", function (event) {
          if (event.origin !== "https://www.settld.care") {
              return;
          }

          if (event.data !== "settld:resizeIframeSignal") {
              return;
          }

          const parent = event.source;

          setInterval(function () {
              parent.postMessage(
                  {
                      type: "settld:scrollHeight",
                      scrollHeight: document.documentElement.scrollHeight,
                  },
                  "https://www.settld.care"
              );
          }, 1000);
      });
  
  
      if ((REACT_APP_SETTLD_ENV === "production"||REACT_APP_SETTLD_ENV === "staging") && !window.settldTestModeEnabled) {
        (function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 2157547, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      }

  }
  // export default function onLoadSetup() {
  //   // Access environment variables
  //   const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;
  //   const REACT_APP_SETTLD_ENV = process.env.REACT_APP_SETTLD_ENV;
  //   const REACT_APP_GA_ID = process.env.REACT_APP_GA_ID;
  
  //   // Initialize window.settldTestModeEnabled
  //   try {
  //     window.settldTestModeEnabled = !!window.localStorage.getItem('settldTestModeEnabled');
  //   } catch (e) {
  //     window.settldTestModeEnabled = false;
  //   }
  
  //   // **Google Analytics Setup**
  //   if (!window.settldTestModeEnabled) {
  //     // Load the Google Analytics script
  //     const gaScript = document.createElement('script');
  //     gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_ID}`;
    

  //     gaScript.onerror = () => {
  //       console.error('Failed to load Google Analytics script.');
  //     };
      
  //     if (!REACT_APP_GA_ID || !REACT_APP_DOMAIN) {
  //       console.warn('Missing GA_ID or DOMAIN environment variable.');
  //       return;
  //     }

  //     gaScript.onload = () => {
  //       console.log("onload")
  //       window.dataLayer = window.dataLayer || [];
  //       function gtag(...args: any[]) {
  //         window.dataLayer.push(args);
  //       }

  //       gtag('js', new Date());

  //       gtag('config', REACT_APP_GA_ID, {
  //         cookie_domain: REACT_APP_DOMAIN,
  //         linker: {
  //           domains: [`www.${REACT_APP_DOMAIN}`, `help.${REACT_APP_DOMAIN}`],
  //         },
  //       });
  //     };
  //     document.head.appendChild(gaScript);

  //   }
  //   // **Message Event Listener**
  //   const messageEventListener = (event:any) => {
  //     if (event.origin !== 'https://www.settld.care') {
  //       return;
  //     }
  //     if (event.data !== 'settld:resizeIframeSignal') {
  //       return;
  //     }
  //     const parent = event.source;
  //     setInterval(() => {
  //       parent.postMessage(
  //         {
  //           type: 'settld:scrollHeight',
  //           scrollHeight: document.documentElement.scrollHeight,
  //         },
  //         'https://www.settld.care'
  //       );
  //     }, 1000);
  //   };
  //   window.addEventListener('message', messageEventListener);
  
  //   // **Cookie Notice Handling**
  //   const showCookieNotice = () => {
  //     if (localStorage.getItem('__cookies_allowed__')) {
  //       return;
  //     }
  //     const agreeButton = document.getElementById('agree-with-cookies');
  //     const cookieNotice = document.getElementById('cookie-notice');
  
  //     if (agreeButton && cookieNotice) {
  //       agreeButton.addEventListener('click', () => {
  //         localStorage.setItem('__cookies_allowed__', 't');
  //         cookieNotice.style.display = 'none';
  //       });
  //       cookieNotice.style.display = 'block';
  //     }
  //   };
  //   window.addEventListener('load', showCookieNotice);
  
  //   // **Hotjar Setup**
  //   if (REACT_APP_SETTLD_ENV === 'production' && !window.settldTestModeEnabled) {
  //     (function (h: any, o: any, t: any, j: any,) {
  //       h.hj =
  //         h.hj ||
  //         function () {
  //           (h.hj.q = h.hj.q || []).push(arguments);
  //         };
  //       h._hjSettings = { hjid: 2157547, hjsv: 6 };
        
  //       const a = o.getElementsByTagName('head')[0] as HTMLHeadElement | null;
  //       const r = o.createElement('script') as HTMLScriptElement;
  //       if (!a) {
  //         throw new Error("Head element not found");
  //       }
        
  //       if (!r) {
  //         throw new Error("Script element could not be created");
  //       }
  //       r.async = true;
  //       r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  //       a.appendChild(r);
  //     })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  //   }
  

  // }