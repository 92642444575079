import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Document } from "@src/types";
import { ChatDocumentLink } from "./Chat/ChatDocumentLink";
import { Message } from "./Chat/message";

export const Files = ({
  documents,
}: {
  documents: ReadonlyArray<{ document: Document; message: Message }>;
}) => {
  const classes = useStyles();
  if (documents.length === 0) {
    return (
      <div>
        <h2>Attachments</h2>

        <p>
          <i>No documents available</i>
        </p>
      </div>
    );
  }

  return (
    <div>
      <h2>Attachments</h2>
      <div className={classes.messagingContainer}>
        {documents.map(({ document, message }) => {
          return (
            <p key={document.id}>
              <ChatDocumentLink key={document.id} document={document} message={message} />
            </p>
          );
        })}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  messagingContainer: {
    flexDirection: "column",
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
});
