import { NonExpressFormStep, } from "../Sections";
import { AccountDetails } from "./Account";
import { DocumentsDetails } from "./Document";
import { Person, NotifierDetails, DeceasedDetails, Executor, Nok } from "./Person";

export interface NonExpressFormData {
  readonly notifier?: NotifierDetails;
  readonly accounts?: ReadonlyArray<AccountDetails>;
  readonly deceased?: DeceasedDetails;
  readonly nok?: Nok;
  readonly executor?: Executor;
  readonly documents?: DocumentsDetails;
  readonly submittedSections: NonExpressFormStep;
  readonly kycPending: boolean;
  readonly submitted: boolean;
}

export enum YesNo {
  Yes = "YES",
  No = "NO",
}

export type FuneralDirectorFields = {
  readonly firstName?: string;
  readonly surname?: string;
  readonly company?: string;
  readonly address?: string;
  readonly contactNumber?: string;
  readonly email?: string;
  readonly sortCode?: string;
};

export type AdditionalHelpDetails = {
  requestMissingAssetSearch?: YesNo;
  requestProbateSolicitor?: YesNo;
  requestHomeRemoval?: YesNo;
  requestPropertyValuationService?: YesNo;
  requestProbateHouseInsurance?: YesNo;
  requestExecutorInsurance?: YesNo;
  requestFuneralFundingFinance?: YesNo;
  requestAdvanceLoan?: YesNo;
  // requestInheritanceFundingFinance?: YesNo;
  // readonly requestFinancialGuidance?: YesNo;
  // readonly requestFuneralDirector?: YesNo;
  // readonly requestHelpDealingWithDebt?: YesNo;
  // readonly requestHelpDealingWithFamilyConflict?: YesNo;
  // readonly requestLocalGriefSupport?: YesNo;
  // readonly requestProbateBridgingLoans?: YesNo;
};
// ui info, backend field
export const additionalServices: ReadonlyArray<[string, keyof AdditionalHelpDetails]> = [
  ["Receive your inheritance faster", "requestAdvanceLoan"],
  ["Check for missing assets", "requestMissingAssetSearch"],
  ["Probate and Estate Admin", "requestProbateSolicitor"],
  ["House clearance", "requestHomeRemoval"],
  ["Property valuation & sale", "requestPropertyValuationService"],
  ["Probate House Insurance", "requestProbateHouseInsurance"],
  ["Executor Insurance", "requestExecutorInsurance"],
  // ["Funeral Funding Finance", "requestFuneralFundingFinance"],
  // ["Funeral Funding Finance", "requestFuneralFundingFinance"],
  // ["Probate bridging loans", "requestProbateBridgingLoans"],
  // ["Arranging the funeral", "requestFuneralDirector"],
  // ["Dealing with family conflicts", "requestHelpDealingWithFamilyConflict"],
  // ["Dealing with debt left behind", "requestHelpDealingWithDebt"],
  // ["Accessing grief support", "requestLocalGriefSupport"],
  // ["Seeking financial guidance", "requestFinancialGuidance"],
];

// Helper functions and objects.
export const fullDetailsRequiredFields: ReadonlyArray<keyof Person> = [
  "firstName",
  "lastName",
  "address",
  "postcode",
  "contactNumber",
  "email",
  "title",
  "city",
];

export * from "./Person";
export * from "./ServiceProvider";