import * as React from "react";

import { Box, Stack } from "@mui/material";

import { colors } from "@styles/constants";
import { Document } from "@src/types";
import { AccountDetails, AccountType, YesNo } from "@src/types";
import { Person, Responsible } from "./Responsible";
import { FieldRow, FieldsTable } from "@molecules/FieldsTable";
import { Property, PropertySummary } from "./PropertySummary";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export type InsuranceAccountProps = {
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly account: AccountDetails;
  readonly documents: ReadonlyArray<Document>;
};

export const InsuranceAccount: React.FC<InsuranceAccountProps> = ({
  properties,
  persons,
  account: {
    propertyId,
    serviceProviderType,
    accountNumber,
    holderNumber,
    accountType,
    accountTypeOther,
    vehicleMake,
    vehicleModel,
    vehicleRegistrationNumber,
    jointClaim,
    nextSteps,
    responsibleId
  },
  documents
}) => {
  return (
    <FieldsTable> <FieldRow label="Policy number" value={accountNumber} />

      <FieldRow label="Customer reference" value={holderNumber} />

      <FieldRow label="Policy type" value={readableAccountType(accountType, accountTypeOther)} />

      {(accountType === AccountType.Vehicle || accountType === AccountType.BreakdownCover) && (
        <>
          <FieldRow label="Vehicle make" value={vehicleMake} /> <FieldRow label="Vehicle model" value={vehicleModel} />
          <FieldRow label="Vehicle registration" value={vehicleRegistrationNumber} />
        </>
      )}

      <FieldRow
        label="Property"
        value={<PropertySummary properties={properties} propertyId={propertyId} />}
      />

      <FieldRow label="Joint policy?" value={jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow label="Next step" value={readableAccountNextSteps(nextSteps, serviceProviderType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />

      {documents && documents.length > 0 && (
        <FieldRow
          label="Documents"
          value={
            <Stack>
              {documents.map((doc, index) => (
                <Box key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={doc.downloadUrl}
                    style={{ color: colors.primary }}
                  >
                    {doc.filename}
                  </a>
                </Box>
              ))}
            </Stack>
          }
        />
      )}
    </FieldsTable>
  );
};
