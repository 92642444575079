import * as React from "react";
import { Alert, Snackbar, Stack, Typography } from "@mui/material";
import { Executor, executorRequiredFields, NonExpressFormData, Person, Relationship, } from "@src/types";
import { filterProperties } from "@utils/Functions";
import { ReactStateSetter } from "@utils/Types";
import { BooleanInput } from "@atoms/BooleanInput";
import { DateInput } from "@atoms/DateInput";
import { EmailAddressInput } from "@src/components/atoms";
import { Header } from "@atoms/Header";
import { PhoneNumberTextInput } from "@src/components/atoms";
import { TextInput } from "@atoms/TextInput";
import { NewTitleInput } from "@atoms/TitleInput";
import { NewAddressInput } from "@molecules/AddressInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { InfoBoxTrigger } from "@src/components/molecules";
import { RelationToDeceased } from "@src/components/molecules";
import { Section } from "@src/Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { createExecutor, updateExecutor } from "@api/caseApi";
import { IntestacyInfoBox, VulnerableInfoBox } from "@src/components/molecules";
import { colors } from "@src/styles/constants";
import { NEContext } from "@src/store/NonExpressState";
import { executorErrors, ValidationError } from "@src/utils/Errors";

export type ExecutorDetailsPersistedState = {
  record: Executor;
  hasChanges: boolean;
  errors: Errors;
  remoteError?: string;
};

type Errors = Partial<Record<keyof Executor, string>>;

export const executorDetailsPersistedStateFromForm = (
  form: NonExpressFormData
): ExecutorDetailsPersistedState => {
  const record = filterProperties(form.executor || {}, [
    "title",
    "firstName",
    "lastName",
    "dateOfBirth",
    "address",
    "city",
    "postcode",
    "email",
    "contactNumber",
    "relationshipToDeceased",
    "relationshipToDeceasedOther",
    "roles",
    "isVulnerable",
    "id"
  ]);
  return {
    record: record as Executor,
    hasChanges: false,
    errors: {},
  };
};

const updatedFormFromPersistedState = (
  form: NonExpressFormData,
  state: ExecutorDetailsPersistedState
): NonExpressFormData => {
  const executor = ExecutorRecordFromPersistedState(state);

  return { ...form, executor };
};

const ExecutorRecordFromPersistedState = (state: ExecutorDetailsPersistedState) => {
  const { relationshipToDeceasedOther, ...rest } = state.record;

  return rest.relationshipToDeceased === Relationship.Other && relationshipToDeceasedOther
    ? state.record
    : rest;
};

const serverRecordFromPersistedState = (state: ExecutorDetailsPersistedState) => {
  const title = state.record.title?.toLowerCase();

  const relationshipToDeceased = state.record.relationshipToDeceased?.toLowerCase();

  return {
    id: state.record.id,
    title,
    firstName: state.record.firstName,
    lastName: state.record.lastName,
    dateOfBirth: state.record.dateOfBirth,
    address: state.record.address,
    city: state.record.city,
    postcode: state.record.postcode,
    email: state.record.email,
    contactNumber: state.record.contactNumber,
    relationshipToDeceased,
    relationshipToDeceasedOther: state.record.relationshipToDeceasedOther,
    isVulnerable: state.record.isVulnerable,
    roles: ["executor"],
  };
};

type ExecutorDetailsProps = {
  caseId: string;
  signature: string | null;
  prepareUpdate: (section: Section, form: NonExpressFormData) => NonExpressFormData;
  updateSuccessful: (form: NonExpressFormData, section: Section, justSave?: boolean) => void;
  updateFailure: (error: Error) => void;
  form: NonExpressFormData;
  busy: boolean;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  persistedState: ExecutorDetailsPersistedState;
  setPersistedState: ReactStateSetter<ExecutorDetailsPersistedState>;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  updatePerson: any,
  persistedStateSetters: any,
  persistedStates: any
};

export const ExecutorForm: React.FC<ExecutorDetailsProps> = ({
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  continueWithoutChanges,
  persistedState,
  setPersistedState,
  onSectionClick,
  updateTemplate, updatePerson,
  persistedStateSetters,
}) => {
  const { dispatch } = React.useContext(NEContext);
  const { record, hasChanges, errors } = persistedState;

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          [name]: value,
        },
        errors: {
          ...errors,
          [name]: undefined,
        },
      });
    },
    [record, errors, setPersistedState]
  );

  const onIsVulnerableChange = React.useCallback(
    (value) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          isVulnerable: value,
        },
        errors,
      });
    },
    [record, errors, setPersistedState]
  );

  const onDateOfBirthChange = React.useCallback(
    (value) => {
      setPersistedState({
        hasChanges: true,
        record: {
          ...record,
          dateOfBirth: value,
        },
        errors: {
          ...errors,
          dateOfBirth: undefined,
        },
      });
    },
    [record, errors, setPersistedState]
  );

  // Submit callback.
  const onContinue = React.useCallback(
    (justSave?: boolean, nextSection?: Section) => {
      let futureForm = updatedFormFromPersistedState(form, persistedState);
      const errors = executorErrors(futureForm.executor as Person);
      console.log(errors)
      if (errors) {
        setPersistedState({
          hasChanges,
          record,
          errors,
        });
        if (!nextSection) {
          return;
        }
      }

      setPersistedState((s) => ({ ...s, remoteError: undefined }));

      if (!hasChanges) {
        continueWithoutChanges(Section.Executor, justSave);
        return;
      }
      futureForm = prepareUpdate(Section.Executor, futureForm);
      const subMissionRecord = serverRecordFromPersistedState(persistedState);

      const updateOrCreate = subMissionRecord.id ? updateExecutor : createExecutor;

      updateOrCreate({
        caseId,
        signature,
        intestacy: true,
        record: serverRecordFromPersistedState(persistedState),
      }).then(
        (data) => {
          const person = data.executor;
          setPersistedState((s) => ({ ...s, record: { ...person } as Executor, hasChanges: true }));
          updateSuccessful({ ...futureForm, nok: { ...person } as Executor }, Section.Executor, justSave);
          updatePerson(person);
          dispatch({ type: "REFRESH" })
          persistedStateSetters.nok((s: any) => ({ ...s, record: {}, hasChanges: false }));
        },
        (error) => {
          if (error instanceof ValidationError) {
            setPersistedState((s) => ({
              ...s,
              errors: errorsFromServer(error),
              remoteError: undefined,
            }));
          } else {
            setPersistedState((s) => ({
              ...s,
              remoteError: "Operation failed. Please try again or contact customer support.",
            }));
          }
          updateFailure(error);
        }
      );
    },
    [
      caseId,
      form,
      hasChanges,
      record,
      signature,
      setPersistedState,
      updateFailure,
      updateSuccessful,
      prepareUpdate,
      continueWithoutChanges,
      persistedState,
    ]
  );

  const removeRemoteError = React.useCallback(() => {
    setPersistedState((s) => ({ ...s, remoteError: undefined }));
  }, [setPersistedState]);

  return (
    <ExecutorDetailsView
      busy={busy}
      errors={errors}
      hasChanges={hasChanges}
      remoteError={persistedState.remoteError}
      removeRemoteError={removeRemoteError}
      record={record}
      onContinue={onContinue}
      onDateOfBirthChange={onDateOfBirthChange}
      onFieldChange={onFieldChange}
      onIsVulnerableChange={onIsVulnerableChange}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
    />
  );
};

export type ExecutorDetailsViewProps = {
  busy: boolean;
  errors: Errors;
  hasChanges: boolean;
  record: Executor;
  remoteError?: string;
  removeRemoteError: () => void;
  onDateOfBirthChange: (value?: string) => void;
  onContinue: (exit?: boolean, any?: any) => void;
  onFieldChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onIsVulnerableChange: (value: boolean) => void;
};

export const ExecutorDetailsView: React.FC<ExecutorDetailsViewProps> = ({
  busy,
  errors,
  hasChanges,
  record,
  remoteError,
  removeRemoteError,
  onDateOfBirthChange,
  onContinue,
  onFieldChange,
  onSectionClick,
  updateTemplate,
  onIsVulnerableChange,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: Section.Executor,
      onNext: () => onContinue(false),
      onSave: hasChanges ? () => onContinue(true) : undefined,
      onSectionClick,
    });
  }, [busy, onContinue, onSectionClick, updateTemplate, hasChanges]);

  return (
    <Stack rowGap={4}>

      <Header level={1} color={colors.accentTeal}>
        Executor Information
      </Header>

      <Typography variant="body1">
        <b>Please provide details for an Executor of the Will.</b> When you submit this form, they
        will be sent an email asking them to sign a grant of authority. The grant of authority
        gives you (and Settld) permission to act on their behalf. We cannot proceed without it.
      </Typography>

      <FormStack>
        <FormField halfWidthByItself label="Title" required={executorRequiredFields.get("title")}>
          <NewTitleInput
            name="title"
            onValueChange={(value) => onFieldChange({ target: { name: "title", value } })}
            value={record.title || ""}
            error={errors?.title}
          />
        </FormField>

        <FormField halfWidth label="First name" required={executorRequiredFields.get("firstName")}>
          <TextInput
            name="firstName"
            value={record.firstName || ""}
            error={errors?.firstName}
            onChange={onFieldChange}
          />
        </FormField>

        <FormField halfWidth label="Last name" required={executorRequiredFields.get("lastName")}>
          <TextInput
            name="lastName"
            value={record.lastName || ""}
            error={errors?.lastName}
            onChange={onFieldChange}
          />
        </FormField>

        {record.role !== "solicitor" && (
          <FormField halfWidthByItself label="Date of birth" required={executorRequiredFields.get("dateOfBirth")}>
            <DateInput
              value={record.dateOfBirth || ""}
              onValueChange={onDateOfBirthChange}
              error={errors?.dateOfBirth}
              pastOnly
            />
          </FormField>
        )}

        <FormField label="Address" required={executorRequiredFields.get("address")}>
          <NewAddressInput
            name="address"
            value={record.address || ""}
            onChange={onFieldChange}
            error={errors?.address}
          />
        </FormField>

        <FormField halfWidth label="City or Town" required={executorRequiredFields.get("city")}>
          <TextInput
            name="city"
            value={record.city || ""}
            onChange={onFieldChange}
            error={errors?.city}
          />
        </FormField>

        <FormField halfWidth label="Postcode" required={executorRequiredFields.get("postcode")}>
          <TextInput
            name="postcode"
            value={record.postcode || ""}
            onChange={onFieldChange}
            error={errors?.postcode}
          />
        </FormField>

        <FormField halfWidth label="Telephone number" required={executorRequiredFields.get("contactNumber")}>
          <PhoneNumberTextInput
            name="contactNumber"
            value={record.contactNumber || ""}
            onChange={onFieldChange}
            error={errors?.contactNumber}
          />
        </FormField>

        <FormField halfWidth label="Email address" required={executorRequiredFields.get("email")}>
          <EmailAddressInput
            name="email"
            value={record.email || ""}
            onChange={onFieldChange}
            error={errors?.email}
          />
        </FormField>

        <FormField>
          <BooleanInput
            label={
              <span>
                I feel vulnerable (temporarily or long-term) and would appreciate support{" "}
                <InfoBoxTrigger white width="448px" content={VulnerableInfoBox()} />
              </span>
            }
            value={record.isVulnerable}
            onValueChange={onIsVulnerableChange}
          />
        </FormField>

        <FormField
          halfWidthByItself
          label={
            <span>The person who died was my/their... &nbsp; {IntestacyInfoBox({})}</span>
          }
          required={executorRequiredFields.get("relationshipToDeceased")}
        >
          <RelationToDeceased
            name="relationshipToDeceased"
            value={record.relationshipToDeceased || ""}
            otherValue={record.relationshipToDeceasedOther || ""}
            error={errors.relationshipToDeceased || errors.relationshipToDeceasedOther}
            onFieldChange={onFieldChange}
          />
        </FormField>
      </FormStack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const isExecutorFormComplete = (form: NonExpressFormData): boolean => executorErrors(form.executor as Person) === undefined;

const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    title: ve.errors.title ? ve.errors.title[0] : undefined,
    firstName: ve.errors.firstName ? ve.errors.firstName[0] : undefined,
    lastName: ve.errors.lastName ? ve.errors.lastName[0] : undefined,
    email: ve.errors.email ? ve.errors.email[0] : undefined,
    relationshipToDeceased: ve.errors.relationshipToDeceased ? ve.errors.relationshipToDeceased[0] : undefined,
    roles: ve.errors.roles ? ve.errors.roles[0] : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};
