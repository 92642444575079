import * as React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "@styles/constants";
import { Header } from "@atoms/Header";
import { Sponsors } from "@organisms/Sponsors";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { Stack } from "@mui/material";

type Props = object;

export const OpenBankingErrorPage: React.FC<Props> = () => {
  return <OpenBankingErrorPageView />;
};
export type OpenBankingErrorPageViewProps = object;

export const OpenBankingErrorPageView: React.FC<OpenBankingErrorPageViewProps> = () => {
  const classes = useStyles();

  return (
    <SimpleTemplate headerType="logo">
      <div className={classes.textContainer}>
        <div>
          <Header level={1}>Settld Account Search</Header>

          <Stack spacing={4}>
            <p>
              There was an unexpected error connecting to the bank. Please contact Settld's customer support
              at <a href="mailto:support@settld.care">support@settld.care</a>
            </p>

            <Sponsors /> </Stack>
        </div>
      </div>
    </SimpleTemplate>
  );
};

const useStyles = makeStyles({
  h1: {
    color: colors.black,
    fontSize: "2rem",
    marginBottom: "1rem",
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: 0
  },

  textContainer: {
    color: colors.gray,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1
  },

  formContainer: {
    maxWidth: "290px"
  },

  h3: {
    color: colors.veryDarkBlue,
    fontSize: "1.2rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
    marginTop: 0
  },

  rightContainer: {
    backgroundColor: colors.lightBlue,
    borderRadius: "5px",
    lineHeight: 2,
    padding: "20px"
  },

  additional: {
    marginBottom: "20px"
  },

  ul: {
    listStyle: "none",
    paddingLeft: 0,
    margin: 0
  },

  li: {
    "&::before": {
      backgroundColor: "#73a5c4",
      content: "\"\"",
      display: "inline-block",
      height: "10px",
      width: "10px",
      borderRadius: "10px",
      marginRight: "10px"
    }
  },

  sponsors: {
    paddingTop: "30px"
  },

  sponsorsLine: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },

  sponsor: {
    padding: "10px",
    textAlign: "center"
  },

  imgWidth: {
    width: "216px"
  },

  imgHeight: {
    height: "65px"
  }
});
