import * as React from "react";
import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material";
import { submitNonExpress } from "@api/caseApi";
import { Document, NonExpressFormData, prettyTitle, ServiceProvider } from "@src/types";
import { formatDate } from "@utils/Functions";
import { Header } from "@atoms/Header";
import { FieldRow, FieldsTable, FieldsTableHeader } from "@molecules/FieldsTable";
import { Section } from "@src/Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { Property } from "@src/types/property";
import { Person } from "../AccountForm/ResponsibleFields";
import { Accordion } from "@molecules/Accordion";
import { AccountDetails } from "./AccountDetails";
import { HR } from "@atoms/HR";
import { NEContext } from "@src/store/NonExpressState";
import { ExpressPersonSummary } from "@src/components/organisms";
import { MARITAL_STATUS_MAP, MaritalStatus } from "@src/types/Case";

export type SubmitProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly formCompletionState: Record<Section, boolean>;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly prepareUpdate: (section: Section, form: NonExpressFormData) => NonExpressFormData;
  readonly updateSuccessful: (form: NonExpressFormData, section: Section) => void;
  readonly updateFailure: (error: Error) => void;
  readonly form: NonExpressFormData;
  readonly busy: boolean;
  readonly continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  readonly remoteError: string | undefined;
  readonly changeNotifierEmailAddress: () => void;
  readonly onSectionClick: (section: string) => void;
  readonly updateTemplate: UpdateFormTemplateProc;
  readonly removeRemoteError: () => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
};

export const Submit: React.FunctionComponent<SubmitProps> = ({
  caseId,
  signature,
  formCompletionState,
  serviceProviders,
  persons,
  properties,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  remoteError,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
  uploadedFileInfo
}) => {
  const { state: { bypassKYC } } = React.useContext(NEContext);

  const [{ busy, error }, setState] = React.useState({
    busy: false,
    error: undefined
  } as { busy: boolean; error?: string });

  const onSubmit = React.useCallback(() => {
    if (!isFormSubmittable(formCompletionState, bypassKYC)) {
      setState((s) => ({
        ...s,
        error: "Please verify that all steps on the menu are valid before submitting."
      }));
      return;
    }

    let futureForm: NonExpressFormData = form;

    futureForm = prepareUpdate(Section.Submit, futureForm);
    setState({ busy: true, error: undefined });

    submitNonExpress({ caseId, signature, form: futureForm }).then(
      () => {
        setState((s) => ({ ...s, busy: false }));
        updateSuccessful(futureForm, Section.Submit);
      },
      (e) => {
        setState({ busy: false, error: `${e.message}` });
        updateFailure(e);
      }
    );
  }, [
    caseId,
    form,
    prepareUpdate,
    signature,
    updateFailure,
    updateSuccessful,
    formCompletionState
  ]);

  const removeErrors = React.useCallback(() => {
    removeRemoteError();
    setState({ busy: false });
  }, [removeRemoteError, setState]);

  const onEditNotifier = React.useCallback(() => {
    onSectionClick(Section.Notifier);
  }, [onSectionClick]);

  const onEditDeceased = React.useCallback(() => {
    onSectionClick(Section.Deceased);
  }, [onSectionClick]);

  const onEditAccounts = React.useCallback(() => {
    onSectionClick(Section.Accounts);
  }, [onSectionClick]);

  return (
    <SubmitView
      form={form}
      formCompletionState={formCompletionState}
      error={error}
      busy={busy}
      serviceProviders={serviceProviders || []}
      properties={properties}
      persons={persons}
      remoteError={remoteError}
      onSubmit={onSubmit}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
      removeRemoteError={removeErrors}
      onEditNotifier={onEditNotifier}
      onEditDeceased={onEditDeceased}
      onEditAccounts={onEditAccounts}
      uploadedFileInfo={uploadedFileInfo}
    />
  );
};

export type SubmitViewProps = {
  readonly form: NonExpressFormData;
  readonly formCompletionState: Record<Section, boolean>;
  readonly error?: string;
  readonly remoteError?: string;
  readonly busy: boolean;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;

  readonly updateTemplate: UpdateFormTemplateProc;
  readonly removeRemoteError: () => void;
  readonly onSubmit: () => void;
  readonly onSectionClick: (section: string) => void;
  readonly onEditNotifier: () => void;
  readonly onEditDeceased: () => void;
  readonly onEditAccounts: () => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
};

export const SubmitView: React.FC<SubmitViewProps> = ({
  form,
  formCompletionState,
  error,
  remoteError,
  busy,
  serviceProviders,
  persons,
  properties,
  onSubmit,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
  onEditNotifier,
  onEditDeceased,
  onEditAccounts,
  uploadedFileInfo
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: "submit",
      nextLabel: "Submit case",
      onNext: onSubmit,
      onSectionClick
    });
  }, [busy, onSubmit, onSectionClick, updateTemplate]);

  return (
    <Stack rowGap={4}> <Stack rowGap={2}> <Header level={1}>Finally, let’s review and submit your case.</Header>

      <Typography variant="body1"> The information you have provided will be used to notify the companies you selected.
        Please review the information below before submitting your case. </Typography> </Stack>
      <HR /> {form.notifier && formCompletionState[Section.Notifier] && (
        <FieldsTable header={<FieldsTableHeader title="Your details" onEdit={onEditNotifier} />}> <FieldRow
          label="Title"
          value={form.notifier.title ? prettyTitle(form.notifier.title) : ""}
        /> <FieldRow label="First name" value={form.notifier.firstName || ""} /> <FieldRow
          label="Last name"
          value={form.notifier.lastName || ""} /> <FieldRow
          label="Address"
          value={`${form.notifier.address}, ${form.notifier.city}, ${form.notifier.postcode}`}
        /> <FieldRow label="Phone" value={form.notifier.contactNumber || ""} /> <FieldRow
          label="Email"
          value={form.notifier.email || ""} /> </FieldsTable>
      )} {form.deceased && formCompletionState[Section.Deceased] && (
        <Box> <FieldsTable
          header={
            <FieldsTableHeader title="Details of the person who died" onEdit={onEditDeceased} />
          }
        > <FieldRow
          label="Title"
          value={form.deceased.title ? prettyTitle(form.deceased.title) : ""}
        /> <FieldRow label="First name" value={form.deceased.firstName || ""} /> <FieldRow
          label="Last name"
          value={form.deceased.lastName || ""} /> <FieldRow
          label="Address"
          value={propertyDetails(form.deceased.propertyId, properties)}
        /> <FieldRow label="Phone" value={form.deceased.contactNumber || ""} /> <FieldRow
          label="Email"
          value={form.deceased.email || ""} /> <FieldRow
          label="Date of birth"
          value={form.deceased.dateOfBirth ? formatDate(form.deceased.dateOfBirth) : ""}
        /> <FieldRow
          label="Date of death"
          value={form.deceased.dateOfDeath ? formatDate(form.deceased.dateOfDeath) : ""}
        /> <FieldRow
          label="Marital status"
          value={form?.deceased?.maritalStatus ? MARITAL_STATUS_MAP[form.deceased.maritalStatus as MaritalStatus] : "Unknown"}
          /> </FieldsTable> </Box>
      )}

      {!!form.nok && <ExpressPersonSummary person={form.nok} />} {!!form.executor &&
        <ExpressPersonSummary person={form.executor} />}

      {form.accounts && formCompletionState[Section.Accounts] && (
        <Stack rowGap={2}> <FieldsTableHeader
          title={`Accounts (${getAccountCount(form.accounts)})`}
          onEdit={onEditAccounts}
        />

          {form.accounts.map((account, index) =>
            account.additionalServicesAccount ||
            account.serviceProviderType === "affiliate_partner" ? null : (
              <Accordion key={index} title={account.companyName}> <AccountDetails
                serviceProviders={serviceProviders || []}
                persons={persons}
                properties={properties}
                account={account}
                uploadedFileInfo={uploadedFileInfo}
              /> </Accordion>
            )
          )}
        </Stack>
      )}

      <Typography variant="body1"> <b> By clicking ‘Submit Case’ below, the details you have submitted will be reviewed
        by a member of our team. You will be able to update any of the information provided, including adding additional
        accounts, at a later stage. </b> </Typography>
      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError || !!error}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError || error}
        </Alert>
      </Snackbar> </Stack>
  );
};

const propertyDetails = (
  propertyId: string | undefined,
  properties: ReadonlyArray<Property>
): string => {
  if (!propertyId) {
    return "";
  }

  const property = properties.find((p) => p.id === propertyId);

  if (!property) {
    return "";
  }

  return `${property.address}, ${property.city}, ${property.postcode}`;
};

const isFormSubmittable = (formCompletionState: Record<Section, boolean>, bypassKYC: boolean) => {
  return (
    formCompletionState[Section.Accounts] &&
    formCompletionState[Section.Documents] &&
    formCompletionState[Section.Notifier] &&
    formCompletionState[Section.Deceased] &&
    (formCompletionState[Section.KYC] || bypassKYC)
  );
};

export const capitalizeFirst = (s: string) => {
  if (!s) {
    return s;
  }

  return `${s[0].toUpperCase()}${s.substring(1)}`;
};

const getAccountCount = (accounts: any) => {
  let count = 0;
  accounts.forEach((account: any) => {
    if (
      account.additionalServicesAccount === false ||
      account.serviceProviderType.localeCompare("affiliate_partner") != 0
    ) {
      count++;
    }
  });
  return count;
};
