import * as React from "react";

import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import eenImage from "@assets/images/een.svg";
import iukImage from "@assets/images/iuk.png";
import wbdImage from "@assets/images/wbd.svg";

export type SponsorsProps = object;

export const Sponsors: React.FC<SponsorsProps> = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack spacing={2} alignItems={matches ? "center" : "flex-start"}>
      <div>Supported by</div>

      <Stack direction={matches ? "column" : "row"} spacing={4}>
        <img className={classes.imgWidth} alt="Innovate UK" src={iukImage} />

        <img className={classes.imgWidth} alt="Womble Bond Dickinson" src={wbdImage} />

        <img className={classes.imgHeight} alt="Enterprise Europe Network" src={eenImage} />
      </Stack>
    </Stack>
  );
};

const useStyles = makeStyles({
  imgWidth: {
    width: "140px",
  },

  imgHeight: {
    height: "65px",
  },
});
