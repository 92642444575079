import * as React from "react";
import { Alert, Snackbar, Stack } from "@mui/material";
import { Document, ServiceProvider } from "@src/types";
import { HR } from "@atoms/HR";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { getExpressData, getExpressSectionsData, setExpressSubmitted } from "../express-data";
import { submitExpressRecord } from "@api/formApi";
import { SPContext } from "@src/store/SPProvider";
import { ExpressUiInfoContext } from "@src/store/ExpressUiInfo";
import { ExpressAccountSummary, ExpressPersonSummary, SubmissionHeader } from "@organisms/index";
import { useScrollToTop } from "@src/hooks/useScrollToTop";

export type SubmitProps = {
  readonly serviceProvider: ServiceProvider;
  readonly onSectionClick: (section: string) => void;
  readonly updateTemplate: UpdateFormTemplateProc;
  uploadedFileInfo: (id: string) => Promise<Document>;
  next: () => void;
};
interface FetchState {
  busy: boolean;
  error?: string;
}

export const Submit: React.FunctionComponent<SubmitProps> = ({
  serviceProvider,
  onSectionClick,
  updateTemplate,
  uploadedFileInfo,
  next,
}) => {
  useScrollToTop();

  const [{ account, notifier }, setLocalState] = React.useState({
    account: undefined,
    notifier: undefined,
  });
  const {state: spState} = React.useContext(SPContext);
  const [{ busy, error }, setState] = React.useState<FetchState>({ busy: false, error: undefined });
  const { state: uiInfo } = React.useContext(ExpressUiInfoContext);

  React.useEffect(() => {
    const data = getExpressSectionsData();
    if (!data) return;
    const account = data.account?.target;
    const notifier = data.notifier?.target;
    setLocalState({ account, notifier });
  }, []);

  const onSubmit = React.useCallback(() => {
    const slug = getExpressData()?.slug;
    const data = getExpressSectionsData();

    const account = data?.account?.target;
    const documents = data?.documents?.target;
    const notifier = data?.notifier?.target;
    const kyc = data?.kyc?.target;
    let nok, executor, caseInformation;
    if (spState.intestacyFlow) {
      if (uiInfo.collectExecutorDetails) executor = data.executor.target;
      if (uiInfo.collectNokDetails) nok = data.nok.target;
      caseInformation = { willExists: uiInfo.willExists };
    }
    const isAllSteps = !!(
      !slug ||
      !account ||
      !notifier ||
      (serviceProvider.deathCertificateNeeded && !documents) ||
      (serviceProvider.idDocumentNeeded && !kyc)
    );

    if (isAllSteps) {
      setState({
        busy: false,
        error:
          "Please verify that all steps are valid (have a check on the menu) before submitting.",
      });
      return;
    }

    if (spState.intestacyFlow && !executor && uiInfo.collectExecutorDetails) {
      setState({
        busy: false,
        error:
          "Please verify that all steps(Executor) are valid (have a check on the menu) before submitting.",
      });
      return;
    }

    if (spState.intestacyFlow && !nok && uiInfo.collectNokDetails) {
      setState({
        busy: false,
        error:
          "Please verify that all steps(Nok information) are valid (have a check on the menu) before submitting.",
      });
      return;
    }

    setState({ busy: true });

    if (account?.property?.occupier?.id === "notifier") {
      account.property.occupier.notifier = true;
      delete account.property.occupier.id;
    }

    if (account?.responsible?.id === "notifier") {
      account.responsible.notifier = true;
      delete account.responsible.id;
    }
    const payload = {
      data: {
        slug,
        account,
        documents: documents || {},
        notifier,
        kyc: kyc || {},
        executor: executor,
        nok: nok,
        case: caseInformation,
      },
    };
    submitExpressRecord(payload).then(
      ({ formUrl }) => {
        setState({ busy: false });
        setExpressSubmitted(formUrl);
        next();
      },
      (error) => {
        if (error.message === "email_exists") {
          setState({
            busy: false,
            error:
              "This email already exists, please sign in",
          });
          return;
        }
        setState({
          busy: false,
          error: `Unexpected failure. Please try again or contact customer support. with error message${error.toString()}`,
        });
      }
    );
  }, [next]);

  return (
    <SubmitView
      serviceProvider={serviceProvider}
      account={account}
      notifier={notifier}
      error={error}
      busy={busy}
      onSubmit={onSubmit}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
      uploadedFileInfo={uploadedFileInfo}
      removeRemoteError={() => { }}
    />
  );
};

export type SubmitViewProps = {
  readonly serviceProvider: ServiceProvider;
  readonly account: any;
  readonly notifier: any;
  readonly error?: string;
  readonly remoteError?: string;
  readonly busy: boolean;
  readonly updateTemplate: UpdateFormTemplateProc;
  readonly removeRemoteError: () => void;
  readonly onSubmit: () => void;
  readonly onSectionClick: (section: string) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
};

export const SubmitView: React.FC<SubmitViewProps> = ({
  serviceProvider,
  account,
  notifier,
  error,
  remoteError,
  busy,
  onSubmit,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: "submit",
      nextLabel: "Submit",
      saveLabel: null,
      onNext: onSubmit,
      onSectionClick,
    });
  }, [busy, onSubmit, onSectionClick, updateTemplate]);
  const data = getExpressSectionsData();
  let nok, executor;
  if (serviceProvider.intestacyFlow) {
    executor = data?.executor?.target;
    nok = data?.nok?.target;
  }

  return (
    <Stack rowGap={4}>
      <SubmissionHeader sp={serviceProvider} />
      <HR />
      <ExpressAccountSummary sp={serviceProvider} account={account} />
      <HR />
      <ExpressPersonSummary person={notifier} />
      {!!nok && <ExpressPersonSummary person={nok} />}
      {!!executor && <ExpressPersonSummary person={executor} />}
      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError || !!error}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError || error}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
