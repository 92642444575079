import * as React from "react";

import { Alert, Snackbar, Typography } from "@mui/material";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, AccountNextStep, ServiceProvider, ServiceProviderType, YesNo } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { useOnChange, useUpdater } from "@utils/callbacks";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields
} from "./ResponsibleFields";
import { BooleanInput } from "@atoms/BooleanInput";
import {
  DeceasedDetailsFields,
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord
} from "./DeceasedDetailsFields";
import { AccountFieldProps } from "./AccountForm";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly accountNumber?: string;
  readonly jointClaim?: string;
  readonly accountRequest?: AccountNextStep;
  readonly responsible?: ResponsibleRecord;
};

export type Errors =
  | undefined
  | {
  readonly deceased?: DeceasedErrors;
  readonly accountNumber?: string;
  readonly responsible?: ResponsibleErrors;
};

export const errorsOf: (r: Record, askForDeceasedDetails: boolean) => Errors = (
  record,
  askForDeceasedDetails
) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    responsible: errorsOfResponsible(record?.responsible, record?.accountRequest)
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
      accountRequest: AccountNextStep.Cancel,
      responsible: responsibleRecordFromAccount(account)
    };
  }

  return {
    deceased: {},
    accountNumber: account.accountNumber,
    jointClaim: account.jointClaim,
    accountRequest:
      account.jointClaim === YesNo.Yes
        ? AccountNextStep.UpdateAccountHolder
        : AccountNextStep.Cancel,
    responsible: responsibleRecordFromAccount(account)
  };
};

export type CreditCardsAccountFieldsProps = AccountFieldProps;

export const CreditCardsAccountFields: React.FC<CreditCardsAccountFieldsProps> = ({
  persons,
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateAccountHolder = useUpdater(update, "responsible");
  const updateDeceasedFields = useUpdater(update, "deceased");

  const onChange = useOnChange(update);

  const onJointClaimValueChanged = React.useCallback(
    (jointClaim) => {
      update((s: any) => ({
        ...s,
        jointClaim: jointClaim ? YesNo.Yes : YesNo.No,
        accountRequest: jointClaim ? AccountNextStep.UpdateAccountHolder : AccountNextStep.Cancel
      }));
    },
    [update]
  );

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      onNext: !serviceProvider?.serviceProviderType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const responsible = {
            id: record.responsible?.responsibleId || undefined,
            details: record.responsible?.responsible || undefined,
            bankAccount: record.responsible?.responsibleBankAccount || undefined
          };

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              serviceProviderType: serviceProvider.serviceProviderType
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            accountNumber: record.accountNumber,
            jointClaim: record.jointClaim,
            nextStep: record.accountRequest,
            responsible
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);
          return saveAccount({
            sector: ServiceProviderType.CreditCards,
            record: data
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        }
    });
  }, [updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy, saveAccount]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField halfWidthByItself label="Credit card number"> <TextInput
        name="accountNumber"
        value={record.accountNumber || ""}
        onChange={onChange}
        error={errors?.accountNumber}
      /> </FormField>

      <FormField> <BooleanInput
        label="This is a joint account"
        value={record.jointClaim === YesNo.Yes}
        onValueChange={onJointClaimValueChanged}
      /> </FormField>

      <FormField> <Typography variant="body1">
        {record.accountRequest === AccountNextStep.UpdateAccountHolder
          ? "We will notify and update the card holder details."
          : "We will obtain Date of Death Balances and request for account closure."}
      </Typography> </FormField>

      {record.responsible && (
        <ResponsibleFields
          serviceProvider={serviceProvider}
          persons={persons}
          nextStep={record.accountRequest}
          record={record.responsible}
          errors={errors?.responsible}
          update={updateAccountHolder}
        />
      )}

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};
