import * as React from "react";

import { Box, Grid, SxProps } from "@mui/material";
import { Label } from "../atoms/Label";

export type FormFieldProps = {
  sx?: SxProps;
  halfWidth?: boolean;
  halfWidthByItself?: boolean;
  slightlyMoreThanHalfWidth?: boolean;
  twoThirdsWidth?: boolean;
  twoThirdsWidthByItself?: boolean;
  label?: React.ReactNode;
  children?: React.ReactNode;
  required?: boolean;
};

export const FormField: React.FC<FormFieldProps> = ({
  sx,
  halfWidth,
  halfWidthByItself,
  twoThirdsWidth,
  twoThirdsWidthByItself,
  slightlyMoreThanHalfWidth,
  label,
  children,
  required =false
}) => {
  const width =
    twoThirdsWidthByItself || twoThirdsWidth
      ? 9
      : slightlyMoreThanHalfWidth
      ? 7
      : halfWidthByItself || halfWidth
      ? 6
      : 12;
  const addSibling = twoThirdsWidthByItself || slightlyMoreThanHalfWidth || halfWidthByItself;
  const siblingWidth = twoThirdsWidthByItself ? 3 : halfWidthByItself ? 6 : 0;

  if (addSibling) {
    return (
      <>
        <Grid item xs={12} sm={width}>
          {label && <Label className={required ? "required" : undefined}>{label}</Label>}
          <Box sx={sx}>{children}</Box>
        </Grid>
        <Grid item xs={0} sm={siblingWidth} sx={{ marginTop: "-16px", ...sx }} />
      </>
    );
  }

  return (
    <Grid item xs={12} sm={width}>
      {label && <Label className={required ? "required" : undefined}>{label}</Label>}
      <Box sx={sx}>{children}</Box>
    </Grid>
  );
};
