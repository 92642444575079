import * as React from "react";

import { Box, Stack } from "@mui/material";

import { colors } from "@styles/constants";
import { AccountDetails, Document, MeterAccess } from "@src/types";
import { formatDate } from "@utils/Functions";
import { Property } from "@src/types/property";
import { PropertySummary } from "./PropertySummary";
import { Person, Responsible } from "./Responsible";
import { FieldRow, FieldsTable } from "@molecules/FieldsTable";
import { NotAvailable } from "@pages/Dashboard/RequestDetails/NotAvailable";
import { readableAccountNextSteps } from "@src/utils/accounts";

export type WaterAccountProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly account: AccountDetails;
  readonly documents: ReadonlyArray<Document>;
};

export const WaterAccount: React.FC<WaterAccountProps> = ({
  persons,
  properties,
  account: {
    serviceProviderType,
    accountNumber,
    meterAccess,
    lastMeterReading,
    lastMeterReadingDate,
    nextSteps,
    responsibleId,
    propertyId
  },
  documents
}) => {
  return (
    <FieldsTable> <FieldRow label="Account number" value={accountNumber || <NotAvailable />} />

      <FieldRow
        label="Property"
        value={<PropertySummary properties={properties} propertyId={propertyId} />}
      />

      <FieldRow label="Next Step" value={readableAccountNextSteps(nextSteps, serviceProviderType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />

      {meterAccess === MeterAccess.Yes && (
        <>
          <FieldRow label="Last meter reading" value={lastMeterReading || <NotAvailable />} />

          <FieldRow
            label="Date of the readings"
            value={lastMeterReadingDate && formatDate(lastMeterReadingDate)}
          />
        </>
      )}

      {documents && documents.length > 0 && (
        <FieldRow
          label="Documents"
          value={
            <Stack>
              {documents.map((doc, index) => (
                <Box key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={doc.downloadUrl}
                    style={{ color: colors.primary }}
                  >
                    {doc.filename}
                  </a>
                </Box>
              ))}
            </Stack>
          }
        />
      )}
    </FieldsTable>
  );
};
