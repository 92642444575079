import * as React from "react";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { createDocumentAndUpload, getDocument } from "@api/caseApi";
import { Case } from "@src/types/Case";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { NotifierProfile } from "@src/types/Notifier";
import { Executor, Nok, NonExpressFormData, NotifierRolesByServer, ServiceProvider } from "@src/types";
import urlPaths from "../../urlPaths";

// Import Components
import { nextSection, pathForSection, Section } from "@src/Sections";
import { NotFoundPage } from "../Error/NotFoundPage";
import { LoadingPage } from "../LoadingPage";
import { Property } from "@src/types/property";
import { Person } from "./AccountForm/ResponsibleFields";
import { DeceasedPersistedState, deceasedPersistedStateFromForm } from "./DeceasedDetails";
import { DocumentsPersistedState, documentsPersistedStateFromForm } from "./Documents";
import { NotifierDetailsPersistedState, notifierDetailsPersistedStateFromForm } from "./NotifierDetails";

import { NokDetailsPersistedState, nokDetailsPersistedStateFromForm } from "./NokForm";

import { ExecutorDetailsPersistedState, executorDetailsPersistedStateFromForm } from "./ExecutorForm";

import {
  getCase,
  getFormCompletionState,
  NEFormMenuEntries,
  notifierEmailAddressVerified,
  onCaseLoadUI
} from "./formHelpers";
import { NEContext } from "@src/store/NonExpressState";
import {
  accountsComponent,
  deceasedComponent,
  documentComponent,
  executorComponent,
  kycComponent,
  nokComponent,
  notifierComponent,
  otherComponent,
  submitComponent
} from "./ne_components";

type NEFormState = {
  doesNotExist: boolean;
  error: boolean;
  loading: boolean;
  serviceProviders: Array<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  form: NonExpressFormData;
  notifier: NotifierProfile | null;
  caseInfo: null | (Case & { accounts: Array<ExtendedAccount> });
  notifierEmailAddressVerified: boolean;
  source: "express_form" | null;
  nok: Nok | null;
  executor: Executor | null;
};

type PersistedState = {
  notifier: NotifierDetailsPersistedState;
  deceased: DeceasedPersistedState;
  documents: DocumentsPersistedState;
  nok: NokDetailsPersistedState,
  executor: ExecutorDetailsPersistedState;
};

type PersistedStateProperties =
  | object
  | NotifierDetailsPersistedState
  | DeceasedPersistedState
  | DocumentsPersistedState
  | NokDetailsPersistedState
  | ExecutorDetailsPersistedState

export const NonExpressFormPage: React.FC<{ signature: string | null; caseId: string }> = ({
  signature,
  caseId
}) => {
  const navigate = useNavigate();
  const { state: uiState, dispatch } = React.useContext(NEContext);

  const [state, setState] = React.useState({
    doesNotExist: false,
    error: false,
    missing: false,
    serviceProviders: [],
    persons: [],
    properties: [],
    form: { submittedSections: {}, kycPending: true, submitted: false },
    loading: true,
    notifier: null,
    nok: null,
    executor: null,
    caseInfo: null,
    notifierEmailAddressVerified: true,
    source: null
  } as NEFormState);

  React.useEffect(() => {
    getCase({ caseId, signature })
      .then((data) => {
        console.log(data);
        postFetchCase(data, navigate, setState, dispatch);
      })
      .catch((err) => {
        setState((s) => ({ ...s, error: true }));
        console.warn({ err });
      });
  }, [navigate, caseId, signature, uiState.refresh]);

  if (state.loading) return <LoadingPage />;
  if (state.doesNotExist) return <NotFoundPage />;

  return (
    <ActualNonExpressFormPage
      signature={signature}
      caseId={caseId}
      state={state}
      setState={setState}
    />
  );
};

type ActualNonExpressFormPageProps = {
  signature: string | null;
  caseId: string;
  state: NEFormState;
  setState: any;
};

const ActualNonExpressFormPage: React.FC<ActualNonExpressFormPageProps> = ({
  caseId,
  signature,
  state: { caseInfo, form, serviceProviders, persons, properties },
  setState
}) => {
  const match = useMatch("/form/*");
  if (!match) throw new Error("Unexpected");
  const navigate = useNavigate();
  const { state: uiState } = React.useContext(NEContext);
  const [remoteError, setRemoteError] = React.useState(undefined as string | undefined);
  const [busy, setBusy] = React.useState(false);
  const [updatingNotifierEmailAddress, setUpdatingNotifierEmailAddress] = React.useState(false);

  const serviceProvidersMap = React.useMemo(() => {
    return serviceProviders.reduce((acc, serviceProvider) => {
      if (serviceProvider.id !== undefined) {
        acc[serviceProvider.id] = serviceProvider;
      }
      return acc;
    }, {} as Record<string, ServiceProvider>);
  }, [serviceProviders]);

  const formCompletionState: Record<Section, boolean> = React.useMemo(() => {
    return getFormCompletionState(serviceProviders, form, uiState);
  }, [form, serviceProviders, uiState]);

  const menuEntries = React.useMemo(() => {
    return NEFormMenuEntries(uiState).map(({ key, label }: { key: Section, label: string }) => {
      // @ts-ignore
      const done = !!form.submittedSections[key];

      const error = done && !formCompletionState[key];

      return { key, label, done, error };
    });
  }, [formCompletionState, form, uiState, caseInfo?.bypassKYC]);

  const updatePerson = React.useCallback(
    (person: Person) => {
      setState((s: NEFormState) => {
        const index = s.persons.findIndex(({ id }: { id: string }) => person.id === id);
        if (index < 0) {
          return {
            ...s,
            persons: s.persons.concat([person])
          };
        }

        return {
          ...s,
          persons: [...s.persons.slice(0, index), person, ...s.persons.slice(index + 1)]
        };
      });
    },
    [setState]
  );

  const removePerson = React.useCallback(
    (personId: string) => {
      setState((s: NEFormState) => {
        const index = s.persons.findIndex(({ id }: { id: string }) => personId === id);
        if (index < 0) {
          return s;
        }

        return {
          ...s,
          persons: [...s.persons.slice(0, index), ...s.persons.slice(index + 1)]
        };
      });
    },
    [setState]
  );

  const updateProperty = React.useCallback(
    (property: Property) => {
      setState((s: NEFormState) => {
        const index = s.properties.findIndex(({ id }: { id: string }) => property.id === id);
        if (index < 0) {
          return {
            ...s,
            properties: s.properties.concat([property])
          };
        }

        return {
          ...s,
          properties: [...s.properties.slice(0, index), property, ...s.properties.slice(index + 1)]
        };
      });
    },
    [setState]
  );

  const updateServiceProvider = React.useCallback(
    (sp: ServiceProvider) => {
      setState((s: NEFormState) => {
        const index = s.serviceProviders.findIndex(
          (provider) => sp.id !== undefined && sp.id === provider.id
        );

        if (index < 0) {
          return {
            ...s,
            serviceProviders: s.serviceProviders.concat([sp])
          };
        }

        return {
          ...s,
          serviceProviders: [
            ...s.serviceProviders.slice(0, index),
            sp,
            ...s.serviceProviders.slice(index + 1)
          ]
        };
      });
    },
    [setState]
  );

  const changeNotifierEmailAddress = React.useCallback(() => {
    setUpdatingNotifierEmailAddress(true);
    navigate(`${match.pathnameBase}${pathForSection(Section.Notifier)}`);
  }, [match.pathnameBase, navigate]);

  const [persistedStates, setPersistedStates] = React.useState<PersistedState>({
    notifier: notifierDetailsPersistedStateFromForm(form),
    deceased: deceasedPersistedStateFromForm(form),
    documents: documentsPersistedStateFromForm(form),
    nok: nokDetailsPersistedStateFromForm(form),
    executor: executorDetailsPersistedStateFromForm(form)
  });
  const persistedStateSetterFor = (
    setPersistedStates: React.Dispatch<React.SetStateAction<PersistedState>>,
    prop: keyof PersistedState
  ) => {
    return (
      newState:
        | PersistedStateProperties
        | ((props: PersistedStateProperties) => PersistedStateProperties)
    ) => {
      setPersistedStates((state: PersistedState) => {
        return {
          ...state,
          [prop]: typeof newState === "function" ? newState(state[prop]) : newState
        };
      });
    };
  };

  const persistedStateSetters = React.useMemo(() => {
    return {
      notifier: persistedStateSetterFor(setPersistedStates, "notifier"),
      deceased: persistedStateSetterFor(setPersistedStates, "deceased"),
      documents: persistedStateSetterFor(setPersistedStates, "documents"),
      nok: persistedStateSetterFor(setPersistedStates, "nok"),
      executor: persistedStateSetterFor(setPersistedStates, "executor")
    };
  }, [setPersistedStates]);

  // Legacy

  const setActiveSection = React.useCallback(
    (section) => {
      navigate(`${match.pathnameBase}${pathForSection(section)}`);
    },
    [match.pathnameBase, navigate]
  );

  const onSectionClick = React.useCallback(
    (section) => {
      navigate(`${match.pathnameBase}/${section}`);
    },
    [navigate, match.pathnameBase]
  );

  const setForm = React.useCallback(
    (f) => {
      setState((s: any) => {
        const form = typeof f === "function" ? f(s.form) : f;
        return { ...s, form };
      });
    },
    [setState]
  );

  const continueWithoutChanges = React.useCallback(
    (currentSection, doNotAdvance) => {
      if (!doNotAdvance) {
        setActiveSection(nextSection(currentSection, uiState));
      }
    },
    [setActiveSection, uiState]
  );

  const prepareUpdate = React.useCallback(
    (section: Section, futureForm: NonExpressFormData) => {
      setBusy(true);
      setRemoteError(undefined);
      return {
        ...futureForm,
        submittedSections: {
          ...futureForm.submittedSections,
          [section]: true
        }
      };
    },
    [setBusy, setRemoteError]
  );

  const updateSuccessful = React.useCallback(
    (futureForm: NonExpressFormData, section, doNotAdvance) => {
      if (section === Section.Submit) {
        navigate(urlPaths.formRating());
        return;
      }
      setForm(futureForm);
      setBusy(false);
      if (!doNotAdvance) {
        setActiveSection(nextSection(section, uiState));
      }
    },
    [navigate, setBusy, setForm, setActiveSection, uiState]
  );

  const updateFailure = React.useCallback(
    (error: Error) => {
      setBusy(false);
      console.warn({ error });
      setRemoteError("Operation failed. Please try again or contact customer support.");
    },
    [setBusy, setRemoteError]
  );

  const uploadFile = React.useCallback(
    (file: File, filename?: string, tags?: string[]) => {
      setBusy(true);
      setRemoteError(undefined);
      return createDocumentAndUpload(caseId, signature, file, filename, tags);
    },
    [caseId, signature]
  );

  const uploadedFileInfo = React.useCallback(
    (documentId: string) => {
      return getDocument({ caseId, signature, documentId })
        .then((res: any) => {
          if (!res.data) {
            throw new Error(`Missing results for document ${documentId}`);
          }
          return res.data;
        })
        .catch((err: Error) => {
          console.warn(err.message);
          setRemoteError("Failed to download the file you previously uploaded.");
          throw err;
        });
    },
    [caseId, signature]
  );
  const removeRemoteError = React.useCallback(() => {
    setRemoteError(undefined);
  }, [setRemoteError]);

  const props = {
    caseId, signature, prepareUpdate, updateSuccessful, updateFailure, form, busy,
    continueWithoutChanges, persistedStates, setPersistedStates, updatePerson, updatingNotifierEmailAddress,
    setUpdatingNotifierEmailAddress, onSectionClick, match, setActiveSection, properties, remoteError,
    removeRemoteError, updateProperty, changeNotifierEmailAddress, uploadedFileInfo, persons, uploadFile,
    persistedStateSetters, updateServiceProvider, removePerson, menuEntries, serviceProvidersMap, serviceProviders,
    setBusy, formCompletionState
  };

  return (
    <Routes>
      <Route path="accounts/*" element={accountsComponent(props)} />
      <Route path="deceased" element={deceasedComponent(props)} />
      <Route path="notifier" element={notifierComponent(props)} />
      {uiState.collectNokDetails && <Route path="nok" element={nokComponent(props)} />}
      {uiState.collectExecutorDetails && <Route path="executor" element={executorComponent(props)} />}
      <Route path="documents" element={documentComponent(props)} />
      <Route path="kyc" element={kycComponent(props)} />
      <Route path="submit" element={submitComponent(props)} />
      <Route path="*" element={otherComponent(props)} />
    </Routes>
  );
};

function postFetchCase(data: any, navigate: any, setState: any, dispatch: any) {
  if (!data?.case) return Promise.reject(new Error("Missing data."));

  const {
    case: caseRecord,
    notifierFormState: neFormState,
    // accounts,
    serviceProviders,
    persons,
    properties,
    form: {
      notifierDetails,
      deceasedDetails,
      caseDocuments,
      executor, nok, accounts: formAccounts
    }
  } = data;

  const { notifier, willAvailable, bypassKYC } = caseRecord;
  (caseRecord.accounts || []).forEach((account: any) => {
    if (account.accountEvents) {
      account.accountEvents.sort(
        ({ eventAt: a }: { eventAt: string }, { eventAt: b }: { eventAt: string }) => {
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        }
      );
    }
  });

  const n = persons.find((person: Person) => (person.roles || []).includes(NotifierRolesByServer.Notifier));
  const kycPending = !n || !n.kycCompleted;

  const form = {
    submittedSections: { ...neFormState, nok: !!nok, executor: !!executor, intestacy: !!nok || !!executor },
    notifier: { ...notifierDetails, willAvailable: !!willAvailable },
    deceased: deceasedDetails,
    documents: caseDocuments,
    executor: executor as Executor,
    nok: nok as Nok,
    accounts: formAccounts,
    kycPending,
    submitted: !!caseRecord.caseSubmittedAt
  };

  const ui_state = onCaseLoadUI(form.notifier, !!willAvailable, bypassKYC, formAccounts, serviceProviders);
  dispatch({ type: "SET_UI", payload: ui_state });

  const new_state = {
    doesNotExist: false,
    loading: false,
    error: false,
    caseInfo: caseRecord,
    serviceProviders,
    persons, properties, notifier,
    notifierEmailAddressVerified: notifierEmailAddressVerified(
      persons,
      caseRecord.bypassEmailVerification
    ),
    form,
    source: caseRecord.source
  };

  setState(new_state);
}

