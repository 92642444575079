import * as React from "react";

export const useScrollToTop = () => {
  React.useEffect(() => {
    const isInIframe = window.self !== window.top;
    if (isInIframe) {
      window.parent.postMessage(
        {
          type: "settld:scrollToIframeTop",
        },
        "https://www.settld.care/" 
      );
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    });
  }, []);
};
// job of hired wordpress devloper
{/* <script>
  function scrollToIframeTop() {
    const iframe = document.getElementById("settld.care_express");
    if (iframe) {
      const rect = iframe.getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.pageYOffset,
        behavior: "smooth",
      });
    }
  }

  function resizeIframe(obj) {
    const iframeDomain = "https://help.settld.care";
    obj.contentWindow.postMessage("settld:resizeIframeSignal", iframeDomain);
    let previousHeight = 0;

    window.addEventListener("message", (event) => {
      if (event.origin !== iframeDomain) {
        return;
      }
      if (event.data && event.data.type === "settld:scrollToIframeTop") {
        scrollToIframeTop();
        return;
      }

     
    }, false);
  }
</script>

<iframe 
  id="settld.care_express" 
  style="width: 100%; height: 850px;" 
  onload="resizeIframe(this)" 
  src="https://help.settld.care/express/octopus-energy">
</iframe> */}
