import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Container, Snackbar, Stack, Typography } from "@mui/material";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { YesNoCheckboxField } from "@atoms/YesNoField";
import { updateAdditionalServices } from "@api/caseApi";
import { additionalServices, YesNo } from "@customTypes/index";

type StateRecord = {
  requestFinancialGuidance?: YesNo;
  requestFuneralDirector?: YesNo;
  requestHelpDealingWithDebt?: YesNo;
  requestHelpDealingWithFamilyConflict?: YesNo;
  requestHomeRemoval?: YesNo;
  requestLocalGriefSupport?: YesNo;
  requestProbateBridgingLoans?: YesNo;
  requestProbateSolicitor?: YesNo;
  requestPropertyValuationService?: YesNo;
  requestMissingAssetSearch?: YesNo;
  requestProbateHouseInsurance?: YesNo;
  requestExecutorInsurance?: YesNo;
};

export const OnBoardingAdditionalServicesPage: React.FunctionComponent<{
  caseId: string;
  signature: string | null;
  navigateNext: string;
}> = ({ caseId, signature, navigateNext }) => {
  const navigate = useNavigate();

  const [busy, setBusy] = React.useState(false);
  const [record, setRecord] = React.useState({} as StateRecord);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [remoteError, setRemoteError] = React.useState("");

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | undefined } }) => {
      setRecord((s) => ({ ...s, [name]: value }));
      setHasChanges(true);
    },
    [setRecord]
  );

  const onContinue = () => {
    setBusy(true);
    updateAdditionalServices({
      caseId,
      signature,
      record,
    }).then(
      () => {
        setBusy(false);
        navigate(navigateNext);
        if (hasChanges) {
          localStorage.setItem("additional-services-requested", "true");
        }
      },
      (error) => {
        setBusy(false);
        navigate(navigateNext);
        setRemoteError(error.message);
      }
    );
  };

  const removeRemoteError = React.useCallback(() => {
    setRemoteError("");
  }, [setRemoteError]);

  return (
    <OnBoardingAdditionalServicesPageView
      busy={busy}
      record={record}
      remoteError={remoteError}
      removeRemoteError={removeRemoteError}
      onFieldChange={onFieldChange}
      onContinue={onContinue}
    />
  );
};

export type OnBoardingAdditionalServicesPageViewProps = {
  busy: boolean;
  record: StateRecord;
  remoteError?: string;
  removeRemoteError: () => void;
  onFieldChange: (event: { target: { name: string; value: string } }) => void;
  onContinue: () => void;
};

export const OnBoardingAdditionalServicesPageView: React.FC<
  OnBoardingAdditionalServicesPageViewProps
> = ({ busy, record, remoteError, removeRemoteError, onFieldChange, onContinue }) => {
  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });

  const Entry = ({ label, name }: { label: string; name: keyof StateRecord }) => (
    <FormField>
      <YesNoCheckboxField
        label={label}
        value={record[name]}
        onValueChange={onFieldValueChange(name)}
      />
    </FormField>
  );

  return (
    <SideImageTemplate
      headerType="signout"
      busy={busy}
      image={<img src="/images/stock/signup.svg" width="100%" alt="Family" />}
    >
      <Stack rowGap={4}>
        <Header level={1}>Your case has been successfully created!</Header>
        <Stack rowGap={2}>
          <Typography variant="body1">
            <b>Let’s start by confirming the services you may need</b>
          </Typography>

          <Typography variant="body1">
            Please select from the list below.
          </Typography>
        </Stack>

        <Container>
          <FormStack spacing={0}>
            {additionalServices.map(([ui, internal_field]: any) => (
              <Entry key={internal_field} label={ui} name={internal_field} />
            ))}
          </FormStack>
        </Container>

        <FormStack>
          <FormField>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="primary" size="large" disabled={busy} onClick={onContinue}>
                Skip
              </Button>
              <Button variant="primary" size="large" disabled={busy} onClick={onContinue}>
                Next
              </Button>
            </div>
          </FormField>
        </FormStack>
      </Stack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
    </SideImageTemplate>
  );
};
