import * as React from "react";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

const firstTimeout = 1000;
const secondTimeout = 4000;
const thirdTimeout = 6000;

export const LoadingPage = () => {

  return <LoadingPageView />;
};

export const LoadingPageView: React.FC = () => {
  const [{ message, description }, setState] = React.useState({
    message: "",
    description: null
  } as {
    message: string;
    description?: React.ReactElement | string | null;
  });

  React.useEffect(() => {

    let timeout: NodeJS.Timeout | null = setTimeout(() => {
      setState({ message: "Loading..." });

      timeout = setTimeout(() => {
        setState({
          message: "Loading...",
          description:
            "There seems to be a problem loading your data; we are still working on it...."
        });

        timeout = setTimeout(() => {
          setState({
            message: "Loading failed",
            description: (
              <span>
                We didn't manage to load your data; please reload this page to retry. <br /> If this problem persists,
                please contact us at{" "}<a href="mailto:support@settld.care">support@settld.care</a>
              </span>
            )
          });
          timeout = null;
        }, thirdTimeout);
      }, secondTimeout);
    }, firstTimeout);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <CentredTemplate headerType={"logo"}> <ErrorPane message={message} description={description} /> </CentredTemplate>
  );
};
