import { apiFetch } from "./restApi";
import monitor from "../../utils/monitoring";
import { ValidationError } from "@utils/Errors";
import { SignupReferralOptions } from "@utils/Constants";
import { RegistrationInfo, ServerRecord, ServerResponseRecord } from "@utils/Types";

export const signup = async (args: ServerRecord): Promise<ServerResponseRecord> => {
  let result;

  try {
    result = await signupRequest(args);
  } catch {
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      case "user_exists":
        throw new Error("You have already registered; please log in.");
      default:
        throw new Error(result.error);
    }
  }

  return result.data;
};

const signupRequest = async (args: ServerRecord) => {
  let result;

  const { referral, referralOther, ...otherArgs } = args;

  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },

      body: {
        ...otherArgs,
        referral: referralFrom(referral, referralOther)
      }
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials",
      args,
      error
    });
    throw error;
  }

  return result;
};

const referralFrom = (referral: string, referralOther: string) => {
  const found = SignupReferralOptions.find(({ value }) => value === referral);
  const str = found ? found.label : referral;

  if (referralOther) {
    return str + " - " + referralOther;
  }

  return str;
};

export const registerUser = async (args: RegistrationInfo) => {
  let result;

  try {
    result = await apiFetch({
      path: "/registrations",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },

      body: { ...args }
    });
  } catch (error) {
    monitor.logError({
      event: "POST /registrations",
      args,
      error
    });
    throw error;
  }

  return result;
};
