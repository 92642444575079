import  { useState, useEffect } from 'react';

const CookieNotice = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('__cookies_allowed__')) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('__cookies_allowed__', 't');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div
      id="cookie-notice"
      style={{
        display: 'block',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#ddd',
        width: '100%',
        zIndex: 1031,
      }}
    >
      <div style={{ padding: 20, maxWidth: 800, margin: '0 auto' }}>
        <p>
          We use cookies to enhance your experience and for analytics. Find out more about cookies by
          reading our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.settld.care/legal/cookie-policy.html"
          >
            Cookie Policy
          </a>
          , which contains further information about the cookies and other technologies we use and
          information about how to disable them. By clicking "Accept" and by continuing to use this
          service, you agree to our use of cookies and similar technologies.
        </p>
        <div style={{ textAlign: 'right' }}>
          <button id="agree-with-cookies" onClick={handleAccept}>
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;
