import * as React from "react";

import { Account } from "@src/types";
import { formatDate } from "@utils/Functions";
import { RequestDetailsField } from "@src/components/molecules";
import { formatPropertyStatus, OwnershipStatus, Property, PropertyStatus } from "@src/types/property";
import { NotAvailable } from "./NotAvailable";
import { Unknown } from "./Unknown";

export const PropertyDetails: React.FC<{ account: Account; property?: Property }> = ({
  account,
  property
}) => {
  if (!property) {
    return null;
  }

  return (
    <>
      <RequestDetailsField label="Property address">
        {property ? propertyString(property) : <NotAvailable />}
      </RequestDetailsField>

      <RequestDetailsField label="Current status of property">
        {formatPropertyStatus(property.status)}
      </RequestDetailsField>

      <PropertyStatusDetails account={account} property={property} />
    </>
  );
};

const PropertyStatusDetails: React.FC<{ account: Account; property: Property }> = ({ property }) => {
  if (property.status === PropertyStatus.Vacant) {
    return <VacantPropertyDetails property={property} />;
  } else if (property.status === PropertyStatus.NewOccupier) {
    return <NewOccupierPropertyDetails property={property} />;
  } else if (property.status === PropertyStatus.ExistingOccupier) {
    // return <ExistingOccupierPropertyDetails account={account} property={property} />;
    return null; // We show this case in the main details section as we need it in the view more section
  } else {
    return null;
  }
};

const VacantPropertyDetails: React.FC<{ property: Property }> = ({ property }) => {
  return (
    <>
      <RequestDetailsField label="Date of last occupation of person who died">
        {property.lastOccupiedDate ? formatDate(property.lastOccupiedDate) : "On the date of death"}
      </RequestDetailsField>

      <RequestDetailsField label="Ownership status">
        {property.ownershipStatus ? readableOwnershipStatus(property.ownershipStatus) : <Unknown />}
      </RequestDetailsField>
    </>
  );
};

const NewOccupierPropertyDetails: React.FC<{ property: Property }> = ({ property }) => {
  return (
    <>
      <RequestDetailsField label="Date of last occupation of person who died">
        {property.lastOccupiedDate ? formatDate(property.lastOccupiedDate) : "On the date of death"}
      </RequestDetailsField>

      <RequestDetailsField label="Ownership status">
        {property.ownershipStatus ? readableOwnershipStatus(property.ownershipStatus) : <Unknown />}
      </RequestDetailsField>

      <RequestDetailsField label="New occupant move in date">
        {property.handOverDate ? formatDate(property.handOverDate) : <Unknown />}
      </RequestDetailsField>
    </>
  );
};

const propertyString = (property?: Property) => {
  if (!property) {
    return null;
  }

  const { address, city, postcode } = property;
  return [address, city, postcode].filter((x) => x).join(", ");
};

export const readableOwnershipStatus = (ownershipStatus?: OwnershipStatus) => {
  switch (ownershipStatus) {
    case OwnershipStatus.Owned:
      return "Owned";
    case OwnershipStatus.Rented:
      return "Rented";
    case OwnershipStatus.Unknown:
      return "Not known";
    default:
      return "Not known";
  }
};
