import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { resetPassword, InvalidTokenError } from "@api/sessionApi";
import urlPaths from "../../../urlPaths";
import { Button } from "../../../components/atoms/Button";
import { Header } from "../../../components/atoms/Header";
import { PasswordInput } from "../../../components/atoms/PasswordInput";
import { FormField } from "../../../components/molecules/FormField";
import { FormStack } from "../../../components/molecules/FormStack";
import { CentredTemplate } from "@templates/CentredTemplate";
import { Link } from "../../../components/atoms/Link";

type Record = {
  readonly password: string;
  readonly passwordConfirmation: string;
};

interface State {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly record: Record;
  readonly errors: FieldsErrors;
}

interface FieldsErrors {
  password?: string;
  passwordConfirmation?: string;
}

export const ResetPasswordForm: React.FC<{
  token: string;
  done: () => void;
  invalidToken: () => void;
}> = ({ token, done, invalidToken }) => {
  

  const [state, setState] = React.useState({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      password: "",
      passwordConfirmation: "",
    },
  } as State);

  const { busy, errorMessage, record, errors } = state;

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        record: { ...s.record, [name]: value },
        errors: {
          ...s.errors,
          [name]: "",
        },
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none", token };
      // // @ts-ignore
      // window.googleReCAPTCHA.ready(() => {
      //   // @ts-ignore
      //   window.googleReCAPTCHA.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
      //     submitData({ ...record, captcha: token });
      //   });
      // });

      resetPassword(data).then(
        (_) => {
          setState((s) => ({ ...s, busy: false }));
          done();
        },
        (error: any) => {
          if (error instanceof InvalidTokenError) {
            invalidToken();
            return;
          }

          setState((s) => ({
            ...s,
            busy: false,
            errorMessage: error.message,
          }));
        }
      );
    },
    [record, token, done, invalidToken, setState]
  );

  return (
    <ResetPasswordFormView
      busy={busy}
      errorMessage={errorMessage}
      record={record}
      errors={errors}
      onSubmit={onSubmit}
      onChange={onChange}
      removeErrorMessage={removeErrorMessage}
    />
  );
};

export type ResetPasswordFormViewProps = {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly record: Record;
  readonly errors: FieldsErrors;
  readonly onSubmit: (ev: any) => void;
  readonly onChange: any;
  readonly removeErrorMessage: () => void;
};

export const ResetPasswordFormView: React.FC<ResetPasswordFormViewProps> = ({
  busy,
  errorMessage,
  record,
  errors,
  onSubmit,
  onChange,
  removeErrorMessage,
}) => {
  return (
    <CentredTemplate
      busy={busy}
      headerType="signup"
      errorMessage={errorMessage}
      removeErrorMessage={removeErrorMessage}
    >
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Stack rowGap={4}>
          <Box>
            <Header>Reset your password</Header>

            <Typography variant="body1">
              Please enter a new password for your Settld account.
            </Typography>
          </Box>

          <FormStack>
            <FormField slightlyMoreThanHalfWidth label="Password">
              <PasswordInput
                name="password"
                value={record.password || ""}
                error={errors.password}
                onChange={onChange}
              />
            </FormField>

            <FormField slightlyMoreThanHalfWidth label="Password confirmation">
              <PasswordInput
                name="passwordConfirmation"
                value={record.passwordConfirmation || ""}
                error={errors.passwordConfirmation}
                onChange={onChange}
              />
            </FormField>
          </FormStack>

          <FormStack>
            <FormField slightlyMoreThanHalfWidth>
              <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                Reset password
              </Button>
            </FormField>
          </FormStack>

          <Box>
            <Link to={urlPaths.signin()}>Login here</Link>
          </Box>
        </Stack>
      </form>
    </CentredTemplate>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    password: !record.password
      ? "required"
      : [/[0-9]/, /[a-zA-Z]/, /[^0-9a-zA-Z]/, /......../].every((re) => re.test(record.password))
      ? undefined
      : "please ensure your password is at least 8 characters in length, and contains a combination of letters, numbers and symbols",

    passwordConfirmation: !record.passwordConfirmation
      ? "required"
      : record.password !== record.passwordConfirmation
      ? "password does not match"
      : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};
