import * as React from "react";

import { YesNo } from "@src/types";
import { RadioGroupInput } from "@atoms/RadioGroupInput";
import { BooleanInput } from "./BooleanInput";

type YesNoRadioFieldProps = {
  onValueChange: (value: YesNo) => void;
  value?: YesNo;
  error?: string;
  yesLabel?: string;
  noLabel?: string;
};

export const YesNoRadioField: React.FC<YesNoRadioFieldProps> = ({
  value,
  onValueChange,
  error,
  yesLabel,
  noLabel,
}) => {
  const handleChange = React.useCallback(
    (value: string) => {
      onValueChange(value === YesNo.Yes ? YesNo.Yes : YesNo.No);
    },
    [onValueChange]
  );

  return (
    <RadioGroupInput
      onValueChange={handleChange}
      value={value}
      fields={yesNoFields(yesLabel, noLabel)}
      error={error}
    />
  );
};

type YesNoCheckboxFieldProps = {
  onValueChange: (value: YesNo) => void;
  value?: YesNo;
  label: string;
  noPadding?: boolean;
  smallFont?: boolean;
};

export const YesNoCheckboxField: React.FC<YesNoCheckboxFieldProps> = ({
  value,
  onValueChange,
  label,
  noPadding,
  smallFont,
}) => {
  const handleChange = React.useCallback(
    (value: boolean) => {
      onValueChange(value ? YesNo.Yes : YesNo.No);
    },
    [onValueChange]
  );

  return (
    <BooleanInput
      noPadding={noPadding}
      smallFont={smallFont}
      label={label}
      value={value === YesNo.Yes}
      onValueChange={handleChange}
    />
  );
};

export type YesNoBooleanFieldProps = {
  onValueChange: (value: boolean) => void;
  value?: boolean;
  error?: string;
  yesLabel?: string;
  noLabel?: string;
};

export const YesNoBooleanRadioField: React.FC<YesNoBooleanFieldProps> = ({
  value,
  onValueChange,
  error,
  yesLabel,
  noLabel,
}) => {
  const handleChange = React.useCallback(
    (value: string) => {
      onValueChange(value === YesNo.Yes);
    },
    [onValueChange]
  );

  return (
    <RadioGroupInput
      onValueChange={handleChange}
      value={value === undefined ? undefined : value ? YesNo.Yes : YesNo.No}
      fields={yesNoFields(yesLabel, noLabel)}
      error={error}
    />
  );
};

const yesNoFields = (yesLabel: string | undefined, noLabel: string | undefined) => {
  if (!yesLabel && !noLabel) {
    return yesNoDefaultFields;
  }

  return [
    { label: yesLabel || "Yes", value: YesNo.Yes },
    { label: noLabel || "No", value: YesNo.No },
  ];
};

const yesNoDefaultFields = [
  { label: "Yes", value: YesNo.Yes },
  { label: "No", value: YesNo.No },
];
