import monitor from "../../utils/monitoring";
import { filenameFromFileAndPrefix } from "@utils/Functions";
import { apiFetch } from "./restApi";
import { UploadRecord } from "@src/types/Document";
import { ValidationError } from "@src/utils/Errors";

export type ServerRecord = {
  caseId: string | null;
  signature: string | null;
  record?: any;
  accountId?: string;
  form?: any;
  intestacy?: boolean;
  collectNokDetails?: boolean;
  collectExecutorDetails?: boolean;
  source?: string|null;
};

export const s3Upload = (uploadUrl: string, file: File): Promise<Response> => {
  return fetch(uploadUrl, { method: "PUT", body: file });
};

export const getDocument = async (args: {
  caseId: string;
  signature: string | null;
  documentId: string;
}) => {
  const { caseId, signature, documentId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/documents/${documentId}`,
      search: !signature ? undefined : { caseId, sig: signature }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}/documents/{documentId}",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const createDocument = async (args: {
  caseId: string;
  signature: string | null;
  filename: string;
  tags?: string[];
}) => {
  const { caseId, signature, filename, tags } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/documents`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { filename, tags }
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/documents`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateForm = async (args: { caseId: string; signature: string | null; form: any }) => {
  const { caseId, signature, form } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/update-form`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      keepCase: true,
      body: { form_data: form }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/update-form`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const createDocumentAndUpload = async (
  caseId: string,
  signature: string | null,
  file: File,
  filename?: string,
  tags?: string[]
): Promise<UploadRecord> => {
  const fname = filenameFromFileAndPrefix(file, filename);

  const res = await createDocument({ caseId, filename: fname, signature, tags: tags || [] });

  if (res.error) throw res.error;

  const { uploadUrl, document } = res.data || {};
  if (!uploadUrl || !document) throw new Error("Error uploading file. Please try again.");

  const result = await s3Upload(uploadUrl, file);
  if (result.status !== 200) {
    console.warn(res);
    throw new Error("Error uploading file. Please try again.");
  }

  return { id: document.id, filename: fname };
};

export const getCase = async (args: {
  caseId: string;
  signature: string | null;
  email?: string | null;
}) => {
  const { caseId, signature, email } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}`,
      search: !signature ? { email } : { caseId, sig: signature, email }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}",
      args,
      error
    });
    throw error;
  }
  return result;
};

export const attach = async ({
  caseId,
  signature,
  documentId
}: {
  caseId: string;
  signature: string | null;
  documentId: string;
}) => {
  const args = { documentId };
  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/attachments`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: args
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/attachments`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const deAttach = async (args: {
  caseId: string;
  signature: string | null;
  attachmentId: string;
}) => {
  const { caseId, signature, attachmentId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/attachments/${attachmentId}`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    monitor.logError({
      event: `DELETE /notifier/attachments/{attachmentId}`,
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const updateAdditionalServices = async (args: ServerRecord) => {
  let result;
  try {
    result = await updateRequest(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  
  return result.data;
};

export const updateRequest = async (args: ServerRecord) => {
  const { caseId, signature, record,source } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/additional-help`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record,source }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/additional-help`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const loadGOA = async (args: ServerRecord) => {
  let result;

  try {
    result = await loadRequestGOA(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const loadRequestGOA = async (args: ServerRecord) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/grant-of-authority`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "GET"
    });
  } catch (error) {
    monitor.logError({
      event: `GET /notifier/cases/{caseId}/grant-of-authority`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateGOA = async (args: ServerRecord) => {
  let result;

  try {
    result = await updateRequestGOA(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const updateRequestGOA = async (args: ServerRecord) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/grant-of-authority`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: {}
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/grant-of-authority`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateDeceased = async (args: ServerRecord) => {
  let result;

  try {
    result = await updateRequestDeceased(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  
  return result.data;
};

const updateRequestDeceased = async (args: ServerRecord) => {
  const { caseId, signature, record } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/deceased`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },

      body: { record }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/deceased`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateDocuments = async (args: ServerRecord) => {
  let result;

  try {
    result = await updateRequestDocuments(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  
  return result.data;
};

const updateRequestDocuments = async (args: ServerRecord) => {
  const { caseId, signature, record } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/documents`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/documents`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateNotifier = async (args: ServerRecord) => {
  let result;

  try {
    result = await updateRequestNotifier(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const updateRequestNotifier = async (args: ServerRecord) => {
  const { caseId, signature, record, intestacy, collectExecutorDetails, collectNokDetails } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/notifier`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record, intestacy, collectExecutorDetails, collectNokDetails }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/notifier`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const createExecutor = async (args: ServerRecord) => {
  let result;

  try {
    result = await doCreateExecutor(args);

  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const doCreateExecutor = async (args: ServerRecord) => {
  const { caseId, signature, record, intestacy } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/executor`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { record, intestacy }
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/executor`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateExecutor = async (args: ServerRecord) => {
  let result;

  try {
    result = await doUpdateExecutor(args);

  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const doUpdateExecutor = async (args: ServerRecord) => {
  const { caseId, signature, record, intestacy } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/executor/${record.id}`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record, intestacy }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/executor/{executor_id}`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const createNok = async (args: ServerRecord) => {
  let result;

  try {
    result = await doCreateNok(args);

  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const doCreateNok = async (args: ServerRecord) => {
  const { caseId, signature, record, intestacy } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/nok`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { record, intestacy }
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/nok`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateNok = async (args: ServerRecord) => {
  let result;

  try {
    result = await doUpdateNok(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        throw new ValidationError(result.errors);
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const doUpdateNok = async (args: ServerRecord) => {
  const { caseId, signature, record, intestacy } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/nok/${record.id}`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record, intestacy }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/nok`,
      args,
      error
    });
    throw error;
  }

  return result;
};
// export const getCase = async (args: { caseId: string; signature: string | null }) => {
//   let result;

//   try {
//     result = await getCaseRequest(args);
//   } catch (error) {
//     throw error;
//   }

//   if (result.error) {
//     switch (result.error) {
//       default:
//         
//         throw new Error("Unexpected error; please contact customer support");
//     }
//   }

//   

//   return result.data;
// };

// const getCaseRequest = async (args: { caseId: string; signature: string | null }) => {
//   const { caseId, signature } = args;

//   let result;
//   try {
//     result = await apiFetch({
//       path: `/notifier/cases/${caseId}`,
//       search: !signature ? {} : { caseId, sig: signature },
//     });
//   } catch (error) {
//     monitor.logError({
//       event: "GET /notifier/cases/{caseId}",
//       args,
//       error,
//     });
//     throw error;
//   }

//   return result;
// };

export const deleteAccount = async (args: ServerRecord) => {
  let result;

  try {
    result = await deleteAccountRequest(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  
  return result.data;
};

const deleteAccountRequest = async (args: ServerRecord) => {
  const { caseId, signature, accountId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${accountId}`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "DELETE",
      headers: { "Content-Type": "application/json" }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/accounts/{accountId}/delete`,
      args,
      error
    });
    throw error;
  }

  return result;
};
export const submitNonExpress = async (args: ServerRecord) => {
  let result;

  try {
    result = await submitRequestNonExpress(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        throw new Error(result.error);
    }
  }

  return result.data;
};

const submitRequestNonExpress = async (args: ServerRecord) => {
  const { caseId, signature, form } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/submit`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      keepCase: true,
      body: { form_data: form }
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/submit`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const loadRequirements = async (args: ServerRecord) => {
  let result;

  try {
    result = await loadRequestRequirements(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const loadRequestRequirements = async (args: ServerRecord) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/requirements`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "GET"
    });
  } catch (error) {
    monitor.logError({
      event: `GET /notifier/cases/{caseId}/requirements`,
      args,
      error
    });
    throw error;
  }

  return result;
};

export const updateRequirements = async (args: ServerRecord) => {
  let result;

  try {
    result = await updateRequestRequirements(args);
  } catch {
    
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        
        throw new Error(result.error);
    }
  }

  
  return result.data;
};

const updateRequestRequirements = async (args: ServerRecord) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/requirements`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: {}
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/requirements`,
      args,
      error
    });
    throw error;
  }

  return result;
};
