import * as React from "react";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";
import { SettldContactInfo } from "@src/utils/Constants";

export const BugFallbackPage = () => {
  

  return <BugFallbackPageView />;
};

export type BugFallbackPageViewProps = object;

export const BugFallbackPageView: React.FC<BugFallbackPageViewProps> = () => {
  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane
        message="Unexpected failure"
        description="Please reload the page. If the problem persists, please contact customer support."
      />
        <br />
      <b>support@settld.care</b> or call us on <b>{SettldContactInfo.Telephone}</b> (Monday-Friday)
    </CentredTemplate>
  );
};
