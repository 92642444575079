import * as React from "react";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

export type BrokenLinkPageProps = object;

export const BrokenLinkPage: React.FC<BrokenLinkPageProps> = () => {
  

  return <BrokenLinkPageView />;
};

export type BrokenLinkPageViewProps = object;

export const BrokenLinkPageView: React.FC<BrokenLinkPageViewProps> = () => {
  return (
    <CentredTemplate headerType="signup">
      <ErrorPane
        title="404"
        message="Sorry, page not found"
        description="The link you followed is broken or the page has been removed."
      />
    </CentredTemplate>
  );
};
