import * as React from "react";
import { InputLabel } from "@mui/material";

export type LabelProps = {
  children: React.ReactNode;
  className?: string;
};

export const Label: React.FC<LabelProps> = ({ children,className }) => {
  return <InputLabel sx={{ fontSize: "small" }} className={className}>{children}</InputLabel>;
};
