import * as React from "react";

import { Stack } from "@mui/material";

import { Document, ServiceProvider } from "@src/types";
import { AccountDetails as AccountDetailsType, ServiceProviderType } from "@customTypes/index";
import { Property } from "@src/types/property";
import { BankingAccount } from "./BankingAccount";
import { CreditCardsAccount } from "./CreditCardsAccount";
import { DefaultAccount } from "./DefaultAccount";
import { EnergyAccount } from "./EnergyAccount";
import { InsuranceAccount } from "./InsuranceAccount";
import { MobileAccount } from "./MobileAccount";
import { PensionAccount } from "./PensionAccount";
import { Person } from "./Responsible";
import { WaterAccount } from "./WaterAccount";
import { MortgageAccount } from "./MortgageAccount";
import { SocialMediaOrEmailAccount } from "./SocialMediaOrEmailAccount";

export type AccountDetailsProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly account: AccountDetailsType;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
};

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  persons,
  properties,
  serviceProviders,
  account,
  uploadedFileInfo
}) => {
  const { serviceProviderType } = account;
  const [documents, setDocuments] = React.useState([] as Document[]);

  React.useEffect(() => {
    let unmounted = false;
    Promise.all((account.documentIds || []).map(uploadedFileInfo)).then(
      (documents) => {
        if (unmounted) {
          return;
        }
        setDocuments(documents);
      },
      (err) => {
        console.log(err);
        if (unmounted) {
          return;
        }
        setDocuments([]);
      }
    );
    return () => {
      unmounted = true;
    };
  }, [account.documentIds, setDocuments, uploadedFileInfo]);

  const selectedServiceProvider = React.useMemo(() => {
    return serviceProviders.find((sp) => sp.id === account.companyId);
  }, [serviceProviders, account.companyId]);

  if (!serviceProviderType) {
    return null;
  }
  if (account.additionalServicesAccount === true || account.serviceProviderType === "affiliate_partner")
    return null;

  return (
    <AccountDetailsView
      serviceProvider={
        selectedServiceProvider ? selectedServiceProvider : { customForm: undefined }
      }
      serviceProviderType={serviceProviderType}
      documents={documents}
      account={account}
      persons={persons}
      properties={properties}
    />
  );
};

export type AccountDetailsViewProps = {
  readonly serviceProvider: { customForm?: string };
  readonly serviceProviderType: ServiceProviderType;
  readonly account: AccountDetailsType;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly documents: ReadonlyArray<Document>;
};

export const AccountDetailsView: React.FC<AccountDetailsViewProps> = ({
  serviceProvider,
  serviceProviderType,
  persons,
  account,
  documents,
  properties
}) => {
  return (
    <Stack rowGap={2}>
      {serviceProviderType === ServiceProviderType.Banking ? (
        <BankingAccount persons={persons} account={account} serviceProvider={serviceProvider} />
      ) : serviceProviderType === ServiceProviderType.BuildingSociety ? (
        <BankingAccount persons={persons} account={account} serviceProvider={serviceProvider} />
      ) : serviceProviderType === ServiceProviderType.CreditCards ? (
        <CreditCardsAccount persons={persons} account={account} />
      ) : serviceProviderType === ServiceProviderType.Email ? (
        <SocialMediaOrEmailAccount persons={persons} account={account} documents={documents} />
      ) : serviceProviderType === ServiceProviderType.Energy ? (
        <EnergyAccount
          persons={persons}
          properties={properties}
          account={account}
          documents={documents}
        />
      ) : serviceProviderType === ServiceProviderType.Insurance ? (
        <InsuranceAccount
          persons={persons}
          properties={properties}
          account={account}
          documents={documents}
        />
      ) : serviceProviderType === ServiceProviderType.Mobile ? (
        <MobileAccount persons={persons} account={account} />
      ) : serviceProviderType === ServiceProviderType.Mortgage ? (
        <MortgageAccount persons={persons} account={account} documents={documents} />
      ) : serviceProviderType === ServiceProviderType.Pension ? (
        <PensionAccount persons={persons} account={account} documents={documents} />
      ) : serviceProviderType === ServiceProviderType.SocialMedia ? (
        <SocialMediaOrEmailAccount persons={persons} account={account} documents={documents} />
      ) : serviceProviderType === ServiceProviderType.Water ? (
        <WaterAccount
          persons={persons}
          properties={properties}
          account={account}
          documents={documents}
        />
      ) : (
        <DefaultAccount persons={persons} account={account} documents={documents} />
      )}
    </Stack>
  );
};
