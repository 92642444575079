import * as React from "react";

import { Container, Grid, Stack } from "@mui/material";

import { Account } from "../../types/Account";
import { SectorIcon } from "../../components/atoms/SectorIcon";
import { CustomCollapsible } from "../../components/organisms/CustomCollapsible";
import { AccountUpdates } from "./AccountUpdates";
import { RequestDetails } from "./RequestDetails";
import { RequestOutcome } from "./RequestOutcome";

export type AccountCollapsibleProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly account: Account;
  readonly section: "blocked" | "processing" | "settled";
  readonly open: boolean;
};

export const AccountCollapsible: React.FC<AccountCollapsibleProps> = ({
  signature,
  caseId,
  account,
  section,
  open=false,
}) => {
  const [expanded, setExpanded] = React.useState(open);

  const handleToggle = React.useCallback(
    (active) => {
      setExpanded(active);
    },
    [setExpanded]
  );

  return (
    <AccountCollapsibleView
      caseId={caseId}
      signature={signature}
      account={account}
      expanded={expanded}
      section={section}
      handleToggle={handleToggle}
    />
  );
};

export type AccountCollapsibleViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly account: Account;
  readonly expanded: boolean;
  readonly section: "blocked" | "processing" | "settled";
  readonly handleToggle: (active: boolean) => void;
};

export const AccountCollapsibleView: React.FC<AccountCollapsibleViewProps> = ({
  caseId,
  signature,
  account,
  expanded,
  handleToggle,
}) => {
  let accountHeading: string;
  if (account.additionalServicesDetails) {
    if (account.additionalServicesDetails.type === "Probate solicitor") {
      accountHeading = "Probate and Estate Admin";
    } else {
      accountHeading = account.additionalServicesDetails?.type;
    }
  } else {
    if (account.serviceProvider) {
      accountHeading = account.serviceProvider.companyName;
    } else {
      accountHeading = 'Unknown Company';
    }
  }
  return (
    <CustomCollapsible
      title={
        <>
          <span
            style={{
              fontSize: "1.4rem",
              verticalAlign: "middle",
              display: "inline-block",
              width: "40px",
            }}
          >
            {account.additionalServicesAccount ? null : (
              account.serviceProvider ? (
                <SectorIcon sector={account.serviceProvider.serviceProviderType} />
              ) : null
            )}
          </span>
          {accountHeading}
        </>
      }
      onToggled={handleToggle}
      expanded={expanded}
    >
      <>
        <Container sx={{ padding: "15px 35px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack rowGap={2}>
                <RequestDetails account={account} />
                <RequestOutcome account={account} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <AccountUpdates
                caseId={caseId}
                signature={signature}
                account={account}
                updateChat={expanded}
              />
            </Grid>
          </Grid>
        </Container>

        <br />
      </>
    </CustomCollapsible>
  );
};
