import * as React from "react";
import { ReactElement } from "react";

import { Account } from "@src/types";
import { ServiceProviderType } from "@src/types";
import { formatDate, moneyFormat } from "@utils/Functions";
import { GreyPane } from "@molecules/GreyPane";
import { InfoBoxTrigger } from "@src/components/molecules";
import { RequestDetailsField } from "@src/components/molecules";
import { NotAvailable } from "./RequestDetails/NotAvailable";

export const RequestOutcome: React.FC<{ readonly account: Account }> = ({ account }) => {
  // next_payment_due_on: account.next_payment_due_on,
  // payment_method: account.payment_method,
  // payment_method_other: account.payment_method_other

  return (
    <GreyPane title="Request outcome">
      <RequestDetailsField
        label={
          <>
            BALANCE <InfoBoxTrigger content={renderInfoBox()} />
          </>
        }
      >
        {typeof account.finalBalance === "number" && account.finalBalance < 0 ? (
          <span style={{ color: "red" }}>{moneyFormat(account.finalBalance)}</span>
        ) : typeof account.finalBalance === "number" ? (
          moneyFormat(account.finalBalance)
        ) : (
          <NotAvailable />
        )}
      </RequestDetailsField>

      {account.serviceProvider?.serviceProviderType === ServiceProviderType.Water && (
        <>
          {typeof account.finalBalance === "number" && (
            <RequestDetailsField label={"Payment Information"}>
              {account.finalBalance &&
                account.finalBalance > 0 &&
                !!account.useCreditInNextBill && (
                  <div>Credit is being built to pay towards the next bill.</div>
                )}

              <div>
                {account.nextBillDueMonth &&
                  `Next bill due in ${capitalise(account.nextBillDueMonth)}.`}
              </div>

              {!account.useCreditInNextBill && !account.nextBillDueMonth && <NotAvailable />}
            </RequestDetailsField>
          )}

          {account.paymentPlanInPlace && (
            <>
              <RequestDetailsField label={<>Payment plan amount</>}>
                {typeof account.paymentAmount === "number" && account.paymentAmount < 0 ? (
                  <span style={{ color: "red" }}>{moneyFormat(account.paymentAmount)}</span>
                ) : typeof account.paymentAmount === "number" ? (
                  moneyFormat(account.paymentAmount)
                ) : (
                  <NotAvailable />
                )}
              </RequestDetailsField>

              {account.paymentFrequency && (
                <RequestDetailsField label={<>Payment plan frequency</>}>
                  {paymentFrequencyString(account.paymentFrequency)}
                </RequestDetailsField>
              )}

              {account.nextPaymentDueOn && (
                <RequestDetailsField label={<>Next payment due on</>}>
                  {formatDate(account.nextPaymentDueOn)}
                </RequestDetailsField>
              )}

              {account.paymentMethod && (
                <RequestDetailsField label={<>Payment method</>}>
                  {paymentMethodString(account.paymentMethod)}
                </RequestDetailsField>
              )}
            </>
          )}
        </>
      )}

      {account.outcomeDetails && (
        <RequestDetailsField label={"FURTHER DETAILS"}>
          {renderOutcomeDetails(account.outcomeDetails)}
        </RequestDetailsField>
      )}
    </GreyPane>
  );
};

const renderOutcomeDetails = (details: string): ReactElement => {
  const regex = /\b\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}Z\b/;

  const validNewFormat = regex.test(details);
  if (validNewFormat) {
    return (
      <div
        style={{
          maxHeight: "300px",
          overflowY: "auto",
          border: "1px solid #ddd",
          padding: "20px",
        }}
      >
        <DetailsComponent outcomeDetails={details} />
      </div>
    );
  }

  return <>{details}</>;
};

function convertUtcToUkTime(utcTimeString: string) {
  const utcDate = new Date(utcTimeString);
  return utcDate.toLocaleString("en-GB", { timeZone: "Europe/London" });
}

const DetailsComponent: React.FC<{ outcomeDetails: string }> = ({ outcomeDetails }) => {
  const entries = outcomeDetails.split("Time: ");

  return (
    <div>
      {entries.map((entry, index) => {
        const lines = entry.split("\n").map((line) => line.trim());
        return (
          <div key={index}>
            {lines.map((item, index2) => {
              if (item === "") return null;
              const isTime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}Z$/.test(item);

              if (isTime) {
                return (
                  <span key={index2} style={{ fontWeight: "bold", margin: "0.5em 0" }}>
                    {convertUtcToUkTime(item)}
                    <hr />
                  </span>
                );
              }

              return <div key={index2}>{item}</div>;
            })}
            <br />
          </div>
        );
      })}
    </div>
  );
};

const renderInfoBox = () => {
  return (
    <div>
      <p>
        This is the account balance at the date of death as confirmed by the service provider. A
        positive balance represents an <b>asset</b> (money <b>owed to</b> the estate) whereas a
        negative balance represents a <b style={{ color: "red" }}>liability</b> (money{" "}
        <b style={{ color: "red" }}>owed by</b> the estate).
      </p>

      <p style={{ marginBottom: 0 }}>
        In some instances, such as for investments, the balance represents the number of units held
        rather than a monetary value and will not include a "£" sign.
      </p>
    </div>
  );
};

const capitalise = (str: string) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const paymentFrequencyString = (str: string) => {
  return (
    {
      monthly: "Monthly",
      weekly: "Weekly",
      four_weekly: "Four-weekly",
      quarterly: "Quarterly",
      half_yearly: "Half-yearly",
    }[str] || <NotAvailable />
  );
};

const paymentMethodString = (str: string) => {
  return (
    {
      direct_debit: "Direct Debit",
      paypoint_card: "PayPoint Card",
    }[str] || <NotAvailable />
  );
};
