import * as React from "react";

import urlPaths from "../../../urlPaths";
import { Header, Subheader } from "@atoms/Header";
import { Link } from "@atoms/Link";
import { CentredTemplate } from "@templates/CentredTemplate";

export type InvalidTokenPageProps = object;

export const InvalidTokenPage: React.FC<InvalidTokenPageProps> = () => {
  return (
    <CentredTemplate headerType="signup">
      <Header>Reset password</Header>
      <Subheader>Invalid token</Subheader>

      <p>
        The request for password reset has expired. Please{" "}
        <Link inline to={urlPaths.forgotPassword()}>
          request a new password reset email
        </Link>
        .
      </p>

      <div style={{ paddingTop: "15px" }}></div>
    </CentredTemplate>
  );
};
