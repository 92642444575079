import { keepSessionAlive, token } from "@utils/session";
import { toCamelCase, toSnakeCase } from "@utils/Functions";

export const apiFetch = async ({
  path,
  search,
  body,
  keepCase,
  headers = {},
  timeout = 30000,
  ...restInit
}: {
  path: string;
  search?: { [_: string]: string | number | boolean | null | undefined };
  body?: any;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: any;
  keepCase?: boolean;
  timeout?: number;
}) => {
  const url = getUrl(path, search);
  const init = getInit(restInit, headers, body, keepCase);

  const controller = new AbortController();
  let timeoutId;
  if (timeout) {
    init.signal = controller.signal;
    timeoutId = setTimeout(() => controller.abort(), timeout);
  }

  const response = await fetch(url, init);
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  return response.json().then((value) => {
    return toCamelCase(value);
  });
};

const getUrl = (
  path: string,
  search?: { [key: string]: string | number | boolean | null | undefined }
) => {
  const url = `${process.env.REACT_APP_BACKEND_HOST}/api${path}`;

  if ('settldTestModeEnabled' in window && window['settldTestModeEnabled']) {
    if (!search) {
      search = { testModeEnabled: true };
    } else {
      search = { testModeEnabled: true, ...search };
    }
  }

  if (!search) {
    return url;
  }

  const params = new URLSearchParams();
  Object.entries(toSnakeCase(search)).forEach(([key, value]) => {
    if (value !== undefined) {
      params.set(key, String(value));
    }
  });

  const sep = /[?]/.test(url) ? "&" : "?";
  return url + sep + params.toString();
};

const getInit = (restInit: any, headers: any, body?: any, keepCase?: boolean) => {
  const bearerToken = token();
  if (bearerToken) {
    keepSessionAlive(bearerToken);
    headers = { Authorization: `Bearer ${bearerToken}`, ...headers };
  }

  body = keepCase || body === undefined ? body : toSnakeCase(body);

  return {
    ...restInit,
    headers,
    ...(body === "undefined" ? {} : { body: JSON.stringify(body) }),
  };
};
