import monitor from "../../utils/monitoring";
import { apiFetch } from "@api/restApi";
import { NotifierRolesByServer, PersonDetails, ServiceProvider } from "@src/types";
import { isComplete as isAccountsComplete } from "./Accounts";
import { isComplete as isDeceasedDetailsComplete } from "./DeceasedDetails";
import { isComplete as isDocumentsComplete } from "./Documents";
import { isComplete as isKYCComplete } from "./KYC";
import { isNotifierFormComplete } from "./NotifierDetails";
import { isNokFormComplete } from "./NokForm";
import { isExecutorFormComplete } from "./ExecutorForm";

import { isWillRequired } from "@src/types/Document";
import { Section } from "@src/Sections";
import { ui_state_machine } from "@src/utils/Intestacy";

export const getCase = async (args: { caseId: string; signature: string | null }) => {
  const result = await getCaseRequest(args);

  if (result.error) {
    switch (result.error) {
      default:

        throw new Error("Unexpected error; please contact customer support");
    }
  }



  return result.data;
};

const getCaseRequest = async (args: { caseId: string; signature: string | null }) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}`,
      search: !signature ? {} : { caseId, sig: signature }
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}",
      args,
      error
    });
    throw error;
  }

  return result;
};

export const notifierEmailAddressVerified = (persons: PersonDetails[], bypass?: boolean) => {
  if (persons) {
    const notifier = persons.find((p) => p.roles?.includes(NotifierRolesByServer.Notifier));
    return notifier?.emailAddressVerified || bypass || false;
  }
  return bypass || false;
};

export const NEFormMenuEntries = ({ collectNokDetails, collectExecutorDetails, bypassKYC }: any) => {
  return [
    { key: Section.Accounts, label: "Add accounts" },
    { key: Section.Deceased, label: "Person who died" },
    { key: Section.Notifier, label: "Your Details" },
    ...(collectNokDetails ? [{ key: Section.Nok, label: "Next of kin Details" }] : []),
    ...(collectExecutorDetails ? [{ key: Section.Executor, label: "Executor Details" }] : []),
    { key: Section.Documents, label: "Documents" },
    ...(bypassKYC ? [] : [{ key: Section.KYC, label: "Fraud Protection" }]),
    { key: Section.Submit, label: "Review & submit" }
  ];
};

export function getFormCompletionState(
  serviceProviders: any,
  form: any,
  { collectNokDetails, collectExecutorDetails, willExists, intestacyFlow }: any
) {
  const serviceProvidersMap = serviceProviders.reduce((acc: any, serviceProvider: any) => {
    if (serviceProvider.id !== undefined) {
      acc[serviceProvider.id] = serviceProvider;
    }
    return acc;
  }, {} as Record<string, ServiceProvider>);

  const askForProofOfAddress = !!form.accounts?.some(
    (account: any) => account.companyId && serviceProvidersMap[account.companyId]?.proofOfAddressNeeded
  );
  const askForWillAndProbate = isWillRequired(form?.accounts, form?.notifier, serviceProvidersMap, intestacyFlow, willExists);
  return {
    [Section.Accounts]: !!form.submittedSections.accounts && isAccountsComplete(form),
    [Section.Deceased]: !!form.submittedSections.deceased && isDeceasedDetailsComplete(form),
    [Section.Notifier]: !!form.submittedSections.notifier && isNotifierFormComplete(form, intestacyFlow),
    [Section.Nok]: collectNokDetails && !!form.submittedSections.nok && isNokFormComplete(form),
    [Section.Executor]: collectExecutorDetails && !!form.submittedSections.executor && isExecutorFormComplete(form),
    [Section.Documents]:
      !!form.submittedSections.documents &&
      isDocumentsComplete(form, askForProofOfAddress, askForWillAndProbate),
    [Section.KYC]: !!form.submittedSections.kyc && isKYCComplete(form),
    [Section.Submit]: form.submitted
  };
}

export function onCaseLoadUI(
  notifier: any,
  willAvailable: boolean,
  bypassKYC: boolean,
  accounts: any,
  serviceProviders: any
) {
  // Corrected: Use map and filter to collect companyIds
  const companyIds = accounts
    .map((account: any) => account.companyId)
    .filter((id: any) => id !== undefined && id !== null);

  // The rest remains the same
  const intestacyFlow = serviceProviders.some(
    (sp: any) => companyIds.includes(sp.id) && sp.intestacyFlow
  );

  // Corrected: Initialize state as an empty object to prevent spreading undefined
  let state = {};
  if (intestacyFlow) {
    state = ui_state_machine(notifier, willAvailable);
  }
  const s = { ...state, intestacyFlow, bypassKYC };
  return s;
}
