import * as React from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { Header } from "../atoms/Header";

export type CustomCollapsibleProps = {
  readonly title: React.ReactNode;
  readonly onToggled?: (b: boolean) => void;
  readonly children: React.ReactNode;
  readonly expanded?: boolean;
};

export const CustomCollapsible: React.FC<CustomCollapsibleProps> = ({
  title,
  onToggled,
  children,
  expanded = false,
}) => {
  const [active, setActive] = React.useState(expanded);
  React.useEffect(() => setActive(expanded), [expanded]);

  const accordionToggle = React.useCallback(
    (ev) => {
      ev.stopPropagation();
      setActive(!active);
      onToggled && onToggled(!active);
    },
    [active,setActive, onToggled]
  );

  return (
    <Accordion expanded={active}>
      <AccordionSummary expandIcon={<ExpandMore />} onClick={accordionToggle}>
        <Header level={3}>{title}</Header>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
