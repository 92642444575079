import React, { createContext, useReducer } from "react";

export type UiInfo = {
  willExists: boolean;
  collectNokDetails: boolean;
  collectExecutorDetails: boolean;
  intestacyFlow: boolean
  bypassKYC: boolean,
  refresh: number;
};

const initialState = {
  willExists: false,
  collectNokDetails: false,
  collectExecutorDetails: false,
  intestacyFlow: false,
  bypassKYC: false,
  refresh: 0
};

const NEContext = createContext<{
  state: UiInfo;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

const actionTypes = {
  SET: "SET",
  SET_STATE: "SET_STATE",
  REFRESH: "REFRESH",
  SET_UI: "SET_UI"
};

function reducer(state: UiInfo, action: { type: string; payload: any }): UiInfo {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.REFRESH:
      return { ...state, refresh: state.refresh + 1 };
    case actionTypes.SET:
      return { ...state, ...action.payload };
    case actionTypes.SET_UI:
      return { ...state, ...action.payload };
    case actionTypes.SET_STATE:
      return {
        ...state,
        willExists: payload.willExists,
        collectExecutorDetails: payload.collectExecutorDetails,
        collectNokDetails: payload.collectNokDetails
      };
    default:
      return initialState;
  }
}

const NEUiInfoProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NEContext.Provider value={{ state, dispatch }}>
      {children}
    </NEContext.Provider>
  );
};
export { NEContext, NEUiInfoProvider };
