import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { loadGOA, updateGOA } from "@api/caseApi";
import { Button } from "../../components/atoms/Button";
import { Header } from "../../components/atoms/Header";
import { Link } from "../../components/atoms/Link";
import { LoadingPage } from "../LoadingPage";
import { Accordion } from "../../components/molecules/Accordion";
import { OnboardingTemplate } from "@templates/OnboardingTemplate";

export type PermissionPageProps = {
  caseId: string;
  signature: string | null;
  nextPageURL: string;
};

export const PermissionPage: React.FC<PermissionPageProps> = ({
  caseId,
  signature,
  nextPageURL,
}) => {
  const navigate = useNavigate();

  const [{ loaded, busy, error }, setState] = React.useState<{
    loaded: boolean;
    busy: boolean;
    error?: string;
  }>({ loaded: false, busy: true });

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => (s === null ? s : { ...s, error: undefined }));
  }, [setState]);

  const onContinue = React.useCallback(() => {
    updateGOA({ caseId, signature }).then(
      (_) => {
        navigate(nextPageURL, { replace: true });
      },
      (_error) => {
        setState({
          loaded: true,
          busy: false,
          error: "Failed to load data; please reload this page to try again.",
        });
      }
    );
  }, [navigate, caseId, signature, setState, nextPageURL]);

  React.useEffect(() => {
    loadGOA({ caseId, signature }).then(
      (data) => {
        if (data.alreadyPermitted) {
          navigate(nextPageURL, { replace: true });
          return;
        }
        setState({ loaded: true, busy: false, error: undefined });
      },
      (_error) => {
        setState({
          loaded: false,
          busy: false,
          error: "Failed to load data; please reload this page to try again.",
        });
      }
    );
  }, [caseId, signature, navigate, nextPageURL, setState]);

  if (!loaded) {
    return <LoadingPage />;
  }

  return (
    <PermissionPageView
      busy={busy}
      errorMessage={error}
      removeErrorMessage={removeErrorMessage}
      onContinue={onContinue}
    />
  );
};

export type PermissionPageViewProps = {
  busy: boolean;
  errorMessage: string | undefined;
  removeErrorMessage: () => void;
  onContinue: () => void;
};

export const PermissionPageView: React.FC<PermissionPageViewProps> = ({
  busy,
  errorMessage,
  removeErrorMessage,
  onContinue,
}) => {
  return (
    <OnboardingTemplate errorMessage={errorMessage} removeErrorMessage={removeErrorMessage}>
      <Stack spacing={4}>
        <Header level={1}>We need your permission to proceed</Header>

        <Stack spacing={2}>
          <Accordion info title="Why do you need my permission to proceed?">
            Service Providers need to see that you have given Settld your permission to contact
            them.
          </Accordion>

          <Accordion info title="What am I giving you permission to do?">
            <ol style={{ marginTop: 0 }}>
              <li>To notify Service Providers of the death, using the information you provide.</li>
              <li>
                To communicate and share the information with Service Providers on your behalf.
              </li>
              <li>
                To request Service Providers to close, transfer, amend or gain information about the
                accounts of the Deceased.
              </li>
              <li>To receive date of death balances in respect of the accounts of the Deceased.</li>
            </ol>
            Please note, Settld <b>is not permitted to</b>:
            <ol>
              <li>Sign account closure forms on your behalf.</li>
              <li>Collect money from Service Providers, in respect of the Deceased accounts.</li>
            </ol>
          </Accordion>

          <Accordion info title="Is there anything else I should be aware of?">
            By selecting 'Yes, I agree' below you are confirming that you are at least 18 years of
            age and are authorised, as the Executor or authorised representative of the person who
            died, to take actions and share the information required.
          </Accordion>
        </Stack>

        <Stack spacing={2}>
          <Typography>
            This grant of authority will be effective upon submission of this bereavement
            notification form, and will be valid for a period of 12 months.
          </Typography>

          <Typography>
            By agreeing to proceed, you acknowledge that you have read and understood the Settld{" "}
            <Link
              inline
              target="_blank"
              to="https://www.settld.care/legal/terms-and-conditions.html"
            >
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link inline target="_blank" to="https://www.settld.care/legal/privacy-policy.html">
              Privacy Policy
            </Link>
            .
          </Typography>
        </Stack>

        <Box>
          <Button variant="primary" size="large" onClick={onContinue} disabled={busy}>
            Yes, I Agree
          </Button>
        </Box>
      </Stack>
    </OnboardingTemplate>
  );
};
