import * as React from "react";

export const TipsToAvoidDelays = () => {
  return (
    <div>
      <p>
        <b>To avoid delays</b>
      </p>

      <ol>
        <li style={{ marginBottom: "15px" }}>
          When requesting the closure of <b>water, gas or electricity accounts</b>, we recommend
          uploading an <b>image of a meter reading</b> for each account (taken as close to the date
          of death as possible).
        </li>

        <li>
          When you’re uploading images of documents, please make sure that the image{" "}
          <b>contains all four corners of each page</b> and that{" "}
          <b>all text is clear and easy to read</b>.
        </li>
      </ol>
    </div>
  );
};
