import { executorRequiredFields, nokRequiredFields, notifierRequiredFields, Person, PersonErrors, Relationship, ServiceProvider } from "@src/types";
import { isValidDateString, validEmailAddress } from "./Functions";
export class ValidationError extends Error {
  errors: { [k: string]: string[] };

  constructor(errors: { [k: string]: string[] }) {
    super("validation error");
    this.errors = errors;

    this.name = "ValidationError";
  }
}


export const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    firstName: ve.errors.firstName ? ve.errors.firstName[0] : undefined,
    lastName: ve.errors.lastName ? ve.errors.lastName[0] : undefined,
    emailAddress: ve.errors.emailAddress ? ve.errors.emailAddress[0] : undefined,
    password: ve.errors.password ? ve.errors.password[0] : undefined,
    passwordConfirmation: ve.errors.passwordConfirmation
      ? ve.errors.passwordConfirmation[0]
      : undefined
  };

  return JSON.parse(JSON.stringify(errors));
};


export const notifierErrors = (record: Person, caseData: any, serviceProvider: ServiceProvider): PersonErrors | undefined => {
  const errors: PersonErrors = {};

  notifierRequiredFields.forEach((required: boolean, key: keyof Person, _map: Map<keyof Person, boolean>) => {
    switch (key) {
      case "email":
        if (!record.email) {
          errors.email = "required";
        } else if (!validEmailAddress(record.email || "")) {
          errors.email = "Not a valid email address";
        }
        break;

      case "role":
        if (!record.role) {
          errors.role = "required";
        } else {
          if (serviceProvider.intestacyFlow) {
            const needsNok =
              record.role === "administrator" ||
              (record.role === "delegated_notifier" && !caseData.record.willAvailable);

            if (needsNok && record["nok"] === undefined) {
              errors.nok= "required" ;
            }
          }
        }
        break;

      case "dateOfBirth":
        if (required && !record.dateOfBirth && record.role !== "solicitor") {
          errors.dateOfBirth = "Required"
        } else if (record.dateOfBirth) {
          if (!isValidDateString(record.dateOfBirth)) {
            errors.dateOfBirth = "Invalid Date"
          }
        }
        break;

      case "relationshipToDeceased":
        if (record.role !== "solicitor" && !record.relationshipToDeceased) {
          errors.relationshipToDeceased = "required";
        } else {
          const cond: boolean = record.role !== "solicitor" && record.relationshipToDeceased === Relationship.Other && !record.relationshipToDeceasedOther;
          if (cond) {
            errors.relationshipToDeceasedOther = "required";
          }
        }
        break;

      default:
        if (!record || !record[key]) {
          errors[key] = "required";
        }
        break;
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};


export const executorErrors = (record: Person): PersonErrors | undefined => {
  const errors: PersonErrors = {};

  executorRequiredFields.forEach((required: boolean, key: keyof Person, _map: Map<keyof Person, boolean>) => {
    switch (key) {
      case "email":
        if (!record.email) {
          errors.email = "required";
        } else if (!validEmailAddress(record.email || "")) {
          errors.email = "Not a valid email address";
        }
        break;

      case "relationshipToDeceased":
        if (!record.relationshipToDeceased) {
          errors.relationshipToDeceased = "required";
        } else {
          const cond: boolean = record.role !== "solicitor" && record.relationshipToDeceased === Relationship.Other && !record.relationshipToDeceasedOther;
          if (cond) {
            errors.relationshipToDeceasedOther = "required";
          }
        }
        break;

      case "dateOfBirth":
        if (required && !record.dateOfBirth) {
          errors.dateOfBirth = "Required"
        } else if (record.dateOfBirth) {
          if (!isValidDateString(record.dateOfBirth)) {
            errors.dateOfBirth = "Invalid Date"
          }
        }
        break;

      default:
        if (!record || !record[key]) {
          errors[key] = "required";
        }
        break;
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};



export const nokErrors = (record: Person): PersonErrors | undefined => {
  const errors: PersonErrors = {};

  nokRequiredFields.forEach((required: boolean, key: keyof Person, _map: Map<keyof Person, boolean>) => {
    switch (key) {
      case "email":
        if (!record.email) {
          errors.email = "required";
        } else if (!validEmailAddress(record.email || "")) {
          errors.email = "Not a valid email address";
        }
        break;

      case "relationshipToDeceased":
        if (!record.relationshipToDeceased) {
          errors.relationshipToDeceased = "required";
        } else {
          const cond: boolean = record.role !== "solicitor" && record.relationshipToDeceased === Relationship.Other && !record.relationshipToDeceasedOther;
          if (cond) {
            errors.relationshipToDeceasedOther = "required";
          }
        }
        break;

      case "dateOfBirth":
        if (required && !record.dateOfBirth) {
          errors.dateOfBirth = "Required"
        } else if (record.dateOfBirth) {
          if (!isValidDateString(record.dateOfBirth)) {
            errors.dateOfBirth = "Invalid Date"
          }
        }
        break;

      default:
        if (!record || !record[key]) {
          errors[key] = "required";
        }
        break;
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};

