import { ErrorBoundary } from "@appsignal/react";

import Appsignal from "@appsignal/javascript";

import { plugin as pluginBreadcrumbsNetwork } from "@appsignal/plugin-breadcrumbs-network";

import { plugin as pluginPathDecorator } from "@appsignal/plugin-path-decorator";

import { plugin as pluginWindowEvents } from "@appsignal/plugin-window-events";

const localMonitor = () => {
  const logError = ({ event, args, error }: { event: string; args: object; error: any }) => {
    console.error({ event, args, error });
  };

  return {
    logError,

    logErrorList: ({
      event,
      args,
      errors
    }: {
      event: string;
      args: object;
      errors: readonly Error[];
    }) => {
      errors.forEach((error) => {
        logError({ event, args, error });
      });
    },

    ErrorBoundary: ({ children }: any) => {
      return <>{children}</>; //ESLint: 'React' must be in scope when using JSX(react/ react-in-jsx-scope)
    }
  };
};

const appsignalMonitor = () => {
  const appsignal = new Appsignal({
    key: process.env["REACT_APP_APPSIGNAL_KEY"],
    revision: process.env["REACT_APP_VERSION"]
  });
  // console.log(appsignal.demo())
  appsignal.use(pluginBreadcrumbsNetwork);
  appsignal.use(pluginPathDecorator);
  appsignal.use(pluginWindowEvents);

  const logError = ({ event, args, error }: { event: string; args: object; error: any }) => {
    appsignal.sendError(error, (span: any) => {
      span.setAction(event);
      span.setParams(args);
    });
  };

  return {
    logError,

    logErrorList: ({
      event,
      args,
      errors
    }: {
      event: string;
      args: object;
      errors: readonly Error[];
    }) => {
      errors.forEach((error) => {
        logError({ event, args, error });
      });
    },

    ErrorBoundary: ({ fallback, children }: any) => {
      return (
        <ErrorBoundary
          instance={appsignal}// tags={{ tag: "value" }}
          fallback={fallback}
        >
          {children}
        </ErrorBoundary>
      );
    }
  };
};

const monitor = process.env["REACT_APP_SETTLD_ENV"] === "local" ? localMonitor : appsignalMonitor;

export default monitor();
