import * as React from "react";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

export const NotFoundPage = () => {
  

  return <NotFoundPageView />;
};

export type NotFoundPageViewProps = object;

export const NotFoundPageView: React.FC<NotFoundPageViewProps> = () => {
  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane message="Not found" description="The page you requested doesn't exist." />
    </CentredTemplate>
  );
};
